
import Vue from 'vue'
//import Vuetify from 'vuetify'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App.vue'
import store from './store'
import router from './router'
//import './registerServiceWorker'
import VueYouTubeEmbed from 'vue-youtube-embed'
import DatetimePicker from 'vuetify-datetime-picker'

import VueCookies from 'vue-cookies'
import axios from 'axios'


window.serverUrl = process.env.NODE_ENV === 'production'
? 'https://vux.valiweb.nu/kursmodul' 
: 'http://localhost:3000';

Vue.prototype.$publicUrl = process.env.NODE_ENV === 'production'
? 'https://vux.valiweb.nu/kursmodul/' 
: 'http://localhost:8080/';

// Defined apptitle

// denna raden finns redan i storen...
document.title = store.state.everything.apptitle;

if (window.location.hostname.indexOf("skelleftea.valiweb.nu") > -1) { 
  store.state.everything.apptitle = "ValiWeb Skellefteå";
  document.title = "ValiWeb Skellefteå";
  Vue.prototype.$publicUrl = 'https://skelleftea.valiweb.nu/kursmodul/';
  window.serverUrl = 'https://skelleftea.valiweb.nu/kursmodul';
}

if (window.location.hostname.indexOf("y.valiweb.nu") > -1) { 
  store.state.everything.apptitle = "ValiWeb Yrkeslärare";
  document.title = "ValiWeb Yrkeslärare";
  Vue.prototype.$publicUrl = 'https://y.valiweb.nu/kursmodul/';
  window.serverUrl = 'https://y.valiweb.nu/kursmodul';
}

if (window.location.hostname.indexOf("yrk.valiweb.nu") > -1) { 
  store.state.everything.apptitle = "ValiWeb Yrkeslärare";
  document.title = "ValiWeb Yrkeslärare";
  Vue.prototype.$publicUrl = 'https://yrk.valiweb.nu/kursmodul/';
  window.serverUrl = 'https://yrk.valiweb.nu/kursmodul';
}

if (window.location.hostname.indexOf("yrk-test.valiweb.nu") > -1) { 
  store.state.everything.apptitle = "ValiWeb Yrkeslärare Test";
  document.title = "ValiWeb Yrkeslärare Test";
  Vue.prototype.$publicUrl = 'https://yrk-test.valiweb.nu/kursmodul/';
  window.serverUrl = 'https://yrk-test.valiweb.nu/kursmodul';
}

if (window.location.hostname.indexOf("vux-test.valiweb.nu") > -1) { 
  store.state.everything.apptitle = "ValiWeb VUX Test";
  document.title = "ValiWeb VUX Test";
  Vue.prototype.$publicUrl = 'https://vux-test.valiweb.nu/kursmodul/';
  window.serverUrl = 'https://vux-test.valiweb.nu/kursmodul';
}

//store.state.everything.apptitle = "ValiWeb Skellefteå"; // debug
 // store.state.everything.apptitle = "ValiWeb Yrkeslärare"; // debug

/*
window.serverUrl = process.env.NODE_ENV === 'production'
? 'http://localhost:8888/kursmodul' 
: 'http://localhost:3000'

Vue.prototype.$publicUrl = process.env.NODE_ENV === 'production'
? 'http://localhost:8888/kursmodul/' 
: 'http://localhost:8080/'
*/

/*
if (location.protocol == 'https:')
{
 // window.serverUrl = 'https://kursmodul.valiweb.nu' 
  window.serverUrl = 'https://vux.valiweb.nu/kursmodul'
}

window.serverUrl = 'https://vux.valiweb.nu/kursmodul'
*/

/*
window.serverUrl = process.env.NODE_ENV === 'production'
? 'http://localhost:8888' 
: 'http://localhost:3000'
*/

Vue.use(VueYouTubeEmbed)
Vue.use(DatetimePicker)
//Vue.use(VTiptap)
Vue.use(VueCookies)

// set default config
Vue.$cookies.config('7d')

// set global cookie
// Vue.$cookies.set('SameSite','None; Secure')
Vue.$cookies.set("use_path_argument", "value", null, null, null, null, "Lax");

Vue.$cookies.set('promo_shown', '1')
Vue.$cookies.set('theme', 'default')
//Set-Cookie: promo_shown=1; SameSite=Lax

import "@/assets/_global.scss"

Vue.config.productionTip = false

Vue.prototype.$course_id = {value: 41284};

const token = localStorage.getItem('user-token');

if (token) {
  //axios.defaults.headers.common['Authorization'] = token
 // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('user-token');
  //  axios.defaults.withCredentials = true
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
}

// axios.defaults.withCredentials = true

axios.defaults.params = {};

axios.defaults.params["AppTitle"] = store.state.everything.apptitle;
axios.interceptors.request.use(function (config) {
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date+'T'+time;
     //config.params = "{dt: " + dateTime + "}";
     config.params["dt"] = dateTime;
     
    return config;
}, function (error) {
    return Promise.reject(error);
});

/*
axios.interceptors.request.use(config => {
  console.log(config)
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date+'T'+time;

  config.params = "{dt: hej}'
  // &dt=curDtAndTime
 
 // config.headers['key'] = '123ABD'
  return config
})
*/

//                  Cross domain PHP session authorized API requests with Axios

/*
axios.interceptors.request.use(
  (config) => {
    console.log("intercept.")
    let token = localStorage.getItem('authtoken');
    console.log("intercept.")
    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }

    return config;
  }, 

  (error) => {
    console.log("intercept ERR.")
    return Promise.reject(error);
  }
);
*/

Vue.mixin ({
  methods: {
    timePassed(date_now, date_then) {
      date_now = new Date(date_now)
      date_then = new Date(date_then)
     // console.log(date_now)
     // console.log(date_then)
      // get total seconds between the times
      var delta = Math.abs(date_now - date_then) / 1000;
    //  console.log("Delta: " + delta)

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = delta % 60; 
      let o = {days: days, hours: hours, minutes: minutes, seconds: seconds}
   //   console.log(o)
      return o
    },
    timeProgressed(date_now, date_then) {
        let t = this.timePassed(date_now, date_then)
        if(t.days>7) {
            return date_then
        } 
        else if(t.days>0) {
            return t.days + "d"
        }
        else if(t.hours>0) {
            return t.hours + "h " + t.minutes + "min"
        }
        else if(t.minutes>0) {
            return t.minutes + "min"
        }
        else if(t.seconds>0) {
            return t.seconds + "s"
        }
    }
  }
})

// fix vue tree list to be able to select parent in combination when selecting a child.
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg !== 'Invalid prop: custom validator check failed for prop "selectionType".') {
    const hasConsole = typeof console !== 'undefined';
    if (hasConsole && !Vue.config.silent) {
      console.error(`[Vue warn]: ${msg}${trace}`);
    }
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

//Vue.prototype.$current_course_id = 1234
