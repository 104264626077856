<template>
    <v-container class="pt-0">    
        <template v-if="files && files.length>0">
            <v-row no-gutters>
             <!--   <file-attachment-chip
                    v-for="(file) in files" :key="file.Id"
                    isAllowedToDeleteFile
                    :closeFunc="confirmDeleteFile(file)"
                    :attachment="file"
                    Name="Bilaga"
                />
                -->
                <v-col v-for="(file) in files" :key="file.Id">
                        <v-chip
                            :color="linkTextColor"
                            outlined
                            class="mx-4 mt-2 mb-2 px-4 py-2 chipclass" @click="showFile(file)">
                            {{shortName(file.FileName)}}
                        </v-chip>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            Inga bilagor...
        </template>
    </v-container>
</template>

<script>
  //  import FileAttachmentChip from '../helpers/FileAttachmentChip'

  export default {
    name: 'FileListButtons',
    components: {
   //     FileAttachmentChip
    },
    props: {
            files: {
                type: Array,
                required:true,
                default: []
            },
            isAllowedToDeleteFile: {
                type: Boolean,
                required:false,
                default: false
            },
            linkTextColor: {
                type: String,
                required:false,
                default: 'primary'
            }
    },  
    data: () => ({
        blipp:false,
    }),
    created() {
        
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        /*getFileUrl(i_file) {
          return window.serverUrl + '/api/backend/get_file.php?fileGet=' + i_file.Path + '&token=' + localStorage.getItem("user-token");
        },*/
        showFile(i_file) {
            let extras = "";
            
            if(this.getExtension(i_file.Path)=="pdf") {
                extras = "&download=1";
            } 

            window.open(window.serverUrl + '/api/backend/get_file.php?fileGet=' + i_file.Path + extras + '&token=' + localStorage.getItem("user-token"), '_blank');
        },
        getExtension(filename) {
            return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
        },
        shortName(i) {
            if(i.length>15) {
                let ext = i.substring(i.length,i.length-3);
                return i.substring(0,15) + "_." + ext;
            } else {
                return i;
            }
        },
        confirmDeleteFile(file) {
            this.$emit("deleteFile", file);
        }
    }
  }
</script>

<style scoped>
  /*  >>>.chipclass {
        height: auto;
        white-space: normal;
    }
    */
</style>
