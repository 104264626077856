<template>
    <v-dialog
        v-model="showDialog"
        max-width="420"
        :persistent="persistent"
        @click:outside="clickOutsideDialog"
    >
        <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 1.0);">
                <v-layout justify-center align-center >
                <v-card flat 
                    class="mx-auto my-6"
                    max-width="374"
                >
                    <v-img v-if="type=='alert'" min-height="100" :src="isYrk?bollar:alert_image" name="Bekräfta" ></v-img>
                    <v-img v-if="type=='alert_email'" min-height="100" :src="isYrk?bollar:alert_email_image" name="Bekräfta" ></v-img>
                    <v-img v-else-if="type=='submit'" min-height="100" :src="isYrk?bollar:submit_image" name="Submit OK" ></v-img>
                    <v-img v-else-if="type=='general'" min-height="100" :src="isYrk?bollar:happy_image" name="Happy Days" ></v-img>
                    <v-card-title class="text-h6">
                        <!--
                        <v-icon
                            large
                            left
                        >
                            warning
                        </v-icon>   
                        -->                     
                        {{title}}
                    </v-card-title>

                    <v-card-text v-if="message" v-html="message">
                    </v-card-text>
                    <v-card-text v-else>
                        <slot name="slotmessage"></slot>
                    </v-card-text>

                    <v-card-actions v-if="choiceNO!=''">
                        <v-btn
                            color="grey"
                            dark
                            depressed
                            @click="cancel()"
                        >
                            {{choiceNO}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            dark
                            depressed
                            @click="confirm()"
                            class="ml-4"
                        >
                            {{choiceOK}}
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions v-else>
                        <v-row justify="center">
                            <v-btn
                                color="primary"
                                dark
                                depressed
                                :loading="isLoading"
                                @click="confirm()"
                                class="ml-4"
                            >
                                {{choiceOK}}
                            </v-btn>
                        </v-row>
                    </v-card-actions>

                </v-card>
            </v-layout>
        </v-container>
    </v-dialog>
</template>

<script>
export default {
    name: 'confirm-dialog',
    components:{
    },
    props:{
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: false
        },
        choiceOK: {
            type: String,
            required: true
        },
        choiceNO: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        asyncMode: {
            type: Boolean,
            required: false,
            default: false
        },
        isYrk: {
            type: Boolean,
            required: false,
            default: false,
        },
        persistent: {
            type: Boolean,
            required: false,
            default: true
        },
        loading: {
            type: Boolean,
            require: false,
            default: false
        }
    },
    data: () => ({
        showDialog:true,
        isLoading: false,
        alert_image: require('@/assets/images/alert_illustration.jpg'),
        alert_email_image: require('@/assets/images/confirm_email.jpg'),
        submit_image: require('@/assets/images/submit_illustration.jpg'),
        happy_image: require('@/assets/images/happydays2.jpg'),
        bollar: require('@/assets/images/valiweb_yrk_headericons.svg'),
    }),
    watch: {
        loading: {
            // the callback will be called immediately after the start of the observation
            immediate: true, 
            handler (val, oldVal) {
                this.isLoading = val;
            }
        }
    },
    mounted() {
        this.isLoading = this.loading;
    },
    methods: {
        clickOutsideDialog() {
            if (!this.persistent) {
                this.cancel();
            }
        },
        confirm() {
            if (this.loading) {
                return;
            }
            this.$emit('confirm')
        },
        cancel() {
            if (this.loading) {
                return;
            }
            this.$emit('closeDialog')
        }
    }
}
</script>
