<template>
    <div v-if="$store.state.everything.isPageEditActivated">>
        <v-text-field
            v-model="content"
            @input="txtFieldLostFocus"
            :placeholder="'Text för key:' + TheKey + '...'" 
        >
        </v-text-field>

    </div>
    <div v-else>
        <div v-if="hadData" v-html="storeContent"></div>
        <div ref="slotContainer">
            <slot v-if="!hadData" name="DefaultContent"></slot>
        </div>
    </div>
</template>

<script>
  import axios from 'axios';
 
 export default{
  name:'ContentText',
  components: {
   
  },
    props: {
        rounded: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        TheKey:{
            type: String,
            required: true,
            default: ''
        },
        isZoned: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        dateWhenDataWasChangedOnInput: null,
        hadData: false,
        theEditor: null,
        hideToolbar: false,
        view: false,
        mention: false,
        errorMessages: null,
        isSaving: false,
        selectedLink: '',
        selectedIcon: '',
        unsubscribeFromStore: null,
        showContentAreaButton: false,
        lastSaveTime: null,
        timedSaveTrigger: null,
        content: "",
        lastContent: "",
        lastFetched: null,
        creationDate: null,
        modificationDate: null,
    }),
    computed: {
        storeContent() {
            var obj = this.$store.state.everything.translations.find(o => o.Key === this.TheKey);
            this.content = obj?obj.Content:"";
            return obj?obj.Content:"";
        },
        hasChanges() {
            return this.content != this.lastContent;
        }
    }, 
    mounted: function() {
        /*var checkRefs = setInterval(()=> {
            if(this.$refs) {
                clearInterval(checkRefs);
                this.evalLoadContent();
            }
        },3000);
        */

        this.$nextTick(() => {
            this.evalLoadContent();
        });

        this.unsubscribeFromStore = this.$store.subscribe((mutation, state) => {
            if(mutation.type === 'setPageEditActivation' && mutation.payload == false){
                // trigga sparning om content är förändrat
                if (this.content != this.lastContent) {
                    if (this.timedSaveTrigger != null) {
                        clearTimeout(this.timedSaveTrigger);
                    }
                    this.dateWhenDataWasChangedOnInput = null;
                    // trigga funktionen direkt!
                    this.saveKeyData();
                }
            }
        });
    },
    created: function() {
        
    },
    beforeDestroy() {
        this.unsubscribeFromStore();
        if (this.timedSaveTrigger != null) {
            clearTimeout(this.timedSaveTrigger);
        }
    },
    watch: { 
        TheKey: function(newVal, oldVal) {
            //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            if(newVal != oldVal) {
                this.evalLoadContent();
            }
        }
    },
    methods: {
        evalLoadContent() {
            if (this.TheKey == "") {
                this.$toast("No key specified!");
                return false;
            }

            let a = {
                Key:this.TheKey,
                Content: "",
                CreationDate: "",
                ModificationDate: ""
            };

            this.$store.dispatch("insertTranslationContent", a).then((index) => {
            //   console.log("Translation created for the first time!");
                this.content = this.$store.state.everything.translations[index].Content;
                this.getLocalStorage();
            });
        },
        txtFieldLostFocus(event) {
            this.dateWhenDataWasChangedOnInput = new Date();
            if (this.lastContent != this.content) {
                if (this.lastSaveTime == null && this.timedSaveTrigger == null) {
                    this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                    return;
                }

                var curTime = new Date();
                if ((this.lastSaveTime != null && curTime.getTime() - this.lastSaveTime.getTime() >= 5000) && this.timedSaveTrigger != null) {
                    if (this.timedSaveTrigger != null)
                        clearTimeout(this.timedSaveTrigger);
                    this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                } else if (this.timedSaveTrigger == null) {
                    if (this.timedSaveTrigger != null)
                        clearTimeout(this.timedSaveTrigger);
                    this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                }
                
                
            }
            //this.$toast.save("Sparar via blur...");
            //console.log("pop");
        },
        fetchKeyData() {
            this.timedSaveTrigger = null;
            var fd = new FormData();
        //    console.log(this.$el);

            try{
                fd.append('data', JSON.stringify({
                    isZoned: this.isZoned,
                    TheKey: this.TheKey,
                    DefaultContent: this.$refs.slotContainer.innerHTML
                }));
            } catch {
             //   console.log("SlotContainer for key: " + this.TheKey + " does not exist!");
                return;
            }

              axios.post(window.serverUrl + '/api/backend/translations/get_content.php', fd)
              .then(response => {
                  JSON.stringify(response, null, 4)
                    if(response.status === 200) {
                        if (response.data.data.length == 0) {
                            this.hadData = false;
                            //console.log("No data input yet for this key:", this.TheKey);
                        } else {
                            // console.log("fetch");
                            // this.content = response.data.data[0]["Content"];
                            this.hadData = true;
                            // this.lastContent = this.content;
                            this.creationDate = response.data.data[0]["CreationDate"];

                            if(response.data.data[0]["ModificationDate"]) {
                                this.modificationDate = response.data.data[0]["ModificationDate"];
                            }
                            
                            this.setLocalStorage(response.data.data[0]);
                        }
                    } else {
                      console.log("Error loading content for Key: " + this.TheKey + " from backend.")
                    }
              })
              .catch(e => {
                console.log(e);
              })
        },
        saveKeyData() {    
            if(this.$store.state.everything.user.userData.length === 0 || this.$store.state.everything.user.userData.Privilegies<70) {
                return;
            }
            //console.log("Save from contentText.", this.$store.state.everything.user.userData);
        
            var curTime = new Date();
            if (this.dateWhenDataWasChangedOnInput == null) {

            } else if (curTime.getTime() - this.dateWhenDataWasChangedOnInput.getTime() < 3000 || this.lastContent == this.content) {
                // we are still inputting...
                if (this.timedSaveTrigger != null) {
                    clearTimeout(this.timedSaveTrigger);
                }
                this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                return;
            }
            this.lastSaveTime = new Date();
            this.isSaving = true;
            var fd = new FormData();
            fd.append('data', JSON.stringify({
                isZoned: this.isZoned,
                TheKey: this.TheKey,
                content: this.content
            }));
            this.lastContent = this.content;
            this.$toast.clearQueue();
            this.$toast.save("Sparar ändringar...");
            axios.post(window.serverUrl + '/api/backend/translations/save_content.php', fd)
              .then(response => {
                this.isSaving = false;
                  //JSON.stringify(response, null, 4)
                  //console.log(response);
                    if(response.status === 200){
                        if (response.data.status != "OK") {
                            //console.log("Could not save data for key...:", this.TheKey);
                        } else {
                            this.modificationDate = response.data.datetime;
                            // this.replaceLocalStorage();
                            let obj = {};
                            obj.Key = this.TheKey;
                            obj.Content = this.content;
                            obj.CreationDate = this.modificationDate;   // just to fill creationdate with something
                            obj.ModificationDate = this.modificationDate;
                            this.setLocalStorage(obj);

                            this.$toast.clearQueue();
                            this.$toast.save("Ändringarna sparades till databasen.");
                            this.hadData = true;
                        }
                    } else {
                      console.log("Error saving content for Key: " + this.TheKey + " in backend.")
                    }
              })
              .catch(e => {
                this.isSaving = false;
                console.log(e);
              })
        },
        getLocalStorage() {
            let a = {
                Key:this.TheKey,
            };

            this.$store.dispatch("setTranslationFromLocalStorage", a).then((result) => {
              //  console.log("getLocalStorage - setTranslationFromLocalStorage RESULTAT: ", result);
             
                // NOTE: Fix så att inte användare får gammal data från localstorage. Eftersom inte modification-data jämförs så tog
                // koden för givet att localstorage data skulle användas. 
                // Sedan hämtas bara ny data efter 10 minuter.
                // Resulterade i att om en användar gått in på sidan innan en content uppdatering, och besökte sedan sidan efteråt
                // så fick de bara gammal data från localstorage... obra!
             
              //  if(!result) {
                    this.fetchKeyData();
             //   }
                this.lastContent = this.storeContent;
                this.hadData = true;
            });
        },
        setLocalStorage(obj) {
            this.$store.dispatch("setTranslationFromServer", obj).then((result) => {
              //  console.log("setLocalStorage result: ", result);
                this.lastContent = this.storeContent;
                return result;
            });
        }
    }

}
</script>
<style scoped>
</style>    