<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-chip 
                v-on="on"
                dark
                class="ma-2 float-left"
                :color="chipColor"
            >
                <v-icon left small>{{icon}}</v-icon>
                {{category}}
            </v-chip>
        </template>
        <span>
            Måltyp: {{tooltipResult}}
        </span>
    </v-tooltip>
</template>
<script>
    export default {
    name: 'WeightCategoryChip',
    components: {

    },
    props: {
        category: {
            type: String,
            required: false,
            default: ""
        },
        tooltip: {
            type: String,
            required: false,
            default: ""
        },
        icon: {
            type: String,
            required: false,
            default: ''
        }
    },  
    data: () => ({
        type: ""
    }),
    created() {
        if(this.category=="Teoretisk")  {
            this.type=1;
        } else if(this.category=="Praktisk") {
            this.type=2;
        } else if(this.category=="Teoretisk och praktisk") {
            this.type=3;
        }
    },
    mounted() {

    },
    computed: {
        chipColor() {
            if(this.type==1) {
                return "blue darken-1";
            }
            else if(this.type==2) {
                return "red lighten-1";
            }
            else if(this.type==3) {
                return "green darken-1";
            }
        },
        tooltipResult() {
            if(this.category) {
                return this.category;
            } else {
                return '...';
            }
        }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
