import Vue from "vue";
import axios from "axios";

const state = {
    notifications: {
        active: true,
        showNoticeCenter: false,
        showDialogMenu: false,
        dialogMenuPos: { x: 0, y: 0 },
        sendEmail: false,
        message: "",
        title: "",
        targetUserId: null,
        targetUserEmail: null,
        targetUserName: null,
        correspondance: null,
        serverDateTime: null,
        sendMessageAsEmailConfirmDialog:false,
        messageIdToEmail:null,
    },
};
const getters = {
};
const mutations = {
      setShowNoticeCenter(state, i) {
        state.notifications.showNoticeCenter = i;
      },
      setMessage(state, i) {
        state.notifications.message = i;
      },
      setMessageTitle(state, i) {
        state.notifications.title = i;
      },
      setMessageTargetUserId(state, i) {
        state.notifications.targetUserId = i;
      },
      setMessageTargetUserEmail(state, i) {
        state.notifications.targetUserEmail = i;
      },
      setMessageTargetUserName(state, i) {
        state.notifications.targetUserName = i;
      },
      setMessageSendEmail(state, i) {
        state.notifications.sendEmail = i;
      },
      setCorrespondance(state, i) {
        state.notifications.correspondance = i;
      },
      addCorrespondance(state, i) {
        //  console.log("Push new notice to array...")
        //  console.log(state.notifications.correspondance)
        if (state.notifications.correspondance == null) {
          state.notifications.correspondance = [];
        }
        state.notifications.correspondance.push(i);
        //  console.log(state.notifications.correspondance)
      },
      removeCorrespondanceMessage(state, i) {
        let n = state.notifications.correspondance
          .map((item) => item.Id)
          .indexOf(i); // find index of your object
        state.notifications.correspondance.splice(n, 1);
      },
      setServerDateTime(state, i) {
        state.notifications.serverDateTime = i;
      },
      setShowDialogMenu(state, i) {
        state.notifications.showDialogMenu = i;
      },
      setDialogMenuPos(state, i) {
        state.notifications.dialogMenuPos = i;
      },
      setSendMessageAsEmailConfirmDialog(state, i) {
        state.notifications.sendMessageAsEmailConfirmDialog = i;
      },
      setMessageIdToEmail(state, i) {
        state.notifications.messageIdToEmail = i;
      }
};
const actions = {
    checkNotices({ commit, state }, vm) {
        return new Promise((resolve, reject) => {
          //console.log("Check notifications in store.")
  
          //Get notices from date -> state.notifications.serverDateTime
          /* if (state.notifications.serverDateTime != null) {
              console.log("Last checked:" + state.notifications.serverDateTime);
            }
          */

          let postData = {
            UserId: this.state.everything.user.user_id,
            FromUserId: null,
            LastDate: state.notifications.serverDateTime,
          };
  
          var fd = new FormData();
          fd.append("req", "get_notices");
          fd.append("data", JSON.stringify(postData));
  
          axios
            .post(window.serverUrl + "/api/backend/notifications.php", fd)
            .then((response) => {
              JSON.stringify(response, null, 4);
  
              if (response.status === 200) {
                
                if(response.data.status=="OK") {
                  //  console.log("Done fetching notices.")
                  if (response.data.data.length > 0) {
                  //  console.log("New messages arrived!");
                    
                    for(let i=0;i<response.data.data.length;i++) {
                      if(response.data.data[i].ToUserId=="-1") {
                        vm.$toast.warning(response.data.data[i].Message);
                      //  console.log(response.data.data[i].Message);
                      }
                    }

                    let o = [];
                    let oldNotices = localStorage.getItem("Notices");

                    if(oldNotices) {
                      o = JSON.parse(oldNotices);
                    }

                    o.unshift(...response.data.data);
                    let newNotices = localStorage.setItem("Notices", JSON.stringify(o));
                    // console.log("Setting oldNotices to localStorage: ", o );
                    // console.log(this.state.everything.user.notices.length);
  
                    if (response.data.datetime !== false) {
                      commit("setServerDateTime", response.data.datetime);
                      //  console.log(
                      //    "Server time set to: " + state.notifications.serverDateTime
                      //  );
                    }
  
                    if (this.state.everything.user.notices.length === 0) {
                      //  console.log("Set...");
                      commit("setNotices", response.data.data);
                    } else {
                      //  console.log("Adding new notices:");
                      //  console.log(response.data.data);
                      commit("addNotices", response.data.data);
                    }
  
                    // Count unread messages
                    let u = 0;
  
                    for (let cnt = 0; cnt < response.data.data.length; cnt++) {
                      //  console.log(this.state.everything.user.notices[cnt]["ShowedDate"]);
                      if (response.data.data[cnt]["ShowedDate"] == undefined) {
                        //   console.log("Notice not showed:" + this.state.everything.user.notices[cnt]["Id"])
                        u++;
                      }
                    }
  
                    commit("setUnreadNotices", u);
                  }
  
                  resolve();
                } 
                else if(response.data.status=="UNAUTHORIZED") {
                  console.log("Wrong token provided");
                  reject();
                }
              } else {
                console.log("Error loading data from backend.");
                reject();
              }
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
        }); // end of ret promise
      },
      markReadNotices({ commit, state }) {
        return new Promise((resolve, reject) => {

          let noticesToMark = [];
          console.log("Check for new notifications in store.");
          //  console.log(this.state.everything.user.notices)
  
          if (this.state.everything.user.notices === 0) {
            resolve();
            return;
          }
  
          for (let cnt = 0; cnt < this.state.everything.user.notices.length; cnt++) {
            if (this.state.everything.user.notices[cnt]["ShowedDate"] == undefined) {
              noticesToMark.push({ Id: this.state.everything.user.notices[cnt]["Id"] });
            }
          }
  
          if (noticesToMark.length == 0) {
            resolve();
            return;
          }
  
          //  console.log("List of Notices to mark:");
          //  console.log(noticesToMark);
  
          var fd = new FormData();
          fd.append("req", "mark_read_notices");
  
          let postData = noticesToMark;

        //  console.log(postData);
        //  return;
  
          fd.append("data", JSON.stringify(postData));
  
          axios
            .post(window.serverUrl + "/api/backend/notifications.php", fd)
            .then((response) => {
              JSON.stringify(response, null, 4);
  
              if (response.status === 200) {
                let data = response.data.data;
                //  console.log(response.data);
                if (data.length > 0) {
                  for (let cnt = 0; cnt < data.length; cnt++) { 
                    let o = data[cnt];
                    var elementPos = this.state.everything.user.notices.findIndex(x => x.Id == o["Id"]);
  
                    this.state.everything.user.notices[elementPos]["ShowedDate"] = data[cnt]["ShowedDate"];
                    this.state.everything.user.notices[elementPos]["Showed"] = data[cnt]["Showed"];

                    let loc_notices = localStorage.getItem("Notices");
                    loc_notices = JSON.parse(loc_notices);
                    loc_notices[elementPos]["ShowedDate"] = data[cnt]["ShowedDate"];
                    loc_notices[elementPos]["Showed"] = data[cnt]["Showed"];

                    localStorage.setItem("Notices", JSON.stringify(loc_notices));
                  }
  
                  commit("setUnreadNotices", 0);
                }
  
                resolve();
              } else {
                console.log("Error loading data from backend.");
                resolve();
              }
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
        }); // end of ret promise
      },
      getCorrespondance({ commit, state }, i_id) {
        return new Promise((resolve, reject) => {
        //  console.log("------------------------------");
        //  console.log("Check correspondance in store.");
  
          var fd = new FormData();
          fd.append("req", "get_notices");
  
          //  console.log("My UserId is:" + this.state.everything.user.user_id)
  
          let postData = {
            UserId: i_id,
            FromUserId: this.state.everything.user.user_id,
            IsChat: true,
            LastDate: ""
          };
  
          // fetch only NEW messages
          if (state.notifications.correspondance != null) {
            if (state.notifications.correspondance.length > 0) {
              let i = state.notifications.correspondance.length - 1;
              //  console.log("Latest message: " + state.notifications.correspondance[i].CDate)
              postData.LastDate = state.notifications.correspondance[i].CDate;
            }
          }
  
          //  console.log("My UserId is:" + this.state.everything.user.user_id)
          //  console.log(postData)
  
          fd.append("data", JSON.stringify(postData));
  
          axios
            .post(window.serverUrl + "/api/backend/notifications.php", fd)
            .then((response) => {
              JSON.stringify(response, null, 4);
  
              if (response.status === 200) {
                //  console.log("Done fetching correspondance.")
                //  console.log(response.data.data)
                if (response.data.datetime !== false) {
                  //console.log("SetServerDateTIme: ", response.data.datetime);
                  commit("setServerDateTime", response.data.datetime);
                  //  console.log("Server time set to: " + state.notifications.serverDateTime)
                }
  
                if (state.notifications.correspondance != null) {
                  if (response.data.data.length > 0) {
                    //  console.log("New message!")
                    for (let a = 0; a < response.data.data.length; a++) {
                      //  console.log(response.data.data[a])
                      commit("addCorrespondance", response.data.data[a]);
                      //  console.log(state.notifications.correspondance)
                    }
  
                    resolve({ updateScroll: true });
                  } else {
                    //  console.log("No new messages!")
                    resolve({ updateScroll: false });
                  }
                } else {
                  //  console.log("Set initial messages.")
                  if (response.data.data.length > 0) {
                    commit("setCorrespondance", response.data.data);
                    resolve({ updateScroll: true });
                  } else {
                    commit("setCorrespondance", null);
                    resolve({ updateScroll: false });
                  }
  
                }
              } else {
                console.log("Error loading data from backend.");
                resolve({ updateScroll: false });
              }
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
        }); // end of ret promise
      },
      sendMessage({ commit, state }, i_postData) {
        return new Promise((resolve, reject) => {
          var fd = new FormData();
          fd.append("req", "write_notice");
  
          commit("setMessageTitle", i_postData.Title);
          commit("setMessage", i_postData.Message);
          commit("setMessageTargetUserId", i_postData.UserId);
          //  this.$store.commit("setMessageTargetUserEmail", "")
        //  commit("setMessageTargetUserName", "");
          commit("setMessageSendEmail", i_postData.SendAsEmail);
  
          /*
            let postData = {
                SendAsNotification: true,
                FromUserId: fromUserId,
                UserId: state.notifications.targetUserId,
                Title: state.notifications.title,
                Message: state.notifications.message,
                SubmissionId: null,
                CourseId: null,
                SendAsEmail: state.notifications.sendEmail,
                SendToCourseMentors: sendToCourseMentors
            }
  
            */
  
           // console.log(i_postData);
  
          fd.append("data", JSON.stringify(i_postData));
  
          axios
            .post(window.serverUrl + "/api/backend/notifications.php", fd)
            .then((response) => {
              JSON.stringify(response, null, 4);
  
              //  console.log(response.data.addNoticeStatus.data)
              // console.log(state.notifications.message);
  
              if (response.status === 200) {

                if (i_postData.SendToCourseMentors != true) {
                  let d = response.data.addNoticeStatus.data;
                  d.Message = state.notifications.message;
                  d.FromUserId = i_postData.FromUserId;
                  d.ToUserId = state.notifications.targetUserId;
                  commit("addCorrespondance", d);
                }

                if(response.data.addNoticeStatus.recipientOnlineStatus) {
                  if(response.data.addNoticeStatus.recipientOnlineStatus.data.length === 0) {
                    console.log("User NOT online!");  
                    // Show dialog - Användaren är inte online, vill du skicka meddelandet som epost för att uppmärksamma mottagaren?
                    // Message is not sent directly if user is offline.
                    commit("setMessageIdToEmail", response.data.addNoticeStatus.data.Id);
                    commit("setSendMessageAsEmailConfirmDialog", true);
                  } else {
                    console.log("User IS online!");
                  }
                  console.log(response.data.addNoticeStatus.recipientOnlineStatus.data);
                }
  
              } else {
                console.log("Error loading data from backend.");
              }
              resolve();
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
        }); // promise-end
      }, // sendMessage-end
      sendLastMessageAsEmail({commit, state}) {
        return new Promise((resolve, reject) => {
          var fd = new FormData();
          fd.append("req", "send_notice_via_email");

          let postData = {
            Id: state.notifications.messageIdToEmail
          };

          fd.append("data", JSON.stringify(postData));
  
          axios
            .post(window.serverUrl + "/api/backend/notifications.php", fd)
            .then((response) => {
              JSON.stringify(response, null, 4);

              commit("setMessageIdToEmail", null);

              if (response.status === 200) {
                // console.log(response);
                resolve();
              } else {
                console.log("Error loading data from backend.");
                resolve();
              }
            })
            .catch((e) => {
              console.log(e);
              reject();
            });

        });
      },
      showMessageDialog({ commit, state }, i_obj) {
        if (i_obj.id == -1) {
          i_obj.scope.$toast.warning("<strong>Systemmeddelanden går ej att svara på!</strong>");
          return;
        }
        console.log("Öppnar dialog! [DEBUG TEs†] ")
  
        // console.log(i_obj);
        // console.log("new id: " + i_obj.id)
        // console.log("curr id: " + state.notifications.targetUserId)
        if (i_obj.id != state.notifications.targetUserId) {
          //  console.log("reset messages.")
          commit("setCorrespondance", null); // reset dialog if we open a new userChat
        }
  
        commit("setShowNoticeCenter", true);
        commit("setMessageTargetUserId", i_obj.id);
        commit("setMessageTargetUserName", i_obj.name);
  
        this.dispatch("getCorrespondance", i_obj.id).then(
          () => {
            var e = document.getElementById("message-container");
            e.scrollTop = e.scrollHeight;
          },
          (error) => {
            console.log(error);
          }
        );
      },
      removeNote({ commit, state }, i) {
        return new Promise((resolve, reject) => {
          //  let task = o.task
  
          var fd = new FormData();
          fd.append("req", "remove_notice");
  
          let postData = {
            UserId: this.state.everything.user.user_id,
            Id: i.Id,
          };
  
          fd.append("data", JSON.stringify(postData));
  
          axios
            .post(window.serverUrl + "/api/backend/notifications.php", fd)
            .then((response) => {
              JSON.stringify(response, null, 4);
  
              if (response.status === 200) {
              //  console.log(response);
                commit("removeCorrespondanceMessage", i.Id);
                resolve();
              } else {
                console.log("Error loading data from backend.");
                resolve();
              }
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
        });
      }
};

export default { namespaced: false, state, getters, mutations, actions };