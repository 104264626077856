// import Vue from "vue";
import axios from "axios";

const state = {
    users: [],
};
const getters = {
};
const mutations = {
    addUser(state, i) {
        state.users.push(i);
    }
};
const actions = {
    getPreLoadedUser({commit, state}, user_id) {
        return new Promise((resolve, reject) => {
        //    console.log("is :" + user_id + " preloaded? ");
            let ix = state.users.findIndex(x => x.Id === user_id);
            if(ix != -1) {
            //    console.log("User exists! ");
                resolve(state.users[ix]);
            } else {
            //    console.log("User did not exist! Add to store! ");
                
                let dataToServer= {
                    UserId: this.state.everything.user.user_id,
                    Id: user_id // the user we want to access
                }

                var fd = new FormData()
                fd.append('req', 'get_user')
                fd.append('data', JSON.stringify(dataToServer) )

                axios.post(window.serverUrl + '/api/backend/userdata.php', fd)
                .then(response => {
                    JSON.stringify(response, null, 4)

                    if(response.status === 200) {
                        commit("addUser", response.data.data[0]);
                        resolve(response.data.data[0])
                    }
                })
                .catch(e => {
                    console.log(e)
                    reject();
                })
            }

        });

    }
};

export default { namespaced: false, state, getters, mutations, actions };