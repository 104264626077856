import { Node, mergeAttributes  } from '@tiptap/core'

//import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core'
import { find, registerCustomProtocol, reset } from 'linkifyjs'
//import { Plugin, PluginKey } from 'prosemirror-state'

export const CustomIcon = Node.create({
  name: 'bdi',
  
  group: 'inline',
  inline: true,
  /*marks: '_',*/
  draggable: false,
  selectable: true,
  allowGapCursor: false,
  exitable: true,
  spanning: false,
  atom: true,
  isolating: true,
  content: 'text*',
  renderHTML({ HTMLAttributes }) {
    return ['bdi', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  parseHTML() { return [{ tag: 'bdi' }] },
  addAttributes() {
    return { class: { default: '' }, contenteditable: 'false' };
  },
  addOptions() {
    return {
      protocols: [],
      HTMLAttributes: {
        class: null,
      },
      validate: undefined,
    }
  },

  priority: 1000,

  keepOnSplit: false,

  onCreate() {
      this.options.protocols.forEach(registerCustomProtocol);
   //   console.log(this);
   //   console.log(this.options);
  },

  onDestroy() {
      reset()
  },
  /*
  addCommands() {
    return {
      setImage: options => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options,
        })
      },
    }
  },*/
  addCommands() {
    return {
      addFigure: () => ({ chain }) => {
        return chain()
          .insertContent({ type: this.name })
          // set cursor after horizontal rule
          .command(({ tr, dispatch }) => {
            if (dispatch) {
              const { $to } = tr.selection
              const posAfter = $to.end()

              if ($to.nodeAfter) {
                tr.setSelection(TextSelection.create(tr.doc, $to.pos))
              } else {
                // add node after horizontal rule if it’s the end of the document
                const node = $to.parent.type.contentMatch.defaultType?.create()

                if (node) {
                  tr.insert(posAfter, node)
                  tr.setSelection(TextSelection.create(tr.doc, posAfter))
                }
              }

              tr.scrollIntoView()
            }

            return true
          })
          .run()
      },
    }
  },
})





/*

import {
  mergeAttributes,
  Node,
  nodeInputRule,
} from '@tiptap/core'
import { TextSelection } from 'prosemirror-state'

export interface HorizontalRuleOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    horizontalRule: {

      setHorizontalRule: () => ReturnType,
    }
  }
}

export const HorizontalRule = Node.create<HorizontalRuleOptions>({
  name: 'horizontalRule',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  group: 'block',

  parseHTML() {
    return [
      { tag: 'hr' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['hr', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setHorizontalRule: () => ({ chain }) => {
        return chain()
          .insertContent({ type: this.name })
          // set cursor after horizontal rule
          .command(({ tr, dispatch }) => {
            if (dispatch) {
              const { $to } = tr.selection
              const posAfter = $to.end()

              if ($to.nodeAfter) {
                tr.setSelection(TextSelection.create(tr.doc, $to.pos))
              } else {
                // add node after horizontal rule if it’s the end of the document
                const node = $to.parent.type.contentMatch.defaultType?.create()

                if (node) {
                  tr.insert(posAfter, node)
                  tr.setSelection(TextSelection.create(tr.doc, posAfter))
                }
              }

              tr.scrollIntoView()
            }

            return true
          })
          .run()
      },
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: /^(?:---|—-|___\s|\*\*\*\s)$/,
        type: this.type,
      }),
    ]
  },
})
*/