<template>
  <v-container fluid mb-8>
    <v-row align="center" justify="center">
      <v-col cols="12" md="7">
        <v-card flat class="mx-auto" :class="{'pa-8': $vuetify.breakpoint.smAndUp}" style="margin-bottom: 64px">
          <v-card-title class="mb-4">
            <h1>{{ $route.name }}</h1>
          </v-card-title>
          <v-card-text>
            <content-area :TheKey="$route.name + '.MainContent'">
                <template v-slot:DefaultContent>
                    Författa en text...
                </template>
            </content-area>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</v-container>
</template>
  
  <script>
import ContentArea from '../../components/texts/ContentArea.vue';
  
    export default {
      components: {
        ContentArea
      },
      data: () => ({

      })
    }
  </script>
  