import Vue from "vue";
import Vuex from "vuex"; /* , { Store } */

import everything from "./models/everything";
import notices from "./models/notices";
import profiling from "./models/profiling";
import preloaded_data from "./models/preloaded_data";
import groups from "./models/groups";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: false,
    modules: {
        everything,
        notices,
        profiling,
        preloaded_data,
        groups
    },
    strict: false
});