import Vue from "vue";
import axios from "axios";

const state = {
    count: 0,
    error: {
      routeDenied: null,
    },
    apptitle: localStorage.getItem("apptitle")?localStorage.getItem("apptitle"):"ValiWeb VUX",
    courseEditor: {
      currentCourseId: null,
      currCoursePostId: -1,
      courseData: null,
      courseMentors: null,
      mentorsLoaded: false,
      currentCourseEditedById: null,
      currentCourseEditedByUsername: null,
      currentCourseEditedSaveTime: null,
      showCourseSettings: false,
      showCoursePartSettings: false,
      showCourseNav: false,
      userExamId: null,
      userExamData: null,
      userExamAutoResult: null,
      userExamQuestCnt: null,
      timeToResetByIdle: null, // debug-var
      defaultMinutesToIdle: 55,
      isEditing: false,
      previewState: true,
      currentValiFormSubmission: null,
      mentorViewCourseAsUser: null,
      navBack: null,
      navForward: null,
      reloadCourseMenu: null,
      editingUser:null,
      pages: {
        total: 0,
        current: 0,
      },
    },
    isPageEditActivated: false,
    isSaving:false,
    user: {
      user_id: localStorage.getItem("user_id")?localStorage.getItem("user_id"):0,
      username: "",
      realname: "",
      userData: [],
      isValidator: true,
      userPerm: {
        editCourse: false,
      },
      notices: [],
      unreadNotices: 0,
      taskList: [],
    },
    debugRegisterUserData: {
      code: "VALVOUSK",
      userName: "info@morningdewmedia.com",
      firstName: "Sven",
      lastName: "Svensson",
      adress: "Fisk 13",
      postnr: "91333",
      city: "Holmsund",
      cellPhone: "0701234567",
      phone: "4321",
      orgnr: "8404251234",
      email: "feedback@morningdewmedia.com",
      email2: "feedback@morningdewmedia.com",
      password: "1234",
      password2: "1234",
      workplace: "Nada",
      preferredLocale: "SE",
      interests: [4,8,10],
      gdpr_consent: true,
    },
    translations: [] 
};
const getters = {
};
const mutations = {
    /* changeName (state, payload) {
      state.user.fullName = payload
    }, */
    setAppTitle(state, i) {
      state.apptitle = i;
      localStorage.setItem("apptitle", i);
    },
    setPageEditActivation(state, i) {
      state.isPageEditActivated = i;
    },
    setCurrPostId(state, i) {
      state.courseEditor.currCoursePostId = i;
    },
    setShowCourseSettings(state, i) {
      state.courseEditor.showCourseSettings = i;
    },
    setShowCoursePartSettings(state, i) {
      state.courseEditor.showCoursePartSettings = i;
    },
    setCourseNavBack(state, i) {
      state.courseEditor.navBack = i;
    },
    setCourseNavForward(state, i) {
      state.courseEditor.navForward = i;
    },
    setCourseData(state, i) {
      state.courseEditor.courseData = i;
    },
    setTimeToResetByIdle(state, newT) {
      state.courseEditor.timeToResetByIdle = newT;
    },
    updateCourseEditedLastTime(state) {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var now = date + " " + time;
      state.courseEditor.currentCourseEditedSaveTime = now;
    },
    resetCourseEditedSaveTime(state) {
      state.courseEditor.currentCourseEditedSaveTime = null;
      state.courseEditor.timeToResetByIdle = null;
    },
    setCurrentCourseId(state, newId) {
      //  console.log("Course is set to:" + newId)
      state.courseEditor.currentCourseId = newId;
    },
    setUserId(state, newId) {
      // console.log("Set store state user Id to:" + newId)
      state.user.user_id = newId;
    },
    setUserName(state, newName) {
      // console.log("Set store state user Id to:" + newId)
      state.user.username = newName;
    },
    setUserData(state, i) {
      state.user.userData = i;
    },
    setEditCourse(state, canEditCourse) {
      //  console.log("Set store state permission to edit course:" + canEditCourse)
      state.user.userPerm.editCourse = canEditCourse;
    },
    setCourseIsEditedBy(state, obj) {
      state.courseEditor.currentCourseEditedById = obj.userId;
      state.courseEditor.currentCourseEditedByUsername = obj.username;
      // console.log("Course is edited by id: " + state.courseEditor.currentCourseEditedById + " | " + state.courseEditor.currentCourseEditedByUsername)
      if (state.courseEditor.currentCourseEditedById == state.user.user_id) {
        //console.log("The Editor is YOU!")
        state.courseEditor.previewState = false;

        // Reset lastSavedTime
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var now = date + " " + time;

        var timeDiff = state.courseEditor.defaultMinutesToIdle * 60000; // milliseconds = 15 min * 60,0000
        //  var timeDiff = 0.1 * 60000
        var timeToIdle = now;
        timeToIdle = Date.parse(timeToIdle) - timeDiff;

        this.commit("setTimeToResetByIdle", timeToIdle);
        this.commit("updateCourseEditedLastTime");
      }
    },
    setValiFormSubmission(state, i) {
      state.courseEditor.currentValiFormSubmission = i;
    },
    setUserValidator(state, isValidator) {
      //  console.log("Set store state permission to edit course:" + canEditCourse)
      state.user.isValidator = isValidator;
    },
    addNumber(state, newNumber) {
      //  console.log(newNumber)
      state.count = newNumber;
    },
    setEditorPreviewState(state, isOn) {
      //  console.log("Setting preview state: " + isOn)
      state.courseEditor.previewState = isOn; // !state.courseEditor.previewState
    },
    setUserExamId(state, i) {
      //  console.log("Set UserExam Id:" + i)
      state.courseEditor.userExamId = i;
    },
    setUserExamData(state, i) {
      state.courseEditor.userExamData = i;
    },
    setUserExamDataStatus(state, i) {
      state.courseEditor.userExamData[i.index].Status = i.status;
    },
    setUserExamDataUserCanEdit(state, i) {
      state.courseEditor.userExamData[i.index].UserCanEdit = i.userCanEdit;
    },
    setUserExamAutoResult(state, i) {
      state.courseEditor.userExamAutoResult = i;
    },
    addToUserExamAutoResult(state, i) {
      state.courseEditor.userExamAutoResult += i;
    },
    setUserExamQuestionCount(state, i) {
      state.courseEditor.userExamQuestCnt = i;
    },
    addCoursePart(state, i) {
      state.courseEditor.courseData["CourseParts"].push(i);
    },
    setNotices(state, i) {
      /* console.log(state.user.notices) */
      state.user.notices = i;
      /* console.log(state.user.notices) */
    },
    addNotices(state, i) {
      for (let cnt = 0; cnt < i.length; cnt++) {
        state.user.notices.unshift(i[cnt]);
      }
    },
    setRealName(state, i) {
      state.user.realname = i;
    },
    setUnreadNotices(state, i) {
      state.user.unreadNotices = i;
    },
    setMentorViewCourseAsUser(state, i) {
      state.courseEditor.mentorViewCourseAsUser = i;
    },
    setRouteDenied(state, i) {
      state.error.routeDenied = i;
    },
    setTotalPages(state, i) {
      state.courseEditor.pages.total = i;
    },
    setCurrentPage(state, i) {
      state.courseEditor.pages.current = i;
    },
    setShowCourseNav(state, i) {
      // console.log("SETTING COURSENAV in Store to:" + i)
      state.courseEditor.showCourseNav = i;
    },
    setTaskList(state, i) {
      state.user.taskList = i;
    },
    setTaskListAtPos(state, i) {
      state.user.taskList[i["Pos"]]["CompletedDate"] = i["CompletedDate"];
      state.user.taskList[i["Pos"]]["CompletedByUserId"] =
        i["CompletedByUserId"];
    },
    addTaskListItem(state, i) {
      state.user.taskList.push(i);
    },
    setMentors(state, i) {
      state.courseEditor.courseMentors = i;
    },
    setEditingUser(state, i) {
      state.courseEditor.editingUser = i
    },
    removeTasks(state, rows) {
      for(let cnt=0;cnt<rows.length;cnt++) {
        let ix = state.user.taskList.findIndex(x => x.Id === rows[cnt].Id);
        state.user.taskList.splice(ix,1);
      }
    },
    reloadCourseMenu(state, i) {
      console.log("Reloading course menu (store)... " + i + " ");
      state.courseEditor.reloadCourseMenu = i;
    },
    setUseMentorText(state, i) {
      console.log("Set New UseMentorText: " + i);
      state.courseEditor.courseData['FooterMentorCopy']['UseCustomText'] = i;
    },
    setIsEditing(state, i) {
      state.courseEditor.isEditing = i;
    },
    setIsSaving(state, i) {
      state.isSaving = i;
    },
    setTranslations(state, i) {
      state.translations = i;
    },
    setTranslationIndex(state, i) {
    //  console.log("setting index: ", i.index);
      state.translations[i.index] = i.obj;
    },
    setTranslationAtIndex(state, i) {
    //  console.log("setting index: ", i.index);
      state.translations[i.index].Content = i.Content;
      state.translations[i.index].CreationDate = i.CreationDate;
      state.translations[i.index].ModificationDate = i.ModificationDate;
    //  console.log("Data som ska settas: ", i);
    },
    addTranslation(state, i) {
      state.translations.push(i);
    },
    /*
    setMentorsLoaded(state, i) {
      state.courseEditor.mentorsLoaded = i
    }
*/

};
const actions = {
    setPageEditActivation({ commit }, iValue) {
      commit("setPageEditActivation", iValue);
    },
    setUser({ commit }, i_routerTo) {
      return new Promise((resolve, reject) => {
        var token = localStorage.getItem("user-token");

        if(!token) {
          resolve();
          return;
        }

        let accessData = new Object();
        
      //  console.log("Set USER in store");
      // console.log(i_routerTo["name"]);
      // console.log("Id: " + i_routerTo.params.id);
      //  console.log("Reset Denied!");
        commit("setRouteDenied", null); // reset so we don't show errors

        if (i_routerTo["name"] == "kurs" || i_routerTo["name"] == "/delmoment") {
          //  console.log("Is user eligable for the course-id:" + i_routerTo.params.id)
          accessData.CourseId = i_routerTo.params.id;
        }

        var postData = {
          token: token,
          access: accessData
        }

        var fd = new FormData();
        fd.append("req", "auth_user");
        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/auth.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);
            //  console.log("Re-Authorize user on refresh.");
          //  console.log(response.data)

            if (response.status === 200) {
              if (response.data.status == "OK") {
              //  console.log(response.data.data)
                localStorage.user_id = response.data.data["Id"];
                localStorage.username = response.data.data["Username"];
                localStorage.userType = response.data.data["RelatedUserType"];
                localStorage.submissionId = response.data.data["SubmissionId"];

                commit("setUserData", response.data.data);  // contains all user data, except password
                /*
                let a = {
                  Id: response.data.data["SubmissionId"]
                }
                commit("setValiFormSubmission", a);
                */

                // console.log(response.data.data)

                // Prevent normal users from accessing certain pages!

                if (response.data.data["RelatedUserType"] == 1) {
                //  console.log("page: " + i_routerTo["name"]);
                  if (
                    i_routerTo["name"] == "KursLista" ||
                    i_routerTo["name"] == "Akter" ||
                    i_routerTo["name"] == "Akter/" ||
                    i_routerTo["name"] == "Användare" ||
                    i_routerTo["name"] == "Användare/" ||
                    i_routerTo["name"] == "To do" ||
                    i_routerTo["name"] == "To do/" ||
                    i_routerTo["name"] == "Versionshistorik"
                  ) {
                    console.log("Normal user should NOT have access to this page...");

                    commit("setRouteDenied", "Du har tyvärr ingen behörighet till sidan.");

                    resolve();
                    return;
                  }
                }
                
                if(response.data.data.RelatedUserType==1) {
                  if (response.data.CourseAccess !== undefined) {
                    if (response.data.CourseAccess == 0) {
                      // console.log("Du har inte behörighet till kurs med id:" + i_routerTo.params.id);
                      commit("setRouteDenied", "Du har tyvärr ingen behörighet till kurs med id:" + i_routerTo.params.id);
                      resolve();
                      return;
                    }
                  } 
                 /* else {
                    commit("setRouteDenied", null); // reset so we don't show errors
                  }
                */                
                } 

                //  localStorage.post_id = -1   // reset since we are NOT editing a sub-post
                //  console.log("User Exam Id from server:")
                //  console.log(response.data["userExamId"])
                if (response.data["userExamId"] != null) {
                  commit("setUserExamId", response.data["userExamId"]);
                }

                // this.$store.state.user.user_id = response.data.data["Id"]
                commit("setUserId", response.data.data["Id"]);
                commit("setUserName", response.data.data["Username"]);
                commit(
                  "setRealName",
                  response.data.data["FirstName"] +
                    " " +
                    response.data.data["LastName"]
                );

                commit("setUserValidator", true); // init var
                commit("setEditCourse", false); // init var

                // Set userPermissions (Show or hide certain UI elements, depending on userType)
                if (response.data.data["RelatedUserType"] == 2 || response.data.data["RelatedUserType"] == 3) {
                  // Superadmin / Webadmin
                  commit("setEditCourse", true);
                  commit("setUserValidator", false);
                } 
                else if(response.data.data["RelatedUserType"] == 4) {
                  commit("setUserValidator", false);

                  if(response.data.CanEdit=='1') {
                    commit("setEditCourse", true);
                  } else {
                    commit("setEditCourse", false);
                  }
                }

                //  console.log(response.data.data)
              /*  localStorage.setItem("user-token", JSON.stringify(response.data.data)); */
                // router.push('/Validering')
                resolve();
              } else {
              //  console.log("FAIL..." + response.data.result);
                localStorage.removeItem("user-token");
                localStorage.removeItem("username");
                localStorage.removeItem("avatar");
                delete axios.defaults.headers.common["Authorization"];

                commit("setRouteDenied", response.data.result);

                resolve();
              }
            } else {
              console.log("Error loading data from backend.");
              reject();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });

        /*
        axios
          .get(`${state.api}user`)
          .then(response => {
            commit('setUserData', response.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
          */
      });
    }, // setUser END
    activateCourseEdit({ commit, state }) {
      return new Promise((resolve, reject) => {
        // check if course is locked by other user
        var fd = new FormData();
        fd.append("req", "lock_course_for_editing");

        let postData = {
          CourseId: state.courseEditor.currentCourseId,
          CoursePartId: state.courseEditor.currCoursePostId
        };

        fd.append("data", JSON.stringify(postData));

        // const vm = this

        axios
          .post(window.serverUrl + "/api/backend/course_lock.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);
            //  console.log(response)

            if (response.status === 200) {
              if (response.data.status == "OK") {
                commit("setEditorPreviewState", false);
                commit("setIsEditing", true);

                commit("setCourseIsEditedBy", {
                  userId: state.user.user_id,
                  username: state.user.username,
                });
                //commit("updateCourseEditedLastTime");

                resolve();
              } else {
                
                console.log(response.data.data);

                commit("setCourseIsEditedBy", {
                  userId: response.data.data.RelatedUserId,
                  username: response.data.data.Username,
                });
                //  console.log("Noooo")
                resolve();
              }
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    }, // activateCourseEditing END
    closeCourseEdit({ commit, state }, vm) {
      let courseId = -1;
      let coursePartId = -1;

      if(vm) {
        courseId = vm.CourseId;
        coursePartId = vm.CoursePartId;
      } else {
        courseId = state.courseEditor.currentCourseId;
        coursePartId = state.courseEditor.currCoursePostId;
      }

      return new Promise((resolve, reject) => {
        let err = false;
        commit("setCourseIsEditedBy", { userId: null, username: null });
        commit("setEditorPreviewState", true);
        commit("setIsEditing", false);
        commit("setShowCoursePartSettings", false);
        commit("resetCourseEditedSaveTime");

        var fd = new FormData();
        fd.append("req", "close_course_for_editing");

        let postData = {
          CourseId: courseId,
          CoursePartId: coursePartId
        };

        fd.append("data", JSON.stringify(postData));

        // const vm = this

        axios
          .post(window.serverUrl + "/api/backend/course_lock.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);
            //  console.log(response)

            if (response.status === 200) {
              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            err = true;
          });
        if (err) reject();
      });
    }, // activateCourseEditing END
    updateIdleTime({ commit, state }, vm) {
      return new Promise((resolve) => {
        if (state.courseEditor.previewState == false) {
          var today = new Date();
          var date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          var time =
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
          var now = date + " " + time;

          var timeDiff = state.courseEditor.defaultMinutesToIdle * 60000; // milliseconds = 15 min * 60,0000
          //  var timeDiff = 0.1 * 60000
          var timeToIdle = now;
          timeToIdle = Date.parse(timeToIdle) - timeDiff;
          commit("setTimeToResetByIdle", timeToIdle);

          if (state.courseEditor.currentCourseEditedSaveTime != null) {
            //  console.log("Eval if user is idle.");
            if (
              Date.parse(state.courseEditor.currentCourseEditedSaveTime) <
              Date.parse(now) - timeDiff
            ) {
              // You have been idle for too long, Kick user from editing ;)
              commit("setShowCoursePartSettings", false);
              vm.$store.dispatch("closeCourseEdit").then(() => {
                //  console.log("Coffee break sent you to preview-mode.");
                // vm.$snackbar.show("Redigering stängdes pga inaktivitet.", {duration: -1})
                vm.$toast("Redigering stängdes pga inaktivitet.");
              });
            }
          }
        }

        resolve();
      });
    },
    loadCourse({ commit, state }) {
      return new Promise((resolve, reject) => {
        var fd = new FormData();
        fd.append("req", "get_course");

        let postData = {
          UserId: state.user.user_id,
          CourseId: state.courseEditor.currentCourseId
        };

        postData.RelatedUserType = 1;
        
        if (state.user.userPerm.editCourse == true) {
          // Only admins will get MentorMaterial
          postData.RelatedUserType = 2;
        }

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/course_data.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);
            //  console.log(response.data.data)

            if (response.status === 200) {
              // Is Locked?
              let courseData = response.data.data[0];

              // Is user surfing the course overview page?

              if(state.courseEditor.currCoursePostId == -1) {
              // setup who is editing this course (if anyone)
                if (courseData["LockedByUserId"] != null) {
                  let lockData = {
                    userId: courseData["LockedByUserId"],
                    username: courseData["LockedByUsername"],
                  };
                  commit("setCourseIsEditedBy", lockData);
                  // commit("setIsEditing", true);
                  /*
                  if(response.data.IsEditing==true) {
                    // console.log("Du är redaktör för denna post just NU!");
                      this.$store.dispatch("setCourseEditing").then(
                          (response) => {
                            // console.log("preview state is: " + this.$store.state.everything.courseEditor.previewState);
                          },
                          (error) => {
                            console.log(error.response);
                          }
                      );
                  }
                  */

                } else {
                  commit("setCourseIsEditedBy", { userId: null, username: null });
                  commit("setEditorPreviewState", true);
                  commit("setIsEditing", false);
                }
              
              }

              // SETUP ACTIVE MENU ITEM in PartCourseMenu (App-root)
              //  console.log("curr-post:" + state.courseEditor.currCoursePostId)

              var nId = state.courseEditor.currCoursePostId;
              //  console.log(courseData['CourseParts'])

              let foundNavPos = false;
              let totalPages = 1; // include the course overview as page 1
              let currPage = 1;

              if (nId == -1) {
                foundNavPos = true;
              }

              /*
              let a = courseData['CourseParts'].map(function(x) {return x.Id; }).indexOf(nId)

              if(a!=-1) {
                console.log("page is: " + a)
                courseData['CourseParts'][a].active = true
                foundNavPos = true
                currPage += a+1
              }
              */

              //  if(foundNavPos == false) {
              for (let cnt = 0; cnt < courseData["CourseParts"].length; cnt++) {
                totalPages++;
                //   console.log(totalPages)

                if (!foundNavPos) {
                  currPage++;
                }

                let blocksWithPost = courseData["CourseParts"][cnt][
                  "Blocks"
                ].filter((x) => x.PostType !== null);
                //   console.log("B with posts: " + blocksWithPost.length)
                totalPages += blocksWithPost.length;

                if (courseData["CourseParts"][cnt].Id == nId) {
                  //   console.log("page is: " + nId)
                  courseData["CourseParts"][cnt].active = true;
                  foundNavPos = true;
                }

                // let o = courseData['CourseParts'][cnt]["Blocks"].map(function(x) {return x.PostId; }).indexOf(nId)

                if (!foundNavPos) {
                  //  let o = courseData['CourseParts'][cnt]["Blocks"].map(function(x) {return x.PostId; }).indexOf(nId)
                  //  console.log(courseData['CourseParts'][cnt]["Blocks"])
                  let postCnt = 0;

                  for (let cnt2 = 0; cnt2 < blocksWithPost.length; cnt2++) {
                    postCnt++;
                    //    console.log("postcnt: " + postCnt)
                    if (blocksWithPost[cnt2].PostId == nId) {
                      courseData["CourseParts"][cnt].active = true; // PartCourse
                      blocksWithPost[cnt2].active = true; // Actual post
                      foundNavPos = true;
                      currPage += postCnt;
                    }
                  }

                  if (!foundNavPos) {
                    currPage += postCnt;
                  }

                  /*
                        let blocksWithPost = courseData['CourseParts'][cnt]["Blocks"].filter(x => x.PostType!==null)
                        let nrOfPosts = courseData['CourseParts'][cnt]["Blocks"].filter(x => x.PostType!==null).length

                        console.log("Blocks w posts:")
                        console.log(blocksWithPost)
                        console.log("Amount of posts: " + nrOfPosts)

                        if(o!=-1) {
                          //  console.log("post is:" + o)
                            courseData['CourseParts'][cnt].active = true   // PartCourse
                            courseData['CourseParts'][cnt]["Blocks"][o].active = true  // Actual post
                            foundNavPos = true
                            currPage += o+1
                          //  break
                        } else {
                          if(!foundNavPos) {
                            currPage += nrOfPosts
                          }
                        }
                        */
                }
                //   console.log("Currpage: " + currPage)
              }
              //  }

              commit("setTotalPages", totalPages);
              commit("setCurrentPage", currPage);

              /*    console.log("found nav? " + foundNavPos)
              console.log("Pages: " + totalPages)
              console.log("CurrPage: " + currPage)
              */

              commit("setCourseData", courseData);

              //  console.log(courseData)

              // Setup Page Navigation Forward / Backward.
              this.dispatch("setPageNavDirections")
                .then(() => {
                  //  console.log("Coffee break while setting up pageNavDirections...");
                })
                .catch((e) => {
                  console.log(e);
                  reject();
                });

              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    setPageNavDirections({ commit, state }) {
      let cp = state.courseEditor.courseData["CourseParts"];
      let prevId = false;
      let currIdSetCnt = false;
      let nextId = false;
      //  console.log("vm.id:" + vm.id)

      // vm.id bytes ut mot state.courseEditor.currCoursePostId (eftersom vi redan har detta variabel i STORE)
      if (state.courseEditor.currCoursePostId == -1) {
        // this.$store.commit("setCurrPostId", i_postId)
        // this.$router.push('/kurs/' + this.$store.state.courseEditor.currentCourseId + '/post/' + i_postId)
        if (state.courseEditor.courseData["CourseParts"].length != 0) {
          nextId = state.courseEditor.courseData["CourseParts"][0]["Id"];
        }

        prevId = false;
        // prevId =
      } else {
        //  console.log("in")
        // First find where we are atm.
        for (let cnt = 0; cnt < cp.length; cnt++) {
          let a = cp[cnt];
          /*  console.log("cnt:" + cnt)
            console.log("currIdSetCnt:" + currIdSetCnt)
            console.log("compare ids" + a["Id"] + " vs " + state.courseEditor.currCoursePostId) */

          if (a["Id"] == state.courseEditor.currCoursePostId) {
            currIdSetCnt = cnt;
            //  console.log("(a)Found currId at cnt:" + currIdSetCnt)
            if (cnt == 0) {
              //    console.log("First - prevId = " + state.courseEditor.currentCourseId)
              prevId = state.courseEditor.currentCourseId;
            }
          } else if (currIdSetCnt !== false) {
            //  console.log("(a)Set nextId:" + a["Id"])
            nextId = a["Id"];
            break;
          } else {
            if (prevId === false) {
              //     console.log("(a)PrevId set:" + a["Id"])
              prevId = a["Id"];
            }
          }

          if (currIdSetCnt === false) {
            //    console.log("(a)PrevId set:" + a["Id"])
            prevId = a["Id"];
          }

          //  console.log(cp[cnt])

          for (let cnt2 = 0; cnt2 < a["Blocks"].length; cnt2++) {
            let b = a["Blocks"][cnt2];
            // console.log(b)

            if (b["BlockType"] == "Post") {
              //console.log("Block is Post with PostID:" + b["PostId"])
              if (b["PostId"] == state.courseEditor.currCoursePostId) {
                currIdSetCnt = cnt2;
                //     console.log("(b)Found currId: " + b["PostId"] + " at cnt2:" + currIdSetCnt)
              } else if (currIdSetCnt !== false) {
                //      console.log("(b)Set nextId:" + b["PostId"])
                nextId = b["PostId"];
                break;
              }

              if (currIdSetCnt === false) {
                //       console.log("PrevId set:" + b["PostId"])
                prevId = b["PostId"];
                // console.log("prevId set to " + prevId)
              }
            }
          }

          if (prevId && nextId) break;
        }
      }

      /*  console.log("CourseId: " + state.courseEditor.currentCourseId)
      console.log("PostId: " + state.courseEditor.currCoursePostId)
      console.log("PrevId: " + prevId)
      console.log("NextId: " + nextId) */

      commit("setCourseNavBack", prevId);
      commit("setCourseNavForward", nextId);
    },
    activateUserExam({ commit, state }) {
      return new Promise((resolve, reject) => {
        // console.log("wohooohaaa!")
        var fd = new FormData();
        fd.append("req", "get_user_exam");

        let postData = {
          UserExamId: state.courseEditor.userExamId,
          UserId: state.user.user_id,
          Username: state.user.username,
          CourseId: state.courseEditor.currentCourseId,
        };

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/user_exam.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);
            //  console.log("User EXAM DATA ABOUT TO BE SET")
            //  console.log(response.data.data)

            if (response.status === 200) {
              // console.log("Done fetching userExam.")
              commit("setUserExamData", response.data.data);
              /*              
                if(Array.isArray(response.data.data)) {
                  if(response.data.data[0] !== undefined) { // ["ResultFromAutoCorrect"]
                    commit("setUserExamAutoResult", response.data.data[0]["ResultFromAutoCorrect"])
                  }
                }
                */

              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      }); // end of return promise
    },
    updateCoursePartData({ state }, vm) {
      return new Promise((resolve) => {
        // console.log("updatingCoursePart...")
        // console.log(vm.post)
        for (
          let cnt = 0;
          cnt < state.courseEditor.courseData["CourseParts"].length;
          cnt++
        ) {
          let a = state.courseEditor.courseData["CourseParts"][cnt];
          /* console.log(a["Id"])
         console.log(a)
         console.log("compare with post id: " + vm.post.Id) */
          if (a["Id"] == vm.post.Id) {
            state.courseEditor.courseData["CourseParts"][cnt]["Name"] =
              vm.post.Name;
            console.log("CoursePart name update: " + vm.post.Name);
            //  console.log("Blocks in store: ")
            //  console.log(state.courseEditor.courseData["CourseParts"][cnt]["Blocks"])

            //  console.log("New BlockData from vm: ")
            //  console.log(vm.blockData)
            for (
              let b = 0;
              b <
              state.courseEditor.courseData["CourseParts"][cnt]["Blocks"]
                .length;
              b++
            ) {
              let ba =
                state.courseEditor.courseData["CourseParts"][cnt]["Blocks"][b];
              ba["Title"] = vm.blockData[b]["title"];
              ba["PostName"] = vm.blockData[b]["title"];
              // console.log("New title is: " + vm.blockData[b]["title"])
            }
          } else {
            // Editing a post - post title to store data.
            for (
              let c = 0;
              c <
              state.courseEditor.courseData["CourseParts"][cnt]["Blocks"]
                .length;
              c++
            ) {
              let ca =
                state.courseEditor.courseData["CourseParts"][cnt]["Blocks"][c];
              if (ca["PostId"] == vm.post.Id) {
                ca["Title"] = vm.post["Name"];
                ca["PostName"] = vm.post["Name"];
              }
            }
          }
        }
        resolve();
      });
    },
    updateCourseData({ state }, vm) {
      return new Promise((resolve) => {
        state.courseEditor.courseData["Name"] = vm.courseData[0]["name"];
        state.courseEditor.courseData["Settings"] = JSON.parse(JSON.stringify(vm.courseData[0]["Settings"]));
        resolve();
      });
    },
    loadFile({ state }) {
      return new Promise((resolve, reject) => {
        var fd = new FormData();
        fd.append("req", "get_user_exam");

        let postData = {
          UserExamId: state.courseEditor.userExamId,
          UserId: state.user.user_id,
          Username: state.user.username,
          CourseId: state.courseEditor.currentCourseId,
        };

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/user_exam.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);
            //  console.log(response.data.data)

            if (response.status === 200) {
              console.log("Done fetching userExam.");
              //  commit("setUserExamData", response.data.data)

              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      }); // end of return promise
    },
    makeCourseImageBlockIfNotExists({ state }) {
      return new Promise((resolve, reject) => {
        var fd = new FormData();
        fd.append("req", "makeCourseImageBlockIfNotExists");

        let postData = {
          CourseId: state.courseEditor.currentCourseId,
        };

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/course_admin.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    getTasks({ commit, state }, i) {
      return new Promise((resolve, reject) => {
        var fd = new FormData();
        fd.append("req", "get_tasks");

        let postData = {
          UserId: state.user.user_id,
          Condition: null,
          isTrash:0
        };

        if(i.trash==1) {
          postData.isTrash = 1;
        }

        if(i.condition !== undefined) {
          postData.Condition = i.condition;
        }

        if(i.courseId !== undefined) {
          postData.CourseId = i.course_id;
        }

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/tasks.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              if (response.data) {
                // console.log(response.data.data)
                commit("setTaskList", response.data.data);
              }
              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    createTask({ commit, state }, o) {
      return new Promise((resolve, reject) => {
        // Find task and update it accordingly
        var fd = new FormData();
        fd.append("req", "create_task");

        //  console.log("Skapa task:")
        //  console.log(o)

        let postData = {
          Task: o.task,
        };

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/tasks.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              //    console.log(response)
              resolve(response.data.createTask.data["Id"]);
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    updateTask({ commit, state }, o) {
      return new Promise((resolve, reject) => {
        //  let task = o.task
        // let context = o.context

        // Find task and update it accordingly
        var fd = new FormData();
        fd.append("req", "update_task");

        let orgNr = state.user.userData.NationalRegistrationNumber;
        orgNr = orgNr.substring(0,6);

        let postData = {
          UserId: state.user.user_id,
          UserRealName: state.user.realname,
          NationalRegistrationNumber: orgNr,
          CourseName: o.courseName,
          Task: o.task,
        };

        if (o.task.SetCompleted == 1) {
          if (o.task["TaskType"] == 1) {
            postData.EventStartDate = o.eventStartDate;
          }
        }

        fd.append("data", JSON.stringify(postData));

        axios
          .post(window.serverUrl + "/api/backend/tasks.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              //  console.log(response)
              if (response.data) {
                //  console.log("After save task: ");
                //  console.log(response.data)

                /*
                let elementPos = null;
                //Insert new task in taskList
                if (o.task.SetCompleted == 1) {
                  if (o.task["TaskType"] == 1) {
                    commit("addTaskListItem", response.data.createTask.data);
                  }

                  // Find old task and update its completedDate etc
                  
                  elementPos = state.user.taskList
                    .map(function(x) {
                      return x.Id;
                    })
                    .indexOf(o.task["Id"]);
                 
                 
                  let a = {
                    Pos: elementPos,
                    CompletedDate: response.data.data["CompletedDate"],
                    CompletedByUserId: response.data.data["CompletedByUserId"],
                  };

                  commit("setTaskListAtPos", a);
                  
                } else if (o.task.SetCompleted == 0) {
                  elementPos = state.user.taskList
                    .map(function(x) {
                      return x.Id;
                    })
                    .indexOf(o.task["Id"]);

                  let a = {
                    Pos: elementPos,
                    CompletedDate: null,
                    CompletedByUserId: null,
                  };

                  commit("setTaskListAtPos", a);
                  
                }
                */
              }
              resolve();
            } else {
              console.log("Error loading data from backend.");
              resolve();
            }
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    gotoUserExamAsMentor({ commit, state }, i) {
      // Goto user_exam as mentor
      let targetPath = "/kurs/" + i.obj.CourseId;

      // console.log("set aprtcourse id to:" + i.obj.CoursePartId)
      if (i.obj.CoursePartId != null) {
        targetPath += "/post/" + i.obj.CoursePartId;
        commit("setCurrPostId", i.obj.CoursePartId);
      } else {
        commit("setCurrPostId", -1);
      }

    //  console.log(i.obj)

      var subObj = {
        Id: i.obj.SubmissionId,
        RelatedUserID: i.obj.UserId,
        FirstName: i.obj.FirstName,
        LastName: i.obj.LastName,
      };

      commit("setCurrentCourseId", i.obj.CourseId);

      commit("setMentorViewCourseAsUser", i.obj.UserId);
      commit("setValiFormSubmission", subObj);

      localStorage.mentorViewCourseAsUser = i.obj.UserId;
      localStorage.setItem("valiFormSubmission", JSON.stringify(subObj));

      // console.log(i.route)
      if (targetPath == i.route.path) {
        // console.log("Already here!")
        i.router.go();
      } else {
        i.router.push(targetPath);
      }
    },
    getMentors({ commit, state }) {
      return new Promise((resolve, reject) => {
        let dt = {
          CourseId: state.courseEditor.courseData["Id"],
          PostType: "course",
          UserId: state.user.userData.Id
        };

        var fd = new FormData();
        fd.append("req", "get_mentors");
        fd.append("data", JSON.stringify(dt));

        axios
          .post(window.serverUrl + "/api/backend/mentors.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              commit("setMentors", response.data.data);
              /*  console.log("Mentors set.")
                console.log(state.courseEditor.courseMentors) */
              resolve();
            }
            reject();
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    addFooterMentorCopy({ commit, state }) {
      return new Promise((resolve, reject) => {
        let dt = {
          CourseId: state.courseEditor.courseData["Id"],
          UserId: state.user.userData.Id
        };

        var fd = new FormData();
        fd.append("req", "addFooterMentorCopy");
        fd.append("data", JSON.stringify(dt));

        axios
          .post(window.serverUrl + "/api/backend/course_data.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              if(response.data.data!=='undefined') {  // New footer-row created
                resolve({reloadPage: true});
              }
              resolve();
            }
            reject();
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    setCourseEditing({commit, state}) {
      return new Promise((resolve, reject) => {
        console.log("Set post edit mode.");

        commit("setEditorPreviewState", false);
        commit("setIsEditing", true);
        commit("setCourseIsEditedBy", {
          userId: state.user.user_id,
          username: state.user.username,
        });
        resolve();
      });
    },
    logout({commit, state}, i) {

      var fd = new FormData();
      fd.append("req", "log_out");
      fd.append("data", JSON.stringify({ token: localStorage.getItem("user-token") }));

      axios
        .post(window.serverUrl + "/api/backend/login.php", fd)
        .then((response) => {
          JSON.stringify(response, null, 4);

          if (response.status === 200) {
            console.log("You logged out from the server.");
            commit('setPageEditActivation', false);
            commit("setServerDateTime", null);
            commit("setUserId", 0);
            commit("setNotices", []); 
            commit("setCorrespondance", null);
            
            localStorage.removeItem("user-token");
            delete axios.defaults.headers.common["Authorization"];
            var tmpTitle = localStorage.getItem("apptitle"); // preserve app title
            localStorage.clear();
            if (tmpTitle) {
              localStorage.setItem("apptitle", tmpTitle);
            }
            
            i.toast("Du är nu utloggad!");
            i.router.push('/Login');

          } else {
            console.log("Error loading data from backend.");
          }
        })
        .catch((e) => {
          console.log(e);
        });

      // $router.push({ path: '/login',  query: { logout: 'true' } });
    },
    insertTranslationContent({ commit, state }, i) {
      return new Promise((resolve, reject) => {
        commit("addTranslation", i);
        let index = state.translations.map(item => item.Key).indexOf(i.Key);
        resolve(index);
      });
    },
    setTranslationFromLocalStorage({commit, state}, iObj) {
      return new Promise((resolve, reject) => {
  //        let index = state.translations.map(item => item.Key).indexOf(i.Key);
          let data = [];
          let locText = localStorage.getItem("translations");
          let obj = null;
          let i = -1;

          if(locText) {
              data = JSON.parse(locText);
              obj = data.find(o => o.Key === iObj.Key);

              if(obj!=null) {
              //  console.log("obj found:", obj);
                i = state.translations.map(item => item.Key).indexOf(iObj.Key);
              //  console.log("setting from localStore to store index: ", i);
                
                var g = {};
                g.index = i;
                g.Content = obj.Content;
                g.CreationDate = obj.CreationDate;
                g.ModificationDate = obj.ModificationDate;

              //  console.log(g);

                commit("setTranslationAtIndex", g);
              //  console.log("Store after:", state.translations);
              //  console.log("ret true...");

                resolve(true);
            }
            resolve(false);
          }

          resolve(false);
      });
    },
    setTranslationFromServer({commit, state}, iObj) {
      return new Promise((resolve, reject) => {
          
        // LOCALSTORAGE

          let data = [];
          let locText = localStorage.getItem("translations");
          let obj = null;
          let i = -1;
          let locIndex = -1;

          if(locText) {
              data = JSON.parse(locText);
           //   obj = data.find(o => o.Key === iObj.Key);
              locIndex = data.map(item => item.Key).indexOf(iObj.Key);
          }

          if(locIndex === -1) {
              //console.log("Adding obj to localStorage!");
              data.push(iObj);
          } else {
              //console.log("Updating obj in localStorage!");
              data[locIndex] = iObj;
          }

          let setTrans = localStorage.setItem("translations", JSON.stringify(data));

          // STORE
          i = state.translations.map(item => item.Key).indexOf(iObj.Key);
        //  console.log("Setting translation data from server to store index: ", i);
          
          var g = {};
          g.index = i;
          g.Content = iObj.Content;
          g.CreationDate = iObj.CreationDate;
          g.ModificationDate = iObj.ModificationDate;

          commit("setTranslationAtIndex", g);
        //  console.log("Store after:", state.translations);
        //  console.log("ret true...");

          resolve(true);
      });
    },
    updateTranslationContent({ commit, state }, i) {
      let index = state.translations.map(item => item.Key).indexOf(i.Key);
    //  console.log("Updating translation-index: ", index);

      var g = {};
      g.index = index;
      g.Content = i.Content;
      g.CreationDate = i.CreationDate;
      g.ModificationDate = i.ModificationDate;

      commit("setTranslationContentAtIndex", g);
      
      let data = [];
      let locText = localStorage.getItem("translations");
      let obj = null;

      if(locText) {
        data = JSON.parse(locText);
      }

      var tObj = {};
      tObj.Content = i.Content;
      tObj.CreationDate = i.CreationDate;
      tObj.ModificationDate = i.ModificationDate;

      let locIndex = data.map(item => item.Key).indexOf(i.Key);

      if(locIndex === -1) {
          //console.log("Adding obj to localStorage!");
          data.push(tObj);
      } else {
          //console.log("Updating obj in localStorage!");
          data[locIndex] = tObj;
      }

      let setTrans = localStorage.setItem("translations", JSON.stringify(data));
      
    },
    courseProgressUpdate({ commit, state }, i) {
      return new Promise((resolve, reject) => {
        let dt = {
          CourseId: state.courseEditor.courseData["Id"],
          CoursePartId: state.courseEditor.currCoursePostId
        };

        var fd = new FormData();
        fd.append("req", "updateCourseProgressVideoPlay");
        fd.append("data", JSON.stringify(dt));

        axios
          .post(window.serverUrl + "/api/backend/course_progress.php", fd)
          .then((response) => {
            JSON.stringify(response, null, 4);

            if (response.status === 200) {
              if(response.data.addedProgress) {
                // update the progressbar
                //console.log("wohoo");
                let progress_percent = response.data.data[0].progress_percent;
              //  console.log(progress_percent);
                state.courseEditor.courseData.progress_percent = progress_percent;
              //  console.log(state.courseEditor.courseData);
              }
              resolve();
            }
            reject();
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    }

  
};
export default { namespaced: false, state, getters, mutations, actions };