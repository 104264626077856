<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-chip 
                v-on="on"
                class="float-left"
                :color="chipColor"
                outlined
            >
                <v-icon left small>{{icon}}</v-icon>
                {{compDate}}
            </v-chip>
        </template>
        <span>
            {{dateTooltip}}
        </span>
    </v-tooltip>
</template>
<script>
    export default {
    name: 'DateChip',
    components: {

    },
    props: {
        date: {
            type: String,
            required: false,
            default: ""
        },
        tooltip: {
            type: String,
            required: true,
            default: ""
        },
        type: {
            type: String,
            required: false,
            default: 'Basic'
        },
        compressDate: {
            type: Boolean,
            required: false,
            default: true
        },
        icon: {
            type: String,
            required: false,
            default: 'calendar_today'
        }
    },  
    data: () => ({
        
    }),
    created() {
        
    },
    mounted() {

    },
    computed: {
        chipColor() {
            if(this.type=='Basic') {
                return '#51A5FE';
            }
            else if(this.type=='Begins') {
                return 'green';
            }
            else if(this.type=='Ends') {
                return '#B388FF';
            }
            else {
                return 'green';
            }
        },
        compDate() {
            if(this.date) {
                if(this.compressDate) {
                    return this.date.substring(0,10);
                } else {
                    return this.date;
                }
            } else {
                return '...';
            }
        },
        dateTooltip() {
            if(this.date) {
                return this.tooltip;
            } else {
                return 'Tomt';
            }
        }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
