import { Node, mergeAttributes  } from '@tiptap/core'

//import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core'
import { find, registerCustomProtocol, reset } from 'linkifyjs'
//import { Plugin, PluginKey } from 'prosemirror-state'

export const CustomButton = Node.create({
  name: 'button',
  
  group: 'inline',
  inline: true,
  /*marks: '_',*/
  draggable: false,
  selectable: true,
  allowGapCursor: false,
  exitable: true,
  spanning: false,
  atom: true,
  isolating: true,
  content: 'inline*',
  renderHTML({ HTMLAttributes }) {
    return ['button', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  parseHTML() { return [{ tag: 'button' }] },
  addAttributes() {
    return { class: { default: 'ma-2 img-btn v-btn v-btn--has-bg theme--light v-size--x-large' }, style: { default: ''}, contenteditable: 'false' };
  },
  addOptions() {
    return {
      protocols: [],
      HTMLAttributes: {
        class: null,
        style: null
      },
      validate: undefined,
    }
  },

  priority: 1000,

  keepOnSplit: false,

  onCreate() {
      this.options.protocols.forEach(registerCustomProtocol);
   //   console.log(this);
   //   console.log(this.options);
  },

  onDestroy() {
      reset()
  },
})


