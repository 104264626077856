<template>
    <div>
        <!--  @click:outside="$emit('close', true)" -->
        <v-dialog v-model="mutatableValue" max-width="500" persistent>
                <v-form ref="form" >
            <v-card>
                <v-card-title>
                    <span class="headline">
                        {{ "Skapa länk-knapp" }}
                    </span>
            
                    <v-spacer />
            
                    <v-btn icon @click="$emit('close', true)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
        
                <v-card-text> 
                    <v-text-field label="URL" v-model="url" @input="updatePreview" required :rules="[(v) => !!v || 'This field is required']" />
                    <v-text-field label="Knappens text" v-model="text" @input="updatePreview" />
                    <IconPicker :value="icon" @apply="onSelectIcon($event)" />

                    <v-expansion-panels accordion flat tile>
                        <v-expansion-panel>
                            <v-expansion-panel-header ripple>
                                Knapp-layout
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    <v-btn-toggle
                                        v-model="urlTarget"
                                        borderless mandatory dense
                                        @change="updatePreview"
                                    >
                                        <v-btn value="_blank">
                                            <span>Extern länk</span>
                                            <v-icon right>
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>

                                        <v-btn value="_self">
                                            <span>Intern länk</span>
                                            <v-icon right>
                                                mdi-fit-to-page
                                            </v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </p>
                                <p>
                                    <v-btn-toggle
                                        v-model="btnRounded" 
                                        borderless mandatory dense
                                        @change="updatePreview"
                                    >
                                        <v-btn value="rounded">
                                            <span>Rundade hörn</span>
                                            <v-icon right>
                                                mdi-rounded-corner
                                            </v-icon>
                                        </v-btn>

                                        <v-btn value="square">
                                            <span>Kantiga hörn</span>
                                            <v-icon right>
                                                mdi-square-outline
                                            </v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </p>
                                <p>
                                    <v-btn-toggle
                                        v-model="btnSize" 
                                        borderless mandatory dense
                                        @change="updatePreview"
                                    >
                                        <v-btn value="v-size--x-small">
                                            <span>X-Liten</span>
                                        </v-btn>

                                        <v-btn value="v-size--small">
                                            <span>Liten</span>
                                        </v-btn>
                                        <v-btn value="v-size--default">
                                            <span>Medium</span>
                                        </v-btn>
                                        <v-btn value="v-size--large">
                                            <span>Stor</span>
                                        </v-btn>
                                        <v-btn value="v-size--x-large">
                                            <span>X-Stor</span>
                                        </v-btn>

                                        
                                    </v-btn-toggle>
                                </p>
                                <p>Bakgrundsfärg
                                    <v-menu :value="!bgColorTransparent" top nudge-bottom="96" nudge-right="230" :close-on-content-click="false">
                                        <template v-slot:activator="menu">
                                            <v-btn-toggle
                                                v-model="bgColorTransparent"
                                                borderless mandatory dense
                                                @change="updatePreview"
                                            >
                                                <v-btn :value="true">
                                                    <span>Transparent</span>
                                                    <v-icon right>
                                                        mdi-format-color-marker-cancel
                                                    </v-icon>
                                                </v-btn>

                                                <v-btn :value="false" v-on="menu.on">
                                                    <span>Färg</span>
                                                    <v-icon right :color='iconColor'>
                                                        mdi-format-color-fill
                                                    </v-icon>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="bgColor" flat @input="updatePreview" :swatches="swatches" show-swatches />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </p>
                                <p>Textfärg
                                    <v-menu :value="!textColorStandard" top nudge-bottom="96" nudge-right="230" :close-on-content-click="false">
                                        <template v-slot:activator="menu">
                                            <v-btn-toggle
                                                v-model="textColorStandard"
                                                borderless mandatory dense
                                                @change="updatePreview"
                                            >
                                                <v-btn :value="true">
                                                    <span>Standard</span>
                                                    <v-icon right>
                                                        mdi-format-color-marker-cancel
                                                    </v-icon>
                                                </v-btn>

                                                <v-btn :value="false" v-on="menu.on">
                                                    <span>Egen färg</span>
                                                    <v-icon right :color='textColor'>
                                                        mdi-format-color-fill
                                                    </v-icon>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="textColor" flat @input="updatePreview" :swatches="swatches" show-swatches />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <div v-html="sampleButton" />
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="(iconText==''&&text=='')||url==''" text @click="$emit('apply',{buttonResult: getButtonString()})">
                        {{ "Skapa knapp" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        </v-dialog> 
    </div>
  </template>
  
  <script>
  import IconPicker from './IconPicker'


  export default {
    name:'CustomButtonDialog',
    components: {
        IconPicker
    },
    props: {
        value: {
           type: Boolean,
           required:true,
           default: false
       },
    },
    data: () => ({
      showDialog: false,
      icon: '',
      url: '',
      urlTarget: '_blank',
      text: '',
      iconText: '',
      btnSize: 'v-size--x-large',
      bgColorTransparent: true,
      bgColor: '#ddddddff',
      textColorStandard: true,
      textColor: '#000000ff',
      btnRounded: 'square',
      mutatableValue: false,
      sampleButton: '',
      swatches: []
    }),
    mounted: function() {
        this.mutatableValue = this.value;
        //this.swatches = ["#ff00ffff"];
        var colors = Object.values(this.$vuetify.theme.themes.light);
        let chunkSize = 2;
        for (let i = 0; i < colors.length; i += chunkSize) {
            this.swatches.push(colors.slice(i, i + chunkSize));
        }
        // pair them in threes

        this.$nextTick(() => {
            if (this.$refs.form) {
                this.$refs.form.validate();
            }
        });
    },
    computed: {
        isDisabled() {
            return this.value === this.url || (!this.value && !this.url);
        },
        iconColor() {
            if (this.bgColorTransparent == false)
                return this.bgColor;
            return '';
        }
    },
    watch: {
        // if the set value is set after awhile (for example via a ajax requiest) then we need to re-set the mutableValue
        value(newV) {
            this.mutatableValue = newV;
        }
    },
    methods: {
        getButtonString() {
            var cont = "";
            if (this.text || this.iconText) {
                if (this.url) {
                    cont = "<a href='" + this.url + "' target='" + this.urlTarget + "' class='no-underline'>";
                    cont +="<button contenteditable='false' class='v-btn v-btn--has-bg theme--light " + this.btnSize + " transparent";
                    if (this.btnRounded == "rounded") {
                        cont += " v-btn--rounded";
                    }
                    var style = "";
                    if (this.bgColorTransparent == false) {
                        style += 'background-color:' + this.bgColor + '!important;';
                    }
                    if (this.textColorStandard == false) {
                        style += 'color:' + this.textColor + '!important;';
                    }
                    if (style != "") {
                        cont += "' style='" + style;
                    }
                    cont +="'>";
                    if (this.iconText) {
                        cont += "<bdi contenteditable='false' aria-hidden='true' class='v-icon notranslate mdi " + (this.text?'mr-4 ':'') + this.iconText + "'> </bdi>";
                    }
                    cont += this.text;
                    cont += "</button></a>";
                }
            }
            return cont;
        },
        updatePreview() {
            var cont = this.getButtonString();
            cont = cont.replace("<a href=", "<a onclick='return false;' href=");
            this.sampleButton = cont;
        },
        onSelectIcon(iSelectedIcon) {
            this.iconText = iSelectedIcon;
            //v-btn v-btn--has-bg v-btn--rounded theme--light v-size--large
            this.updatePreview();
        }
    }
  }
  </script>
<style>
  .iconPicker {
    text-rendering: optimizeSpeed;
  }
</style>
