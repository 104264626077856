<template>       
    <div class="content-area" v-if="$store.state.everything.isPageEditActivated">
        <my-tip-tap
            v-model="content"
            @input="txtFieldLostFocus"
            :placeholder="'Skriv in lite text för nyckeln:' + TheKey + '...'" 
            :toolbar="toolbar" />
    
    </div>
    <div class="content-area" v-else>
        <div v-if="hadData" v-html="storeContent"></div>
        <div ref="slotContainer">
            <slot v-if="!hadData" name="DefaultContent"></slot>
        </div>
    </div>
    </template>
    
    <script>
        import axios from 'axios'
        import ConfirmDialog from "../../components/dialogs/ConfirmDialog.vue";
        import MyTipTap from '../helpers/MyTipTap.vue';
    
     export default{
      name:'ContentArea',
      components: {
        ConfirmDialog,
        MyTipTap
      },
        props: {
            rounded: {
                type: Boolean,
                required: false,
                default: true
            },
            dense: {
                type: Boolean,
                required: false,
                default: false
            },
            outlined: {
                type: Boolean,
                required: false,
                default: false
            },
            disableToolbar: {
                type: Boolean,
                required: false,
                default: false
            },
            TheKey:{
                type: String,
                required: true,
                default: ''
            },
            replace:{
                type: Array,
                required: false,
                default: () => { return []; }
            },
            isZoned: {
                type: Boolean,
                required: false,
                default: false
            },
            toolbar: {
                type: Array,
                required: false,
                default: () => { return ['bold', 'italic', 'underline', 'highlight', 'color', '|', 'headings', 'bulletList', 'orderedList', 'checkbox', '|', 'button', 'link', 'image', 'video', 'emoji', 'icons', 'clear', '#undo', '#redo'];}
            }
        },
        data: () => ({
            dateWhenDataWasChangedOnInput: null,
            hadData: false,
            theEditor: null,
            hideToolbar: false,
            view: false,
            mention: false,
            errorMessages: null,
            isSaving: false,
            selectedLink: '',
            selectedIcon: '',
            unsubscribeFromStore: null,
            showContentAreaButton: false,
            lastSaveTime: null,
            timedSaveTrigger: null,
            content: "",
            lastContent: "",
            lastFetched: null,
            creationDate: null,
            modificationDate: null,
            localReplace: []
        }),
        computed: {
            storeContent() {
                var obj = this.$store.state.everything.translations.find(o => o.Key === this.TheKey);
            //    console.log(obj);
                this.content = obj?obj.Content:"";
                var returnVal = obj?obj.Content:"";
                // replace variables if necessary:
                this.localReplace.forEach((pair) => {
                    var keys = Object.keys(pair);
                    returnVal = returnVal.replace(keys[0], pair[keys[0]]);
                });
                return returnVal;
            }
        }, 
        mounted: function() {
            // Insert empty object for this key
            
            /*
            let a = {
                Key:this.TheKey,
                Content: "",
                Datetime: ""
            };

            this.$store.commit("addTranslation", a);

            let i = this.$store.state.everything.translations.map(item => item.Key).indexOf(this.TheKey);
            console.log("index we just create is: ", i);
            this.content = this.$store.state.everything.translations[i].Content;

            console.log("trans:", this.$store.state.everything.translations);

            */

            this.$nextTick(() => {
                this.evalLoadContent();
            });

            this.unsubscribeFromStore = this.$store.subscribe((mutation, state) => {
                if(mutation.type === 'setPageEditActivation' && mutation.payload == false){
                    // trigga sparning om content är förändrat
                    if (this.content != this.lastContent) {
                        if (this.timedSaveTrigger != null) {
                            clearTimeout(this.timedSaveTrigger);
                        }
                        this.dateWhenDataWasChangedOnInput = null;
                        // trigga funktionen direkt!
                        this.saveKeyData();
                    }
                }
            });
        },
        beforeDestroy() {
            this.unsubscribeFromStore();
            if (this.timedSaveTrigger != null) {
                clearTimeout(this.timedSaveTrigger);
            }
        },
        watch: { 
            TheKey: function(newVal, oldVal) {
                //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                if(newVal != oldVal) {
                    this.evalLoadContent();
                }
            },
            replace: function (val) {
                //console.log("replacing with: ", val);
                this.localReplace = val;
            }
        },
        methods: {
            evalLoadContent() {
                if (this.TheKey == "") {
                    this.$toast("No key specified!");
                    return false;
                }

                let a = {
                    Key:this.TheKey,
                    Content: "",
                    CreationDate: "",
                    ModificationDate: ""
                };

                this.$store.dispatch("insertTranslationContent", a).then((index) => {
                //   console.log("Translation created for the first time!");
                    this.content = this.$store.state.everything.translations[index].Content;
                    this.getLocalStorage();
                });
            },
            mentionItems: async function (query) {
                const response = await fetch(
                    `https://dummyjson.com/users/search?q=${query}`,
                    {}
                ).then(res => res.json());
                return response.users
                    .map(u => ({
                    value: u.id,
                    text: u.username,
                    avatar: u.image,
                    }))
                    .slice(0, 5);
            },
            txtFieldLostFocus(event) {
                this.dateWhenDataWasChangedOnInput = new Date();
                if (this.lastContent != this.content) {
                    if (this.lastSaveTime == null && this.timedSaveTrigger == null) {
                        this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                        return;
                    }
    
                    var curTime = new Date();
                    if ((this.lastSaveTime != null && curTime.getTime() - this.lastSaveTime.getTime() >= 5000) && this.timedSaveTrigger != null) {
                        if (this.timedSaveTrigger != null)
                            clearTimeout(this.timedSaveTrigger);
                        this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                    } else if (this.timedSaveTrigger == null) {
                        if (this.timedSaveTrigger != null)
                            clearTimeout(this.timedSaveTrigger);
                        this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                    }
                }
            },
            fetchKeyData() {
                this.timedSaveTrigger = null;
                var fd = new FormData();
                fd.append('data', JSON.stringify({
                    isZoned: this.isZoned,
                    TheKey: this.TheKey,
                    DefaultContent: this.$refs.slotContainer?this.$refs.slotContainer.innerHTML:'' // will be null if we edit code while having an content area up.
                })); 
    
                  axios.post(window.serverUrl + '/api/backend/translations/get_content.php', fd)
                  .then(response => {
                      JSON.stringify(response, null, 4)
                        if(response.status === 200) {
                            if (response.data.data.length == 0) {
                                this.hadData = false;
                                //console.log("No data input yet for this key:", this.TheKey);
                            } else {
                             //   console.log("fetch");
                               // this.content = response.data.data[0]["Content"];
                                this.hadData = true;
                               // this.lastContent = this.content;
                                this.creationDate = response.data.data[0]["CreationDate"];

                                if(response.data.data[0]["ModificationDate"]) {
                                    this.modificationDate = response.data.data[0]["ModificationDate"];
                                }
                                
                                this.setLocalStorage(response.data.data[0]);
                            }
                        } else {
                          console.log("Error loading content for Key: " + this.TheKey + " from backend.")
                        }
                  })
                  .catch(e => {
                    console.log(e);
                  })
            },
            saveKeyData() {
                if(this.$store.state.everything.user.userData.length === 0 || this.$store.state.everything.user.userData.Privilegies<70) {
                    return;
                }
                // console.log("Save from contentArea.", this.$store.state.everything.user.userData);

                var curTime = new Date();
                if (this.dateWhenDataWasChangedOnInput == null) {
    
                } else if (curTime.getTime() - this.dateWhenDataWasChangedOnInput.getTime() < 3000 || this.lastContent == this.content) {
                    // we are still inputting...
                    if (this.timedSaveTrigger != null) {
                        clearTimeout(this.timedSaveTrigger);
                    }
                    this.timedSaveTrigger = setTimeout(this.saveKeyData, 5000);
                    return;
                }
                this.lastSaveTime = new Date();
                this.isSaving = true;
                var fd = new FormData();
                fd.append('data', JSON.stringify({
                    isZoned: this.isZoned,
                    TheKey: this.TheKey,
                    content: this.content
                }));
                this.lastContent = this.content;
                this.$toast.clearQueue();
                this.$toast.save("Sparar ändringar...");
                axios.post(window.serverUrl + '/api/backend/translations/save_content.php', fd)
                  .then(response => {
                    this.isSaving = false;
                      //JSON.stringify(response, null, 4)
                        if(response.status === 200){
                            if (response.data.status != "OK") {
                                //console.log("Could not save data for key...:", this.TheKey);
                            } else {
                                this.modificationDate = response.data.datetime;
                               // this.replaceLocalStorage();
                                let obj = {};
                                obj.Key = this.TheKey;
                                obj.Content = this.content;
                                obj.CreationDate = this.modificationDate;   // just to fill creationdate with something
                                obj.ModificationDate = this.modificationDate;
                                this.setLocalStorage(obj);

                                this.$toast.clearQueue();
                                this.$toast.save("Ändringarna sparades till databasen.");
                                this.hadData = true;
                            }
                        } else {
                          console.log("Error saving content for Key: " + this.TheKey + " in backend.")
                        }
                  })
                  .catch(e => {
                    this.isSaving = false;
                    console.log(e);
                  })
            },
            getLocalStorage() {
                let a = {
                    Key:this.TheKey,
                };

                this.$store.dispatch("setTranslationFromLocalStorage", a).then((result) => {
                   // console.log("getLocalStorage - setTranslationFromLocalStorage RESULTAT: ", result);
                  
                   // NOTE: Fix så att inte användare får gammal data från localstorage. Eftersom inte modification-data jämförs så tog
                   // koden för givet att localstorage data skulle användas. 
                   // Sedan hämtas bara ny data efter 10 minuter.
                   // Resulterade i att om en användar gått in på sidan innan en content uppdatering, och besökte sedan sidan efteråt
                   // så fick de bara gammal data från localstorage... obra!

                   //  if(!result) {
                        this.fetchKeyData();
                  //  }

                    this.lastContent = this.storeContent;
                    this.hadData = true;
                });
            },
            setLocalStorage(obj) {
                this.$store.dispatch("setTranslationFromServer", obj).then((result) => {
                  //  console.log("setLocalStorage result: ", result);
                    this.lastContent = this.storeContent;
                    return result;
                });
            }
            
        }
    
    }
    </script>
    <style>
    .content-area img {
        max-width: 100%;
        height: auto;
    }
    </style>