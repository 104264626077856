
import Vue from "vue";
import Router from "vue-router";
import axios from 'axios'
import store from "./store";
import Default from "./views/dynamic/Default.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.vue"),
      props: true,
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/", // hyperlink
          text: "Logga in", // crumb text
        },
      },
    },
    {
      path: "/password-recovery",
      name: "Glömt Lösenord",
      component: () => import("./views/PasswordRecovery.vue"),
      props: true,
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/password-recovery", // hyperlink
          text: "Glömt lösenord", // crumb text
        },
      },
    },
    {
      path: "/admin/migrate-content",
      name: "Migrate content",
      component: () => import("./views/admin/MigrateContent.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/admin/migrate-content", // hyperlink
          text: "Migrate content", // crumb text
        },
      },
    },
    {
      path: "/password-recovery/:id",
      name: "Återställning av lösenord",
      component: () => import("./views/PasswordRecovery.vue"),
      props: true,
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/password-recovery", // hyperlink
          text: "Återställning av lösenord", // crumb text
        },
      },
    },
    {
      path: "/",
      name: "home",
      component: () =>
        import("./views/Home.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/", // hyperlink
          text: "Hem", // crumb text
        },
      },
    },
    {
      path: "/KursLista",
      name: "KursLista",
      component: () =>
        import("./views/ListCourses.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/KursLista", // hyperlink
          text: "Kurslista", // crumb text
        },
      },
    },
    {
      path: "/Yrkeslista",
      name: "Yrkeslista",
      component: () =>
        import("./views/ListTrades.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Yrkeslista", // hyperlink
          text: "Yrkeslista", // crumb text
        },
      },
    },
    {
      path: "/Yrkeslista/:id",
      name: "Yrkeslista/:id",
      component: () =>
        import("./views/ListTrades.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Yrkeslista", // hyperlink
          text: "Yrkeslista", // crumb text
        },
      },
    },
    {
      path: "/grupplista",
      name: "grupplista",
      component: () =>
        import("./views/GroupList.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/grupplista", // hyperlink
          text: "Grupplista", // crumb text
        },
      },
    },
    {
      path: "/grupp/:group_id",
      name: "grupp/:group_id",
      component: () =>
        import("./views/GroupEdit.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/grupp", // hyperlink
          text: "Grupp", // crumb text
        },
      },
    },
    {
      path: "/grupp/:group_id/kurs/:id",
      name: "grupp/:group_id/kurs/:id",
      component: () =>
        import("./views/EditCourse.vue"),
//        import("./views/EditGroupCourse.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Grupp/Kursöversikt", // hyperlink
          text: "Grupp/Kursöversikt", // crumb text
        },
      },
    },
    {
      path: "/grupp/:group_id/kurs/:id/post/:id2",
      name: "grupp/:group_id/kurs/:id/post/:id2",
      component: () =>
        import("./views/EditCoursePart.vue"),
//        import("./views/EditGroupCourse.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Grupp/Kurs/Delmål", // hyperlink
          text: "Grupp/Kurs/Delmål", // crumb text
        },
      },
    },
    {
      path: "/MyCourses",
      name: "Mina kurser",
      component: () =>
        import("./views/MyCourses.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/MyCourses", // hyperlink
          text: "Mina kurser", // crumb text
        },
      },
    },
    {
      path: "/Anvandare",
      name: "Användare",
      component: () =>
        import("./views/ListUsers.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Anvandare", // hyperlink
          text: "Användare", // crumb text
        },
      },
    },
    {
      path: "/Anvandare/:id",
      name: "Användare/",
      component: () =>
        import("./views/EditUser.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Anvandare", // hyperlink
          text: "Användare", // crumb text
        },
      },
    },
    {
      path: "/Akter",
      name: "Akter",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/ListFiles.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Akter", // hyperlink
          text: "Akter", // crumb text
        },
      },
    },
    {
      path: "/Akter/:id",
      name: "EditFile",
      component: () =>
        import("./views/EditFile.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Akter", // hyperlink
          text: "Akter", // crumb text
        },
      },
    },
    {
      path: "/MinProfil",
      name: "MinProfil",
      component: () =>
        import("./views/MyProfile.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/MinProfil", // hyperlink
          text: "Min Profil", // crumb text
        },
      },
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("./views/About.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/about", // hyperlink
          text: "About", // crumb text
        },
      },
    },
    {
      path: "/kurs/:id",
      name: "kurs",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/EditCourse.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        breadCrumbs: [ 
          {
            to: "/kurs", // hyperlink
            text: "Kurs", // crumb text
          },
          {
            text: "", // crumb text
          }
        ],
      },
    },
    {
      path: "/kurs/:id/post/:id2",
      name: "/delmoment",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/EditCoursePart.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/delmoment", // hyperlink
          text: "Delmoment", // crumb text
        },
      },
    },
    {
      path: "/Profiling",
      name: "Profiling",
      component: () =>
        import( "./views/Profiling.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Profiling", // hyperlink
          text: "Kartläggning", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step1",
      name: "Profiling Step 1",
      component: () =>
        import( "./views/profiling/Step1.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step1", // hyperlink
          text: "Kartläggning - Steg 1", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step1/:id",
      name: "Profiling Step 1 with id",
      component: () =>
        import( "./views/profiling/Step1.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step1", // hyperlink
          text: "Kartläggning - Steg 1", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step1_1",
      name: "Profiling Step 1.1",
      component: () =>
        import( "./views/profiling/Step1_1.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step1", // hyperlink
          text: "Kartläggning - Steg 1.1", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step1_1/:id",
      name: "Profiling Step 1.1 with id",
      component: () =>
        import( "./views/profiling/Step1_1.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step1_1", // hyperlink
          text: "Kartläggning - Steg 1.1", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step2",
      name: "Profiling Step 2",
      component: () =>
        import( "./views/profiling/Step2.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step2", // hyperlink
          text: "Kartläggning - Steg 2", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step2/:id",
      name: "Profiling Step 2 with id",
      component: () =>
        import( "./views/profiling/Step2.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step2", // hyperlink
          text: "Kartläggning - Steg 2", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step3",
      name: "Profiling Step 3",
      component: () =>
        import( "./views/profiling/Step3.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step3", // hyperlink
          text: "Kartläggning - Steg 3", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step3/:id",
      name: "Profiling Step 3 with id ",
      component: () =>
        import( "./views/profiling/Step3.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step3", // hyperlink
          text: "Kartläggning - Steg 3", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step3/:id/reference/:reference",
      name: "Profiling Step 3 with id and reference",
      component: () =>
        import( "./views/profiling/Step3.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step3", // hyperlink
          text: "Kartläggning - Steg 3", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step4/:id",
      name: "Profiling Step 4 with id ",
      component: () =>
        import( "./views/profiling/Step4.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step4", // hyperlink
          text: "Kartläggning - Steg 4", // crumb text
        },
      },
    },
    {
      path: "/profiling/Step5/:id",
      name: "Profiling Step 5 with id ",
      component: () =>
        import( "./views/profiling/Step5.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/profiling/Step5", // hyperlink
          text: "Kartläggning - Steg 5", // crumb text
        },
      },
    },
    {
      path: "/Profiling/Validation/:id",
      name: "Validation",
      component: () =>
        import( "./views/profiling/Validation.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Profiling/Validation", // hyperlink
          text: "Validering", // crumb text
        },
      },
    },
    {
      path: "/Todo",
      name: "To do",
      component: () =>
        import("./views/TaskList.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Todo", // hyperlink
          text: "Todo", // crumb text
        },
      },
    },
    {
      path: "/Todo/:id",
      name: "To do/",
      component: () =>
        import("./views/EditTask.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/Todo", // hyperlink
          text: "Todo", // crumb text
        },
      },
    },
    {
      path: "/Registrering",
      name: "Registrering",
      component: () =>
        import("./views/UserRegistration.vue"),
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/Registrering", // hyperlink
          text: "Registrering", // crumb text
        },
      },
    }, 
    {
      path: "/anvanda-webbplatsen",
      name: "Anvanda webbplatsen",
      component: () =>
        import("./views/om-webbplatsen/AnvandaWebbplatsen.vue"),
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/anvanda-webbplatsen", // hyperlink
          text: "Använda webbplatsen", // crumb text
        },
      },
    },
    {
      path: "/versioner",
      name: "Versionshistorik",
      component: () =>
        import("./views/om-webbplatsen/Versionshistorik.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/versioner", // hyperlink
          text: "Versionshistorik", // crumb text
        },
      },
    },
    {
      path: "/koder",
      name: "Koder",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/RegistrationCodes.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/koder", // hyperlink
          text: "Koder", // crumb text
        },
      },
    },
    {
      path: "/studietakt",
      name: "Studietakt",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/Studietakt.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/studietakt", // hyperlink
          text: "Studietakt", // crumb text
        },
      },
    },
    {
      path: "/statistik",
      name: "Statistik",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/Statistics.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/statistik", // hyperlink
          text: "Statistik", // crumb text
        },
      },
    },
 /*   {
      path: "/pp",
      name: "pp",
      component: () =>
        import("./views/PP.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/pp", 
          text: "pp", 
        },
      },
    },
    {
      path: "/sp",
      name: "sp",
      component: () =>
        import("./views/SP.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/sp", // hyperlink
          text: "sp", // crumb text
        },
      },
    },*/
    {
      path: "/404",
      name: "404",
      component: () => import("./views/NotFound.vue"),
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/404", // hyperlink
          text: "Not found", // crumb text
        },
      },
    },
   /* {
      path: "/dummy",
      name: "dummy",
      component: () =>
        import( "./views/NotFound.vue"),
      meta: {
        requiresAuth: true,
        breadCrumbs: {
          to: "/404", // hyperlink
          text: "Not found", // crumb text
        },
      },
    },*/

    /*
    {
      path: "*",
      component: () => import("./views/NotFound.vue"),
      meta: {
        requiresAuth: false,
        breadCrumbs: {
          to: "/404", // hyperlink
          text: "Not found", // crumb text
        },
      },
    },*/
  ],
  scrollBehavior() {
    //scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;

async function getBackendPageResult(router, to) {
  return new Promise((resolve, reject) => {
  console.log("getBackendPageResult running...");
    var fd = new FormData();
    fd.append('data', JSON.stringify({TheKey: to.path}));
    
    axios
        .post(window.serverUrl + "/api/backend/get_page_if_exists.php", fd)
        .then((response) => {
          console.log("get_page_if_exists result got!!");
          JSON.stringify(response, null, 4);

          if (response.status === 200) {
            if (response.data && response.data.length != 0) {
              var pageItem = response.data[0];
              var pageToAdd = 
              {
                path: pageItem.Key,
                name: pageItem.Title,
                component: Default,
                meta: {
                  keywords: pageItem.Keywords,
                  description: pageItem.Description,
                  header: pageItem.Header,
                  requiresAuth: false,
                  breadCrumbs: {
                    to: pageItem.Key, // hyperlink
                    text: pageItem.Title, // crumb text
                  },
                },
              }
              router.addRoutes([pageToAdd]);
              router.options.routes.push(pageToAdd);
              router.push(pageItem.Key);
              console.log("adding rout!");
              resolve();
            } else {
              console.log("not found in db!");
              resolve();
            }
            
          } else {
            console.log("Error loading data from backend.");
            resolve();
          }
        })
        .catch((e) => {
          console.log(e);
          resolve();
        });
      });
    }


router.beforeEach( async(to, from, next) => {
  //store.commit("setRouteDenied", null);
  //console.log(router);

  
  await Vue.nextTick();
  var existsInRouter = true;
  var resolveResult = router.resolve(to.path);
  //existsInRouter = router.options.routes.find((emp) => emp.path.toLowerCase() === to.path.toLowerCase());
  

  //console.log("exists: ", resolveResult);

  if(resolveResult.resolved.matched.length == 0) {
    //console.log("doesn't exist!", to.path);
    
    //console.log("checkExistsinDb");
    var hasJustAddedPageToRouter = await getBackendPageResult(router, to);
    // resolve again now that we maybe added a new route:
    resolveResult = router.resolve(to.path);
    if (resolveResult.resolved.matched.length == 0) {
      existsInRouter = false;
    }
  }

  if(store.state.everything.courseEditor.isEditing==true) {
    let courseId = from.params.id;    
    let coursePartId = from.params.id2?from.params.id2:-1;
  
    store.dispatch("closeCourseEdit", {CourseId: courseId, CoursePartId: coursePartId}).then(() => {
      console.log("Course unlocked for any user to claim.")
    });
  }
  
  //console.log("userid: ", store.state.everything.user);
  if (store.state.everything.user.user_id == 0 && localStorage.getItem("user-token")) {
    store.dispatch("setUser", to).then(
      () => {
        //console.log("Setta user!");
        //console.log("routeDenied is: " + store.state.error.routeDenied);
        if (store.state.everything.error.routeDenied == null) {
          if (store.state.everything.user.user_id == 0) {
            next("/login");
          } else {
            next();
          }
        } else {
          // Send user to login page
          //router.push('/login')
          //  next(false);
          if(from.path!='/login') {
            console.log("Fel token - skickar användaren till login-sidan!");
            var tmpTitle = localStorage.getItem("apptitle"); // preserve app title
            localStorage.clear();
            if (tmpTitle) {
              localStorage.setItem("apptitle", tmpTitle);
            }
            next("/login");
          } else {
            console.log("Redan på login sidan...");
            next();
          }
          
        }
      },
      (error) => {
        // eslint-disable-line no-unused-vars
        console.error(
          "Got nothing from server. Check internet connection and try again."
        );
        next();
      }
    );
  } else {

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (from.name == "kurs" || from.name == "/delmoment") {
        if (to.name != "kurs" && to.name != "/delmoment") {
          localStorage.removeItem("mentorViewCourseAsUser");
        }
      }
      // console.log("Need to set user?");
      if (!localStorage.getItem("user-token")) {
        // console.log("Not even logged in.")
        
        if (to == "/password-recorvery" || to == "/login") {
          // Pages that work without login
          next();
          //return false;
        } else {
          next("/login");
          //return false;
        }
      }
      
      store.dispatch("setUser", to).then(
        () => {
        //  console.log("Setting user!");
          //console.log("routeDenied is: " + store.state.error.routeDenied);
          if (store.state.everything.error.routeDenied == null) {
            if (store.state.everything.user.user_id == 0) {
              next("/login");
            } else {
              next();
            }
          } else {
            // Send user to login page
            //router.push('/login')
            //  next(false);
            if(from.path!='/login') {
              console.log("Fel token - skickar användaren till login-sidan!");
              var tmpTitle = localStorage.getItem("apptitle"); // preserve app title
              localStorage.clear();
              if (tmpTitle) {
                localStorage.setItem("apptitle", tmpTitle);
              }
              next("/login");
            } else {
              console.log("Redan på login sidan...");
              next();
            }
            
          }
        },
        (error) => {
          // eslint-disable-line no-unused-vars
          console.error(
            "Got nothing from server. Check internet connection and try again."
          );
          next();
        }
      );
    } else {
      next();
    }
  }

  if((store.state.everything.apptitle == "ValiWeb Yrkeslärare" || store.state.everything.apptitle == "ValiWeb Yrkeslärare Test") && to.path=="/") {
    next("/Profiling");
  }
  
  if (!existsInRouter) {
      next("/404");
  }
  next();
});
