import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core'
import { find, registerCustomProtocol, reset } from 'linkifyjs'
import { Plugin, PluginKey } from 'prosemirror-state'


export const CustomLink = Mark.create ({
    name: 'link',

    priority: 1000,

    keepOnSplit: false,

    onCreate() {
        this.options.protocols.forEach(registerCustomProtocol);
      //  console.log(this);
      //  console.log(this.options);
    },

    onDestroy() {
        reset()
    },
    inclusive() {
        return this.options.autolink
      },
  addOptions() {
    return {
      openOnClick: true,
      linkOnPaste: true,
      autolink: true,
      protocols: [],
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
        class: null,
      },
      validate: undefined,
    }
  },
  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
      class: {
        default: this.options.HTMLAttributes.class,
      },
    }
  },

  parseHTML() {
    return [
      { tag: 'a[href]:not([href *= "javascript:" i])' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },

  addCommands() {
    return {
      setLink: attributes => ({ chain }) => {
        return chain()
          .setMark(this.name, attributes)
          .setMeta('preventAutolink', true)
          .run()
      },

      toggleLink: attributes => ({ chain }) => {
        return chain()
          .toggleMark(this.name, attributes, { extendEmptyMarkRange: true })
          .setMeta('preventAutolink', true)
          .run()
      },

      unsetLink: () => ({ chain }) => {
        return chain()
          .unsetMark(this.name, { extendEmptyMarkRange: true })
          .setMeta('preventAutolink', true)
          .run()
      },
    }
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: text => find(text)
          .filter(link => {
            if (this.options.validate) {
              return this.options.validate(link.value)
            }

            return true
          })
          .filter(link => link.isLink)
          .map(link => ({
            text: link.value,
            index: link.start,
            data: link,
          })),
        type: this.type,
        getAttributes: match => ({
          href: match.data?.href,
        }),
      }),
    ]
  },

  addProseMirrorPlugins() {
    var LinkObj = this;
    return [
      new Plugin({
        key: new PluginKey('handleClickLink'),
        props: {
          handleClick(view, pos, event) {
            if (event.target.closest('a') == null) { // just go ahead and do whatever it normally does..
                return true;
            }
            if (event.ctrlKey) {
             //   console.log("CTRL KEY!");
                //event.preventDefault();
                //event.stopPropagation();
                var aElement = event.target.closest('a');
                
                window.open(aElement.getAttribute("href"), aElement.getAttribute("target")).focus();
                
                return true;
            }
            event.preventDefault();
            event.stopPropagation();
       //    console.log("yay", view, pos, event);
       //     console.log("closest a", event.target.closest('a'));
            
            

            /*
            console.log("view:", view);
            console.log("pos", pos);
            console.log("event:", event);
            const attrs = getMarkAttrs(view.state, schema.marks.link)
            console.log("on on click ,et's check:", attrs);
            
            //const attrs = getAttributes(view.state, options.type.name)
            const link = event.target.closest('a')

            if (link && attrs.href) {
                window.open(attrs.href, attrs.target)
                return true
            }*/

            return false
          },
          handleDoubleClick(view, pos, event) { /* … */ },
          handlePaste(view, event, slice) {
            const { state } = view
            const { selection } = state
            const { empty } = selection
    
            if (empty) {
              return false
            }
    
            let textContent = ''
    
            slice.content.forEach(node => {
              textContent += node.textContent
            })
    
            const link = find(textContent).find(item => item.isLink && item.value === textContent)
    
            if (!textContent || !link) {
              return false
            }
    
            options.editor.commands.setMark(options.type, {
              href: link.href,
            })
    
            return true

           },
          // … and many, many more.
          // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
        },
      }),
    ]
  },
})
