<template>
    <v-snackbar v-if="$store.state.groups.group_id!=null && $store.state.groups.course_id!=null"
        :timeout="-1"
        :value="true"
        bottom right
        max-width="100px"
      >
      <v-container style="max-width:300px;" start pa-0 ma-0>
      <!-- <v-card flat class="mb-0 px-6 pt-4" >
            <v-toolbar 
                color="secondary"
                flat
                class="add_border"
            > -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon depressed @click="$router.push('/Grupp/' + $store.state.groups.group_id)">
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Tillbaka till gruppen
                    </span>
                </v-tooltip>
                Kursgrupp
                Grupp-id: {{ $store.state.groups.group_id }}<br />
          <!--</v-toolbar> -->
                <br />
                <h2>Utdelningsschema (post: {{pageId}})</h2>
                <v-row pa-0>
                    <v-col cols="12">
                        <template>
                            Aktiv tid: <i>Beräknas enligt räknemodell (se pdf med kurspoäng mot viktning mot studietakt etc...).</i>
                            <br />
                        </template>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox 
                            v-model="item['postActivatesByCourseGoal']"
                            label="Aktiveras enligt viktningsfaktor."
                            :true-value="true"
                            :false-value="false"
                        ></v-checkbox>
                        <v-checkbox 
                            v-model="item['postActivatesByCourseGoal']"
                            label="Aktiveras enligt manuell tid."
                            :true-value="false"
                            :false-value="true"
                        ></v-checkbox>
                        <template v-if="item['postActivatesByCourseGoal']==false">
                            <template v-if="item.StartDate">
                                <date-chip :date="item['StartDate']" :tooltip="'Startar: ' + item['StartDate']" type="Begins" icon="mdi-clock-start"></date-chip>
                            </template>
                            <template v-if="item.EndDate">
                                <date-chip :date="item['EndDate']" :tooltip="'Slutar: ' + item['EndDate']" type="Ends" icon="mdi-clock-end"></date-chip>
                            </template>
                        </template>
                    </v-col>
                    <v-col cols="12">
                        <i>Här ställer man in hur/när den specifika sidan skall delas ut till gruppen. <br /><br /><strong>Default</strong> är att posten aktiveras enligt den viktningsfaktor som är inställd för delmålet.</i>
                    </v-col>
                    <v-col cols="12">
                        <template v-if="item.CDate">
                            <date-chip :date="item['CDate']" :tooltip="'Skapad: ' + item['CDate'] + ' av user-id:' + item['CreatedBy']" type="Basic" ></date-chip>
                        </template>
                        <template v-if="item.EDate">
                            <date-chip :date="item['EDate']" :tooltip="'Modifierad: ' + item['EDate'] + ' av user-id:' + item['ModifiedBy']" type="Modified" :icon="'update'"></date-chip>
                        </template>
                    </v-col>
                </v-row>
     <!--   </v-card> -->
        </v-container>
      </v-snackbar>
</template>

<script>

    import * as axios from 'axios';
    import DateChip from '../helpers/DateChip.vue';
    import ConfirmDialog from '../dialogs/ConfirmDialog.vue'

    export default {
            name: 'CourseGroupOverlay',
            components: {
                DateChip,
                ConfirmDialog,
            },
            props: {
                
            },  
            data: () => ({
                id: null,
                item: {
                    postActivatesByCourseGoal: true,
                    "StartDate": "2021-10-12 10:00:00",
                    "EndDate": "2021-10-12 10:00:00",
                    "CDate": "2021-10-12 10:00:00",
                    "EDate": "2021-10-12 10:00:00",
                    "CreatedBy": "8561",
                    "ModifiedBy": "8561",
                }
            }),
            computed: {
                pageId() {
                    if(this.$store.state.everything.courseEditor.currCoursePostId == -1) {
                        return this.$store.state.everything.courseEditor.currentCourseId;
                    } else {
                        return this.$store.state.everything.courseEditor.currCoursePostId;
                    }
                },
                invertedPostActivates: {
                    get: function () {
                        return !this.item['postActivatesByCourseGoal'];
                    },
                    set: function (newVal) {
                        console.log("set as you want")
                        this.item['postActivatesByCourseGoal'] = newVal
                    }
                }
            },
            created() {
                
            },
            mounted() {

            },
            methods: {
                postActivatesByCourseGoalsChanged() {

                }
            }
    }
</script>
