<template>
  <v-app v-bind:style="{ background: $vuetify.theme.themes.light.background }">
    <v-navigation-drawer
      ref="mainAppNav"
      v-model="drawer"
      app
      disable-route-watcher
      disable-resize-watcher
      :clipped="$vuetify.breakpoint.lgAndUp"
      floating
      @click="mainMenuClicked()"
    >
    <v-list dense class="main_nav">
        <div no-action v-for="(item, i) in mainNavigationList"
            :append-icon="mainMenuExpandIcon(item)" :key="i">
          <template v-if="itemAvailable(item)">
            <template v-if="item.subLinks">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for='sub in item.subLinks' :key="sub.name" @click="GoTo(sub.path, sub.params)" class="ml-5">
                    <v-list-item-icon>
                      <v-icon>{{ sub.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title  >{{ sub.name }}</v-list-item-title>
                  </v-list-item>
              </v-list-group>
            </template>
            <template v-else>
              <v-list-item
                v-if="itemAvailable(item)"
                @click="GoTo(item.path, item.params, item.subLinks)"
                active-class="highlighted"
                :class="item.path === $route.path ? 'highlighted' : ''"
                slot='activator'
                :value="item.Id"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          
            <template v-if="item.name=='Min profil'">
                <v-divider></v-divider>
            </template>
          </template>
        </div>
        <div class="ml-1 text-caption text--disabled" >
          <version-number></version-number>
        </div>
      </v-list>
      <!--
      <v-list dense class="main_nav">
        <v-list-group no-action v-for="(item, i) in mainNavigationList"
            :append-icon="mainMenuExpandIcon(item)" :key="i">

            <v-list-item
              v-if="itemAvailable(item)"
              @click="GoTo(item.path, item.params, item.subLinks)"
              active-class="highlighted"
              :class="item.path === $route.path ? 'highlighted' : ''"
              slot='activator'
              :value="item.Id"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for='sub in item.subLinks' :key="sub.name" @click="GoTo(sub.path, sub.params)">
                <v-list-item-icon>
                  <v-icon>{{ sub.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title  >{{ sub.name }}</v-list-item-title>
            </v-list-item>
          <template v-if="item.name=='Min profil'">
              <v-divider></v-divider>
          </template>
        </v-list-group>
        <div class="ml-1 text-caption text--disabled" >
          <version-number></version-number>
        </div>
      </v-list>
    -->
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="$store.state.everything.courseEditor.showCourseNav"
      app
      disable-route-watcher
      disable-resize-watcher
      :clipped="$vuetify.breakpoint.lgAndUp"
      :width="menuWidth"
      class="pt-6 pl-6"
      floating
    >
      <part-course-menu
        :key="$store.state.everything.courseEditor.reloadCourseMenu"
        v-if="$store.state.everything.courseEditor.courseData != null"
        class="mb-12"
        v-bind:courseMenuExpanded="courseMenuExpanded"
        :scrollMenu="scrollMenu"
      />
    </v-navigation-drawer>

    <v-app-bar
      color="white"
      app
      flat
      :height="isYrk ? '100' : ''"
      style="max-height:none;"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
    >
      <v-app-bar-nav-icon @click.stop="swapMenu('MainMenu')" ></v-app-bar-nav-icon>

      <template v-if="isSkea" >
          <span>
              <img 
                :src='ske_logo' 
                :alt="app_logo_alt"
                class="mt-2 ml-1"
                :style="'display:inline-block;width:' + app_logo_width + 'px;'"
              >
            </span>
      </template>
      <template v-else-if="isYrk && $vuetify.breakpoint.smAndUp" >
              <img 
                :src='yrk_logo' 
                :alt="app_logo_alt"
                class="ml-1 mr-2 yrk-site-bollar"
                :style="'display:inline-block;width:' + app_logo_width + 'px;'"
              >
            <div style="display:block" >
              <img 
                :src='valiweb_logo' 
                :alt="app_logo_alt"
                class="mt-2 ml-1 yrk-site-logo"
                :style="'display:inline-block;width:' + app_logo_width + 'px;'"
              >
              <div class="ml-2">
                <span class="text-h3 yrk-site-text">Validering sedan 2005</span>
              </div>
            </div>
      </template>

      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp" class="headline ml-1 notranslate" :style="isYrk ? 'min-width:500px;margin-top:41px;' : 'min-width:500px;'">
        <template v-if="(!isYrk && !isSkea)"> <!-- default -->
          <div style="float:left;">{{$store.state.everything.apptitle}}</div>
        </template>

        <template v-if="!isYrk">
          <div class="ml-4 float-left" :style="isYrk ? 'margin-top:6px;' : ''" v-text="PageName()" ></div>
        </template>
      </v-toolbar-title>
      

      <v-spacer></v-spacer>

      <template v-if="$store.state.everything.courseEditor.mentorViewCourseAsUser != null">
        <template
          v-if="canViewCourseAsMentor && isCoursePage(this.$route.path)"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip dark v-on="on" color="orange darken-2">
                {{
                  $store.state.everything.courseEditor.currentValiFormSubmission.FirstName
                }}
                {{
                  $store.state.everything.courseEditor.currentValiFormSubmission.LastName
                }}
              </v-chip>
            </template>
            <span
              >Granskar kurs för
              <strong
                >{{
                  $store.state.everything.courseEditor.currentValiFormSubmission.FirstName
                }}
                {{
                  $store.state.everything.courseEditor.currentValiFormSubmission.LastName
                }}</strong
              ><br />Id:{{ $store.state.everything.courseEditor.mentorViewCourseAsUser
              }}<br />Akt:
              {{ $store.state.everything.courseEditor.currentValiFormSubmission.Id }}</span
            >
          </v-tooltip>
        </template>
      </template>
      <!--
      <template bottom v-if="isCoursePage(this.$route.path)">
        <v-tooltip v-if="$store.state.everything.courseEditor.mentorViewCourseAsUser!=null">
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on" size="30" class="user_avatar_badge m_pointer" color="orange darken-2">
              <span class="white--text">{{$store.state.everything.courseEditor.currentValiFormSubmission.FirstName}}</span>
            </v-avatar>
          </template>
          <span>Granskar kurs för <strong>{{$store.state.everything.courseEditor.currentValiFormSubmission.FirstName}} {{$store.state.everything.courseEditor.currentValiFormSubmission.LastName}}</strong><br />Id:{{$store.state.everything.courseEditor.mentorViewCourseAsUser}}<br />Akt: {{$store.state.everything.courseEditor.currentValiFormSubmission.Id}}</span>
        </v-tooltip>
      </template>
      -->

      <template v-if="isYrk"> <!-- && $vuetify.breakpoint.smAndUp -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded @click="GoTo('/Profiling')">
              Hem
            </v-btn>
          </template>
          <span>Hem</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded @click="GoTo('/Kontakt')">
              Kontakt
            </v-btn>
          </template>
          <span>Kontakt</span>
        </v-tooltip>
        <v-tooltip bottom v-if="$store.state.everything.user.user_id != 0">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded @click="$store.dispatch('logout', {router:$router, route:$route, toast:$toast});">
              Logga ut
            </v-btn>
          </template>
          <span>Logga ut</span>
        </v-tooltip>
      </template>

      <template v-if="$store.state.everything.user.user_id != 0">
        <v-tooltip bottom v-if="($store.state.everything.user.userData['RelatedUserType'] == 2 || $store.state.everything.user.userData['RelatedUserType'] == 3 || $store.state.everything.user.userData['RelatedUserType'] == 4)">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="$store.commit('setPageEditActivation', !$store.state.everything.isPageEditActivated)">
              <v-icon :color="$store.state.everything.isPageEditActivated?'green':'grey'">edit</v-icon>
            </v-btn>
          </template>
          <span>{{ !$store.state.everything.isPageEditActivated?'Aktivera redigering':'Stäng redigering' }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!isYrk">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="GoTo('/')">
              <v-icon>help_outline</v-icon>
            </v-btn>
          </template>
          <span>Kom igång</span>
        </v-tooltip>

        <v-menu v-if="!isYrk" bottom offset-y fixed nudge-bottom="5" :nudge-width="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="markNoticesAsRead()">
              <v-badge
                :content="$store.state.everything.user.unreadNotices"
                :value="$store.state.everything.user.unreadNotices"
                color="green"
                overlap
              >
                <v-icon v-if="$store.state.everything.user.notices.length>0">notifications_active</v-icon>
                <v-icon v-else>notifications_none</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-list
            v-bind:style="noticeStyleObj"
            class="overflow-y-auto noticeCenter"
            dense
            subheader
          >
            <v-subheader>Meddelanden &amp; Aviseringar</v-subheader>
            <!-- style="max-height: 500px; min-width:350px" -->
            <template v-if="hasNotices">
              <v-list-item
                v-for="(item, index) in $store.state.everything.user.notices"
                :key="index"
                @click="fetchNotices()"
              >
                <v-list-item-content @click="openMessage(item)">
                  <v-list-item-title
                    v-if="item['FromUserId'] == '-1'"
                    class="mb-0 d-flex align-center"
                    ><v-icon small class="mr-2">warning</v-icon
                    >{{ item["Title"] }}</v-list-item-title
                  >
                  <v-list-item-title v-else class="mb-0 d-flex align-center"
                    ><v-icon small class="mr-2">comment</v-icon
                    >{{
                      item["SenderFirstName"] + " " + item["SenderLastName"]
                    }}</v-list-item-title
                  >
                  <span
                    class="tiptap-vuetify-editor__content mb-2 caption grey--text text--darken-1"
                    v-html="item['Message']"
                  />
                  <!-- <span class="grey--text body-2 mb-2 font-weight-light">{{item["CDate"]}}</span> -->
                  <span style="opacity: 0.5" class="caption">
                    {{
                      timeProgressed(
                        $store.state.notices.notifications.serverDateTime,
                        item.CDate
                      )
                    }}
                  </span>
                  <v-divider></v-divider>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mb-2 d-flex align-center"
                    ><v-icon small class="mr-2">comment</v-icon
                    >{{ noticeCopy }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-avatar
              v-on="on"
              @click="GoTo('/MinProfil')"
              :class="getAvatarClass"
            >
              <span class="white--text">{{ initialsOfUser }}</span>
            </v-avatar>
            <!-- <v-avatar @click='GoTo("/MinProfil")' v-on="on"> 
            <img
              :src="getAvatar()"
              :alt="getUsername()"
            >
          </v-avatar> -->
          </template>
          <span>Min profil</span>
        </v-tooltip>
      </template>

      <v-tooltip v-if="isLocalhost" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text rounded @click="togglePlatform()">
              Toggle
            </v-btn>
          </template>
          <span>Toggle</span>
      </v-tooltip>
    </v-app-bar>

    <!-- Mentor Surfing as a validator -->

    <!--
    <template v-if="isCoursePage(this.$route.path)">
      <v-snackbar
          :timeout="-1"
          :value="true"
          :app="true"
          :absolute="false"
          :z-index="4"
          top
          sticky
          color="deep-purple accent-4"
          elevation="24"
          v-if="$store.state.everything.courseEditor.mentorViewCourseAsUser!=null"
      >
          Granskar kurs för <strong>{{$store.state.everything.courseEditor.currentValiFormSubmission.FirstName}} {{$store.state.everything.courseEditor.currentValiFormSubmission.LastName}} id:{{$store.state.everything.courseEditor.mentorViewCourseAsUser}}</strong> - akt: {{$store.state.everything.courseEditor.currentValiFormSubmission.Id}}
      </v-snackbar>
    </template>
    -->

    <v-main>
      <!--   <div>
        <v-btn color="success">Success</v-btn>
        <v-btn color="error">Error</v-btn>
        <v-btn color="warning">Warning</v-btn>
        <v-btn color="info">Info</v-btn>
        <v-badge 
      </div> -->
      <router-view :key="$route.path"></router-view>

      <new-version></new-version>

    </v-main>
    <template v-if="isCoursePage(this.$route.path)">
      <course-group-overlay></course-group-overlay>
    </template>

    <v-bottom-navigation
      :value="bottomMenuActiveBtn"
      color="primary lighten-1"
      fixed
      v-if="isCoursePage(this.$route.path)"
    >
      <!-- class="hidden-md-and-up" -->
      <v-btn
        @click="gotoPrevCoursePage()"
        :disabled="canNavBack() ? false : ''"
      >
        <span>Tillbaka</span>
        <v-icon>navigate_before</v-icon>
      </v-btn>
      <v-btn @click.stop="swapMenu('CourseMenu')">
        <span>Kursmeny</span>
        <v-icon>menu</v-icon>
      </v-btn>
      <v-btn
        @click="gotoNextCoursePage()"
        :disabled="checkNavForward ? false : ''"
      >
        <span>Framåt</span>
        <v-icon>navigate_next</v-icon>
      </v-btn>
      <!--
        <v-btn>
          <span>Favorites</span>
          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn>
          <span>Nearby</span>
          <v-icon>mdi-map-marker</v-icon>
        </v-btn> -->
    </v-bottom-navigation>


    <v-bottom-navigation
      color="primary lighten-1"
      class="validation-menu"
      fixed
      v-if="isValidationPage(this.$route.path)"
    >
            <!-- <v-btn @click="$vuetify.goTo('#validation-home', options)">Hem</v-btn> -->
            <v-btn @click="$vuetify.goTo('#validation-home')">Översikt</v-btn>
            <v-btn @click="$vuetify.goTo('#validation-references')">Handläggning</v-btn>
            <v-btn @click="$vuetify.goTo('#validation-judgement')">Bedömning</v-btn>
            <v-btn @click="$vuetify.goTo('#validation-grades')">Betyg</v-btn>
            <v-btn @click="$vuetify.goTo('#validation-files')">Filer</v-btn>
            <!-- <v-btn @click="$vuetify.goTo('#validation-courses')">Kurser</v-btn> -->
            <v-btn @click="$vuetify.goTo('#validation-plan')">Valideringsplan</v-btn>
            <v-btn @click="$vuetify.goTo('#validation-comments')">Kommentarer</v-btn>
    </v-bottom-navigation>

    <!-- NoticeConsole -->
    <v-dialog
      v-if="$store.state.notices.notifications.showNoticeCenter"
      v-model="$store.state.notices.notifications.showNoticeCenter"
      hide-overlay
      max-width="450"
      transition="dialog-bottom-transition"
      z-index="9999"
      content-class="message-dialog"
    >
      <v-card class="message-card">
        <v-toolbar flat>
          <!-- dark color="primary" -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="$store.state.notices.notifications.showNoticeCenter = false"
                v-on="on"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span> Stäng </span>
          </v-tooltip>
          <v-toolbar-title
            >Dialog med
            {{ $store.state.notices.notifications.targetUserName }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn dark icon @click="alert('hej')" v-on="on"><v-icon>check</v-icon></v-btn>
                                </template>
                                <span>
                                    Bekräfta
                                </span>
                            </v-tooltip> -->
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mb-0 pb-0">
          <v-row no-gutters justify="center">
            <v-col
              cols="12"
              md="12"
              style="height: 350px; overflow: auto"
              id="message-container"
            >
              <v-divider></v-divider>

              <template
                v-if="$store.state.notices.notifications.correspondance != null"
              >
                <transition-group name="fadeInMe">
                  <template
                    v-for="(item, index) in $store.state.notices.notifications.correspondance"
                  >
                    <my-message
                      :message="item"
                      :async-mode="asyncMode"
                      :key="index"
                    />
                    <!-- 
                                        <other-message v-else 
                                        />
                                        -->
                  </template>
                </transition-group>
              </template>
              <template v-else>
                <div class="ma-4">Inga meddelanden. Påbörja en dialog!</div>
              </template>
            </v-col>
            <v-col cols="10" md="10">
              <v-list subheader class="ma-0 pa-0">
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-content class="ma-0 pa-0">
                    <!--   <v-list-item-title>Notering till användare</v-list-item-title> -->
                    <v-container class="message-card">
                      <v-row no-gutters align="center">
                        <v-col cols="12" class="mb-6">
                          <my-tip-tap
                            v-model="$store.state.notices.notifications.message"
                            placeholder="Skriv någonting …"
                            :toolbar="['bold', 'italic', 'highlight','underline', 'color', 'emoji', '#undo', '#redo']"
                            :color="'#440033'"
                          >
                          </my-tip-tap>
                        </v-col>
                        <!--
                          <v-col cols="7">
                              <strong>Skicka meddelandet som email?</strong>
                          </v-col>
                          <v-col cols="5">
                              <v-checkbox
                              v-model="$store.state.notices.notifications.sendEmail"
                              class="mx-6"
                              ></v-checkbox>
                          </v-col>
                        -->
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" icon @click="sendMessage()" v-on="on"
                    ><v-icon>send</v-icon></v-btn
                  >
                </template>
                <span> Skicka </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <!--
                    <v-card-actions>
                      <v-list-item class="grow">
                        <v-row align="center"
                            justify="end"
                        >
                          <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                  <v-btn color="primary" icon @click="alert('hej')" v-on="on"><v-icon>send</v-icon></v-btn>
                              </template>
                              <span>
                                  Bekräfta
                              </span>
                          </v-tooltip>
                        </v-row>
                      </v-list-item>
                    </v-card-actions>
                    -->
      </v-card>
    </v-dialog>

    <confirm-dialog v-if="$store.state.notices.notifications.sendMessageAsEmailConfirmDialog" :title="'Mottagaren är inte online!'" :type="'alert_email'" :message="'Vill du även <strong>skicka meddelandet som epost</strong> för att uppmärksamma mottagaren?<strong>'" :choiceOK="'Ja'" :choiceNO="'Nej'" v-on:confirm="confirmedToSendLastMessageAsEmail()" v-on:closeDialog='$store.commit("setSendMessageAsEmailConfirmDialog", false);' />

    <v-snackbar
      v-model="cookie_consent"
      timeout="-1"
      color="black"
      class="cookies pa-4"
      bottom
      dark
    >
      <div class="ma-4">
        På {{hostName}} använder vi kakor (cookies) för att webbplatsen ska
        fungera på bästa sätt. Genom att surfa vidare godkänner du att vi
        använder dem.
        <router-link :to="'anvanda-webbplatsen'" class="cookies">Vad är kakor?</router-link>
      </div>
      <template v-slot:action="{ attrs }">
        <div class="ma-4">
          <v-btn
            color="white"
            depressed
            rounded
            light
            v-bind="attrs"
            @click="cookieAccept()"
          >
            Jag förstår
          </v-btn>
        </div>
      </template>
    </v-snackbar>


    <v-dialog hide-overlay v-model="$store.state.everything.isSaving" persistent max-width="400px">
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.85)"
      >
        <v-layout justify-center align-center>
          <v-flex class="mt-2 mb-2 mr-4 title text--primary">
            Sparar
          </v-flex>
          <v-progress-linear
            indeterminate
            color="#00BCD4"
          ></v-progress-linear>
        </v-layout>
      </v-container>
    </v-dialog>

    <!--
    <v-dialog v-model="cookie_consent" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Use Google's location service?
        </v-card-title>

        <v-card-text>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    -->

    <v-menu
      v-model="$store.state.notices.notifications.showDialogMenu"
      :position-x="$store.state.notices.notifications.dialogMenuPos.x"
      :position-y="$store.state.notices.notifications.dialogMenuPos.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in dialogMenuItems"
          :key="index"
          @click="dialogMenuClick()"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <my-footer 
      v-if="isYrk"
      :logo_img="app_logo" 
      :logo_alt="app_logo_alt" 
      :logo_width="app_logo_width"
      :logo_img_2="balls_img_1"
      :logo_img_3="balls_img_2"
    >
    </my-footer>


  </v-app>
</template>

<script>
/*import HelloWorld from './components/HelloWorld' */
import axios from 'axios';
import PartCourseMenu from "./components/PartCourseMenu.vue";
import NewVersion from "./components/dialogs/NewVersion.vue";
import VersionNumber from "./components/helpers/VersionNumber.vue";
import CourseGroupOverlay from "./components/courseGroup/courseGroupOverlay.vue";
import ConfirmDialog from "./components/dialogs/ConfirmDialog.vue";
import BreadCrumb from "@/components/helpers/BreadCrumb.vue";
import MyMessage from "./components/notifications/MyMessage.vue";
import MyTipTap from './components/helpers/MyTipTap.vue';
import ContentArea from "./components/texts/ContentArea.vue";
import MyFooter from "./components/MyFooter.vue";

export default {
  name: "App",
  components: {
    "part-course-menu": PartCourseMenu,
    "my-message": MyMessage,
    NewVersion,
    VersionNumber,
    CourseGroupOverlay,
    ConfirmDialog,
    BreadCrumb,
    MyTipTap,
    ContentArea,
    MyFooter
  },
  computed: {
    isYrk() {
      return (this.$store.state.everything.apptitle == "ValiWeb Yrkeslärare" || this.$store.state.everything.apptitle == "ValiWeb Yrkeslärare Test");
    },
    isSkea() {
      return (this.$store.state.everything.apptitle == "ValiWeb Skellefteå");
    },
    isLocalhost() {
      return (this.$publicUrl == 'http://localhost:8080/');
    },
    mainNavListComputed() {
      const list = [];

      for(let a in this.mainNavigationList) {
        let o = this.mainNavigationList[a];
        let ok = false;

        if(o.icon == "login" && localStorage.getItem("user-token") === null) {
          ok = true;
        }

        if(localStorage.getItem("user-token") !== null) {
          if(o.userTypeAccess==null) {
            ok = true;
          } 
          else {
            if(o.userTypeAccess.includes(localStorage.userType)) {
              ok = true;
            }
          }
        }

        if(o.requireLogin==false) {
          ok = true;
        }
        
        if(ok) {
          list.push(o);
        }
      }
      
      return list;
    },
    initialsOfUser() {
      let n = this.$store.state.everything.user.realname;
      n = n
        .split(" ")
        .map((n) => n[0])
        .join(".");
      return n;
    },
    routeError() {
      return this.$store.state.everything.error.routeDenied;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    noticeStyleObj() {
      let o = {
        height: window.innerHeight - 200 + "px",
        "min-width": "350px" /* (window.innerWidth)- */,
      };
      return o;
    },
    menuWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "60%";
        case "sm":
          return "60%";
        case "md":
          return "300px";
        case "lg":
          return "500px";
        case "xl":
          return "300px";
      }
      return "300px";
    },
    checkNavForward: function () {
      if (this.$store.state.everything.courseEditor.navForward != false) return true;
      else return false;
    },
    noticeCopy: function () {
      if (this.$store.state.everything.user.notices === null) return "Hämtar notiser...";
      else return "Inga notiser...";
    },
    noticesInPageTitle: function () {
      let o = this.$store.state.everything.user.unreadNotices;

      if (o > 0) {
        return "(" + o + ") ";
      }
      return "";
    },
    isSurfingCourse() {
     // return this.$route.name === 'kurs'
      return this.$route;
      // return this.$route.name.indexOf('kurs') !== -1
    },
    getAvatarClass() {
      var tmp = "";
      switch(this.$store.state.everything.user.userData["RelatedUserType"]) {
        case 1:
          tmp = "success";
          break;
        case 2:
          tmp = "pink";
          break;
        case 3:
          tmp = "red";
          break;
        case 4:
          tmp = "orange";
          break;
        case 5:
          tmp = "teal";
          break;
        case 9:
          tmp = "purple";
          break;
        default:
          tmp = "success";
          break;
      }
      return tmp + " m_pointer";
    }
   /* appTitle: function() {
      if (window.location.hostname.indexOf("skelleftea.valiweb.nu") > -1) { 
        return "SKELLEFTEÅ";
      } else {
        return "ValiWeb VUX";
      }
    }*/
  },
  data() {
    return {
      title: "",
      hostName: "",
      drawer: false,
      courseNavDrawer: false,
      current_course_id: null,
      app_logo: "",
      app_logo_width: 200,
      app_logo_alt: "Logo",
      valiweb_logo: require('@/assets/images/valiweb_logo.svg'),
      ske_logo: require('@/assets/images/skelleftea_logo_svart.svg'),
      yrk_logo: require('@/assets/images/valiweb_yrk_headericons.svg'),
      balls_img_1: require('@/assets/images/footer_icons_01.svg'),
      balls_img_2: require('@/assets/images/footer_icons_02.svg'),
      listGrpModel: 1,
      mainNavigationList: [
        /* {name: 'Hem', path: '/', requireLogin: "false", userTypeAccess: null, icon: 'home'}, */
        {
          name: "Hem",
          path: "/",
          requireLogin: "false",
          userTypeAccess: null,
          icon: "home",
          mobile: true
        },
        {
          name: "Kom igång!",
          path: "/",
          requireLogin: "true",
          userTypeAccess: null,
          icon: "help",
        },
        {
          name: "Mina kurser",
          path: "/MyCourses",
          requireLogin: "true",
          userTypeAccess: null,
          icon: "school",
        },
        { 
          name: "Kartläggning", 
          path: "/Profiling", 
          requireLogin: "true", 
          userTypeAccess: null, 
          icon: "perm_contact_calendar"},
        {
          name: "Min profil",
          path: "/MinProfil",
          requireLogin: "true",
          userTypeAccess: null,
          icon: "person",
        },
        {
          name: "Akter",
          path: "/Akter",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4", "5", "8", "9", "10"],
          icon: "business_center",
        },
        {
          name: "Användare",
          path: "/Anvandare",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4"],
          icon: "people",
        },
        {
          name: "Yrkeslista",
          path: "/Yrkeslista",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4"],
          icon: "mdi-format-list-bulleted-square",
        },
        {
          name: "Kurslista",
          path: "/Kurslista",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4"],
          icon: "list",
        },
        {
          name: "Grupplista",
          path: "/Grupplista",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4"],
          icon: "mdi-account-group",
        },
        {
          name: "To do",
          path: "/Todo",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4"],
          icon: "all_inbox",
        },
        {
          name: "Superadmin",
          requireLogin: "true",
          userTypeAccess: ["2"],
          icon: "mdi-account-star",
          subLinks: [
                        {  name: "Studietakt",
                            path: "/studietakt",
                            requireLogin: "true",
                            userTypeAccess: ["2"],
                            icon: "mdi-timer-sand",
                        },
                        {  name: "Koder",
                            path: "/koder",
                            requireLogin: "true",
                            userTypeAccess: ["2"],
                            icon: "key",
                        },
                        {  name: "Statistik",
                            path: "/statistik",
                            requireLogin: "true",
                            userTypeAccess: ["2"],
                            icon: "mdi-chart-box",
                        }
          ]
        },
       /* {
          name: "Koder",
          path: "/koder",
          requireLogin: "true",
          userTypeAccess: ["2"],
          icon: "key",
        }, 
        /*  {name: 'Användarlista', path: '/Användarlista', requireLogin: "true", userTypeAccess: ["2", "3"], icon: 'list'},
        {name: 'Branscher', path: '/', requireLogin: "true", userTypeAccess: ["2", "3"], icon: 'work'}, */
        {
          name: "Om webbplatsen",
          path: "/anvanda-webbplatsen",
          requireLogin: "false",
          userTypeAccess: null,
          icon: "info",
        },
        {
          name: "Kontakt",
          path: "/Kontakt",
          requireLogin: "false",
          userTypeAccess: null,
          icon: "email",
          mobile: true
        },
        {
          name: "Logga in-sidan",
          path: "/login",
          requireLogin: "true",
          userTypeAccess: ["2", "3", "4"],
          icon: "login",
        },
        {
          name: "Logga ut",
          path: "/login",
          params: { logout: "true" },
          requireLogin: "true",
          userTypeAccess: null,
          icon: "exit_to_app",
        },
        {
          name: "Logga in",
          path: "/login",
          requireLogin: "false",
          userTypeAccess: null,
          icon: "login",
        },
      ],
      userData: null,
      bottomMenuActiveBtn: null,
      courseLoaded: true,
      courseMenuExpanded: false,
      scrollMenu: null,
      checkNotices: null,
      hasNotices: false,
      unreadNoticesCnt: 0,
      noticeStatus: "",
      dialogMenu: false,
      dialogMenuX: 0,
      dialogMenuY: 0,
      dialogMenuItems: [
        { title: "Akt" },
        { title: "Användare" },
        { title: "Prövning" },
      ],
      canViewCourseAsMentor: false,
      asyncMode: true,
      cookie_consent: true,
      checkNewVersionOnContent:null
    };
  },
  mounted() {
    this.title = document.title;

    this.setThemeParams();

    /*
    let o = [];
    let locText = localStorage.getItem("translations");

    if(locText) {
        o = JSON.parse(locText);
    }

    this.$store.commit("setTranslations", o);
    */

    if (localStorage.mentorViewCourseAsUser !== undefined) {
      //  console.log("Time to set view as mentor.")
      this.$store.commit("setMentorViewCourseAsUser", localStorage.mentorViewCourseAsUser);
      this.$store.commit("setValiFormSubmission", JSON.parse(localStorage.getItem("valiFormSubmission")));
    }

    this.checkNotices = setInterval(() => {
      // Fetch notifications (bell)
      if (this.$store.state.everything.user.user_id != 0) {
        if (this.$store.state.notices.notifications.active == true) {
          this.fetchNotices();
        }
      }

      // Fetch chat messages
      if (this.$store.state.notices.notifications.showNoticeCenter) {
        this.$store
          .dispatch("getCorrespondance", this.$store.state.notices.notifications.targetUserId)
          .then(
            (a) => {
              if (a.updateScroll == true) {
                var e = document.getElementById("message-container");
                e.scrollTop = e.scrollHeight;
                this.playSnd("textIn");
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }, 3000);

    if(this.isYrk) {
      this.$vuetify.theme.themes.light.primary = '#294A5C';
      this.$vuetify.theme.themes.light.error = '#FF8F09';
    }

    ////// Notices

    let oldNotices = localStorage.getItem("Notices");

    if(oldNotices) {
      // console.log("Wow, has notices in localStorage!");
  
      let o = JSON.parse(oldNotices);
    //  console.log(o);
      // console.log(o[0]["CDate"]);
      this.$store.commit("setNotices", o);
      if (o.length == 0) {
        //console.log("No notices!, kommer smälla om man inte lagt in denna if/else på rad 1060 i App.vue");
      } else {
        this.$store.commit("setServerDateTime", o[0]["CDate"]);
      }

      this.hasNotices = true;

      // CHeck if user has unread messages
      // Count unread messages
      let u = 0;
  
      for (let cnt = 0; cnt < o.length; cnt++) {
        //  console.log(this.state.everything.user.notices[cnt]["ShowedDate"]);
        if (o[cnt]["ShowedDate"] == undefined) {
          //   console.log("Notice not showed:" + this.state.everything.user.notices[cnt]["Id"])
          u++;
        }
      }

    //  console.log(u);
      this.$store.commit("setUnreadNotices", u);

      if(u>0) {
        this.playSnd("textIn");
      }
    }

    this.checkNewVersionOnContent = setInterval(() => {
      this.checkForUpdatedContent();
    }, 10 * 60 * 1000);

    // Om webbplatsen, kakor &amp; teknisk information
      /////////////

    //  window.addEventListener("keypress", this.checkKeyPress)
  },
  watch: {
    "$store.state.everything.courseEditor.showCourseNav": function () {
      // console.log("StoreVAR för showCouseNav was updated: " + this.$store.state.courseEditor.showCourseNav)
      localStorage.showCourseNav = this.$store.state.everything.courseEditor.showCourseNav;
    },
    routeError() {
      if (this.$store.state.everything.error.routeDenied != null) {
        this.$toast.warning(this.$store.state.everything.error.routeDenied);
      }
    },
    $route(to, from) {
      // console.log("Nav happened!")

      if (this.isCoursePage(to.path)) {
        let self = this;
        if (!localStorage.showCourseNav) {
          // || localStorage.showCourseNav == -1
          // console.log("nav drawer not set.")
          this.drawer = false;

          if (this.$vuetify.breakpoint.mdAndUp) {
            setTimeout(function () {
              self.$store.commit("setShowCourseNav", true);
            }, 80);
          }
        } else {
          //  console.log("course nav on navigate: " + localStorage.showCourseNav)
          // If we did NOT come from a course-page showCourseNav = true
          // console.log(from.path)
          if (this.isCoursePage(from.path) === false) {
            //   console.log("From = not course.")
            if (window.innerWidth > 568) {
              this.drawer = false;
              // this.$store.commit("setShowCourseNav", true)
              setTimeout(function () {
                self.$store.commit("setShowCourseNav", true);
              }, 80);
            }
          }
        }
      } else {
        //  console.log("No course page.")
        //
        // NAVIGATING AWAY FROM A COURSE . HIDE STUFF

        this.$store.commit("setShowCourseNav", false);
        this.$store.commit("setGroupCourseId", null);
      }

      //  console.log("NavDrawer at end of route to:" + this.courseNavDrawer)
    },
  },
  beforeDestroy() {
    // clearInterval(this.evalIdleUser)
    // window.removeEventListener("keypress", this.checkKeyPress)
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.checkNotices);
    clearInterval(this.checkNewVersionOnContent);
  },
  created() {
    localStorage.preview = 0;

    this.hostName = window.location.hostname;

    //if (!this.auth && this.$route.path !== '/login') {

    this.id = Number.parseInt(this.$route.params.id2, 10);

    if (localStorage.getItem("cookie_consent") === null) {
      this.cookie_consent = true;
    } else {
      this.cookie_consent = false;
    }

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 88) {
        this.canViewCourseAsMentor = true;
      } else if (window.scrollY < 89) {
        this.canViewCourseAsMentor = false;
      }
      // console.log(this.$store.state.everything.courseEditor.mentorViewCourseAsUser)
      //console.log("scrollY:" + window.scrollY)
    },
    mainMenuClicked() {
      console.log("mm clicked!");
      return true;
    },
    dialogMenuClick() {
      console.log("clicked.");
    },
    itemAvailable(item) {
      if(this.isYrk) {
        if(item.name=="Mina kurser" || item.name=="Kom igång!") {
          return false;
        }
        if(item.mobile && this.$vuetify.breakpoint.smAndUp) {
          return false;
        }
      } else {
        if(item.name=="Hem"|| item.name=="Kontakt") {
          return false;
        }
      }

      if(item.name == "Kartläggning" && (this.$store.state.everything.user.userData["RelatedUserType"] == 1 && this.$store.state.everything.user.userData["RelatedPriceRegCode"] == "RELOCATE") ) {
        return false;
      }
      /*
      if(item.name == "Grupplista" && (this.$store.state.everything.user.userData["Id"] != 8561 && this.$store.state.everything.user.userData["Id"] != 2) ) {
        return false;
      }
      */

      if (
        item.name == "Logga in" &&
        localStorage.getItem("user-token") !== null
      ) {
        return false;
      }

      if (item.requireLogin == "true") {
        if (localStorage.getItem("user-token")) {
          if (item.userTypeAccess != null) {
            // evaluate user access
            //  console.log(item.userTypeAccess[1])
            //  console.log("UserType: " + localStorage.userType)
            if (item.userTypeAccess.includes(localStorage.userType)) {
              //  console.log("You are superior!")
              return true;
            } else {
              //  console.log("Not superior!")
              return false;
            }
          }
          return true;
        }

        return false; // user is not logged in!
      } else {
        return true;
      }
    },
    GoTo(i_path, i_params, i_sub) {
      console.log("path:", i_path);
      console.log("params:", i_params);
      
      if(typeof i_sub !== 'undefined') return;
      //  console.log("Curr Path: " + this.$route.path)
      //  console.log("Target Path: " + i_path)
      //console.log(i_params)

      // console.log("Path:" + this.$route.path + " --> " + i_path)
      // Check if we need to unlock any course

      if (i_path=="/Profiling" && localStorage.getItem("user-token") === null) {
        
        if(this.$route.path == "/login") {
          this.$toast("Var god logga in!");
          return;
        } else {
            // send to login page instead
            if (localStorage.getItem("user-token") === null) {
              this.$router.push("/login");
              return;
            }
        }
      }

      if (
        this.$store.state.everything.courseEditor.currentCourseEditedById ==
        this.$store.state.everything.user.user_id
      ) {
        this.$store.dispatch("closeCourseEdit").then(() => {
          //  console.log("Any course unlocked safely.")
        });
      }

      if (this.$route.path != i_path) {
        if (i_params != null) {
          this.$router.push({ path: i_path, query: { logout: "true" } });
        } else {
          this.$router.push(i_path);
        }
      } else {

        if (i_params != null) {
          if(i_path == "/login" && i_params.logout=="true") {
              this.$store.dispatch('logout', {router:this.$router, route:this.$route, toast:this.$toast});
              return;
          }
        }
        
        console.log("You are already at location: " + this.$route.path);
      }
    },
    PageName() {
      let pageName = "";

      if (this.$route.meta.breadCrumbs != null) {
        if(this.$route.meta.breadCrumbs.length>0) {
          pageName = " / " + this.$route.meta.breadCrumbs[0].text;
        } else {
          pageName = " / " + this.$route.meta.breadCrumbs.text;
        }
        // if(this.$route.path)
      } else {
        pageName = " / ";
      }

      return pageName;
    },
    showUserName() {
      if (localStorage.getItem("user-token") === null) {
        return "";
      } else {
        var myObj = JSON.parse(localStorage.getItem("user-token"));
      }
      return "user: " + myObj.Username;
    },
    getAvatar() {
      //https://cdn.vuetifyjs.com/images/john.jpg
      if (localStorage.avatar != "") {
        return this.$publicUrl + localStorage.avatar;
      } else {
        return this.$publicUrl + "/img/avatar_temp_square.png";
      }
    },
    getUsername() {
      if (localStorage.username === null) {
        return "";
      } else {
        return localStorage.username;
      }
    },
    gotoPrevCoursePage() {
      this.$store.commit("setCurrPostId", this.$store.state.everything.courseEditor.navBack);
      var targetRoute = '';

      // surfing via a coursegroup
        if(this.$store.state.groups.group_id!=null) {
          targetRoute = "/grupp/" + this.$store.state.groups.group_id;
        }

      if (this.$store.state.everything.courseEditor.navBack == this.$store.state.everything.courseEditor.currentCourseId) {
        // default course navigation
        targetRoute += "/kurs/" + this.$store.state.everything.courseEditor.navBack;

        this.$router
          .push(targetRoute)
          .catch((err) => {
            // eslint-disable-line no-unused-vars
            //  console.log(err)
          });
      } else {
        targetRoute += "/kurs/" +
              this.$store.state.everything.courseEditor.currentCourseId +
              "/post/" +
              this.$store.state.everything.courseEditor.navBack

        this.$router
          .push(targetRoute)
          .catch((err) => {
            // eslint-disable-line no-unused-vars
            //  console.log(err)
          });
      }

      //  this.$store.state.everything.courseEditor.reloadCourseMenu = Math.random()
    },
    gotoNextCoursePage() {
      if (this.$store.state.everything.courseEditor.navForward != null) {
        var targetRoute = '';

        // surfing via a coursegroup
        if(this.$store.state.groups.group_id!=null) {
          targetRoute = "/grupp/" + this.$store.state.groups.group_id;
        }

        // default course navigation

        targetRoute += "/kurs/" +
            this.$store.state.everything.courseEditor.currentCourseId +
            "/post/" +
            this.$store.state.everything.courseEditor.navForward;

        // console.log(this.$store.state.everything.courseEditor.currCoursePostId);

        this.$store.commit(
          "setCurrPostId",
          this.$store.state.everything.courseEditor.navForward
        );

        console.log(targetRoute);

        this.$router.push(targetRoute).catch((err) => {
            // eslint-disable-line no-unused-vars
            //  console.log(err)
        });

      } else {
        console.log("End of course...");
      }

      //  this.$store.state.everything.courseEditor.reloadCourseMenu = Math.random()
    },
    isValidationPage(t) {
      if (t.indexOf("/profiling/Validation/") !== -1) {
        return true;
      } else {
        return false;
      }
    },
    isCoursePage(target) {
      //  console.log("course:" + this.$store.state.everything.courseEditor.currentCourseId)
      //  console.log(this.$store.state.everything.courseEditor.courseData)

      if (target.indexOf("/kurs") !== -1) {
        return true;
      } else {
        return false;
      }
      /*
     if(this.$store.state.everything.courseEditor.courseData!=null) {
        if(target.indexOf('/kurs') !== -1) {
          return true 
        } else {
          return false
        }
     }

     return false
     */
    },
    canNavBack() {
      //  console.log("Can nav back:" + this.$store.state.everything.courseEditor.navBack)
      if (this.$store.state.everything.courseEditor.navBack != false) return true;
      else return false;
    },
    canNavForward() {
      //  console.log("Can nav back:" + this.$store.state.everything.courseEditor.navBack)
      if (this.$store.state.everything.courseEditor.navForward != false) return true;
      else return false;
    },
    swapMenu(m) {
      //  console.log("Swapping menu!")
      //  console.log(m)
      // console.log("this.drawer before:" + this.drawer)
      if (m == "MainMenu") {
        if (
          this.drawer == false &&
          this.$store.state.everything.courseEditor.showCourseNav == true
        ) {
          //  console.log("drawer was closed!")
          this.$store.commit("setShowCourseNav", false);
          let self = this;
          setTimeout(function () {
            self.drawer = !self.drawer;
          }, 50);
        } else {
          this.courseNavDrawer = false;
          this.drawer = !this.drawer;
        }
      } else {
        // console.log("before swap courseNavDrawer: " + this.$store.state.everything.courseEditor.showCourseNav)
        if (this.$store.state.everything.courseEditor.showCourseNav == false) {
          //  console.log("setting courseNave to true!")
          var self = this;
          setTimeout(function () {
            self.$store.commit("setShowCourseNav", true);
          }, 50);
          localStorage.showCourseNav = "true";
        } else {
          // console.log("setting to false!")
          this.$store.commit("setShowCourseNav", false);
          localStorage.showCourseNav = "false";
        }
        this.drawer = false;
        // console.log("courseNavDrawer is: " + this.$store.state.everything.courseEditor.showCourseNav)
      }
      // console.log("this.drawer after:" + this.drawer)
    },
    fetchNotices() {
      var oldUnreadNotices = JSON.parse(
        JSON.stringify(this.$store.state.everything.user.unreadNotices)
      );
      //  console.log("Old notices:" + oldUnreadNotices)

      this.$store.dispatch("checkNotices", this).then(() => {
        if (this.hasNotices === false) {
          //    console.log("Notices is:")
          //console.log(this.$store.state.everything.user.notices)
          if (this.$store.state.everything.user.unreadNotices > 0) {
            // console.log("New notices:" + this.$store.state.everything.user.unreadNotices)
          }

          if (this.$store.state.everything.user.notices.length > 0) {
            this.hasNotices = true;
            // this.$snackbar.show(this.$store.state.everything.user.notices[0]["Message"], {duration: -1})
          } else {
            this.hasNotices = false;
          }
        }

        this.updateTitleNotices();

        if (this.$store.state.everything.user.unreadNotices > oldUnreadNotices) {
          //  console.log("playing a sound!")
          this.playSnd("textIn");
        }
        //
      },
      (error) => {
        console.log(error);
        this.$toast.warning("Du är inte auktoriserad. Var god logga in. (0x06)");
        this.$router.push({ path: '/login',  query: { logout: 'true' } });
      });
    },
    updateTitleNotices() {
      if (this.$store.state.everything.user.unreadNotices > 0) {
        let newTitle = this.noticesInPageTitle + this.title;
        document.title = newTitle;
      }
    },
    markNoticesAsRead() {
      document.title = this.title;
      // console.log("Mark as read!")
      this.$store.dispatch("markReadNotices", this).then(() => {
        /* if(this.hasNotices == true) {
        
         }
      */
      });
    },
    sendMessage() {
    //  console.log("Meddelande:");
    //  console.log(this.$store.state.notices.notifications.message);
      if(this.$store.state.notices.notifications.message=="" || this.$store.state.notices.notifications.message=="<p></p>") {
        this.$toast("Skriv ett meddelande innan du skickar...");
        return;
      }

      let o = {
        SendAsNotification: true,
        FromUserId: this.$store.state.everything.user.user_id,
        UserId: this.$store.state.notices.notifications.targetUserId,
        Title: "",
        Message: this.$store.state.notices.notifications.message,
        SubmissionId: null,
        CourseId: null,
        SendAsEmail: false,
        SendToCourseMentors: false,
      };

      this.$store.dispatch("sendMessage", o).then(() => {
        this.$store.state.notices.notifications.message = "";
        var e = document.getElementById("message-container");
        e.scrollTop = e.scrollHeight;
      });
    },
    openMessage(obj) {
      let i = obj["FromUserId"];
      let n = obj["SenderFirstName"] + " " + obj["SenderLastName"];

      this.$store
        .dispatch("showMessageDialog", { id: i, name: n, scope: this })
        .then(
          () => {},
          (error) => {
            console.log(error);
          }
        );
    },
    playSnd(i_snd) {
      if (i_snd == "textIn") {
        let snd = new Audio(require("@/assets/sounds/notice_06.ogg"));
        snd.volume = 0.3;
        var playPromise = snd.play();

        if (playPromise) {
          playPromise
            .catch((e) => {
              //  console.log(e)
              if (
                e.name === "NotAllowedError" ||
                e.name === "NotSupportedError"
              ) {
                //  console.log(e.name);
              }
            })
            .then(() => {
              // console.log("playing sound !!!");
            });
        }
      }
    },
    confirmedToSendLastMessageAsEmail() {
      this.$store.commit("setSendMessageAsEmailConfirmDialog", false);

      this.$store.dispatch("sendLastMessageAsEmail", this).then((a) => {
        console.log("Email transfer completed!");
      });
    },
    cookieAccept() {
      localStorage.cookie_consent = "true";
      this.cookie_consent = false;
    },
    checkKeyPress(e) {
      console.log(e);
    },
    mainMenuExpandIcon(i) {
      if(typeof i.subLinks === 'undefined') {
        return '';
      } else {
        //return marker ? '' : '';
        return 'keyboard_arrow_down';
      }
    },
    togglePlatform() {
      this.$store.commit('setPageEditActivation', false); // reset edit
      
      if(this.isYrk) {
          this.$store.commit("setAppTitle", "ValiWeb VUX");
      } 
      else if(this.isSkea) {
        this.$store.commit("setAppTitle", "ValiWeb Yrkeslärare");
      }
      else {
        this.$store.commit("setAppTitle", "ValiWeb Skellefteå");
      }

      axios.defaults.params["AppTitle"] = this.$store.state.everything.apptitle;
      
      //console.log(this.$root);
      this.setThemeParams();
    },
    setThemeParams() {
        this.app_logo = this.valiweb_logo;  // default logo
        this.app_logo_width = 200;
        this.app_logo_alt = this.$store.state.everything.apptitle + " logo";

        if(this.isSkea) {
          this.app_logo = this.ske_logo;
          this.app_logo_width = 80;
          // theme colors
          this.$root.$vuetify.theme.themes.light.primary = "#2152ad";
        }
        else if(this.isYrk) {
          this.app_logo = this.valiweb_logo;
          this.app_logo_width = 200;
          this.$root.$vuetify.theme.themes.light.primary = "#96B3B8";
        } else {
          this.$root.$vuetify.theme.themes.light.primary = "#2152ad";
        }

    },
    checkForUpdatedContent() {
      console.log("content check...");
      var fd = new FormData();
        
      let tData = localStorage.getItem("translations");
      tData = JSON.parse(tData);

      if(tData==null) return;

      let postData = {
        translations: tData
      }

      fd.append('data', JSON.stringify(postData))

      axios.post(window.serverUrl + '/api/backend/translations/check_for_updated_content.php', fd)
      .then(response => {
        JSON.stringify(response, null, 4)
          if(response.status === 200) {
            if(response.data.status=="FAIL") {
              // this.$toast.warning(response.data.result + " " + response.data.error_code);
              console.log("Fel vid hämtning av content updates: " + response.data.result + " " + response.data.error_code);
              return;
            }

            if(response.data.data!="") {
              for(let a=0;a<response.data.data.length;a++) {
                  this.$store.dispatch("setTranslationFromServer", response.data.data[a]).then((result) => {
                      console.log("Updated store & localStorage result: ", result);
                      return result;
                  });
              }
           
            }


          } else {
           // this.$toast.warning("Någonting gick fel...");
           console.log("Fel vid hämtning av content updates...");
          }
      })
      .catch(e => {
        // this.$toast.warning("Någonting gick fel...");
        console.log("Fel vid hämtning av content updates...");
        console.log(e); // something went wrong
      });  

    }
  },
};
</script>

<style>
.no-underline {
  text-decoration: none !important;
}
.input-group--dirty label {
  max-width: 133%;
  transform: translateY(-18px) scale(0.75);
  pointer-events: auto;
}

.v-snack {
  padding-bottom:40px !important;
}
.myapp-footer p {
  margin:0 !important;
}

figure.v-icon, .my-tip-tap .v-icon {
  height: 24px;
  font-size: 24px;
  width: 24px;
}
</style>
<style lang="scss" scoped>
.yrk-site-text {
    font-size: 20px !important;
}
@media screen and (max-width: 502px) {
  .yrk-site-bollar {
    width: 150px !important;
  }
  .yrk-site-logo {
    width: 150px !important;
  }
  body .yrk-site-text {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 402px) {
  .yrklogo {
    width: 110px !important;
  }
}

.fadeInMe-enter-active,
.fadeInMe-leave-active {
  transition: opacity 0.5s;
}

.fadeInMe-enter,
.fadeInMe-leave-to {
  opacity: 0;
}

.message-card {
  background-color: #e8e8e8;
}
.text--yellow {
  color: #FFC052;
}

.validation-menu {
  z-index:1 !important;
}

@media print {
  .validation-menu {
    display:none;
  }
}
</style>