<template>
          <v-card flat >
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title class="white--text">
                <ContentText :TheKey="$store.state.everything.apptitle + '.CourseMenu.Title'">
                  <template v-slot:DefaultContent>Kursmeny</template>
                </ContentText>
              </v-toolbar-title>
              <div class="flex-grow-1"></div>
              <template v-if="$store.state.everything.courseEditor.currentCourseEditedById!=null">
                 <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="">
                        <v-icon small>mdi-weight</v-icon>
                      </v-btn>
                    </template>
                    <span>
                        Visa viktning
                    </span>
                  </v-tooltip>
                  -->

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="showSortPartCourse()">
                        <v-icon small>sort</v-icon>
                      </v-btn>
                    </template>
                    <span>
                        Sortera delmål
                    </span>
                  </v-tooltip>
              </template>
              <div v-if="courseMenuExpanded"> 
              <!--v-show="$vuetify.breakpoint.mdAndUp" > -->
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <v-btn icon @click="ToggleShowCourseParts()" v-on="on" >
                        <v-icon v-if="showCoursePartList==true">expand_less</v-icon>
                        <v-icon v-else>expand_more</v-icon>
                      </v-btn>
                  </template>
                  <span v-if="showCoursePartList==true">Dölj delmål</span>
                  <span v-else>Visa delmål</span>
                </v-tooltip>
              </div>
            </v-toolbar>

        <div v-if="showCoursePartList==true"
            transition="slide-y-transition"
            class="course-menu"
          >
          <v-list 
            dense
          >
                  <v-list-item @click="BackToCourse()" v-bind:class="isCourseHome">
                    <v-list-item-icon>
                      <v-icon>home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span v-on="on" >{{$store.state.everything.courseEditor.courseData["Name"]}}</span>
                        </template>
                        <span>Till kursöversikt</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="pb-12">
            <template v-for="(item, i) in this.$store.state.everything.courseEditor.courseData['CourseParts']" >
              <v-list-group v-if="PreparePostBlocks(item.Blocks).length>0"
                  v-model="item.active"
                  :key="i"
                  no-action
                  flat
                  :class="GetCoursePartNavClass(item)"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title @click="OpenCoursePart(item.Id)">{{item.Name}} <span class="grey--text">({{item.Id}})</span> 
                    </v-list-item-title>
                  </v-list-item-content>
                  <template v-if="$store.state.everything.user.userData.RelatedUserType==2">
                    <template v-if="item.Goals && item.Goals.length>0">
                      <div v-for="(goal) in item.Goals" :key="goal.Id">
                        <weight-chip :weight="goal.Weight" :category="goal.Category" :weightId="goal.CourseGoalId" :sort="goal.Sort" />
                      </div>
                    </template>
                    <template v-else> <!-- -if="item.Weight===null" -->
                      <weight-chip :weight="null" :category="''" :weightId="null" :sortId="null" />
                    </template>
                  </template>
                </template>
                <v-list-item-group>
                  <v-list-item
                    v-for="(postBlock, post_cnt) in PreparePostBlocks(item.Blocks)"
                    v-model="postBlock.active"
                    :key="post_cnt"
                    link
                    @click="OpenCoursePart(postBlock.PostId)"
                    :class="GetPostNavClass(postBlock)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="postBlock.PostIcon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title > <!-- :class="postBlock.MentorOnly?'grey--text':''" -->
                        {{postBlock.PostName}} <span class="grey--text">({{postBlock.PostId}})</span>
                        <template v-if="postBlock.MentorOnly==true">
                          <v-chip class="ml-2" x-small>LM</v-chip>
                        </template>
                    </v-list-item-title>
                    
                  </v-list-item>
                </v-list-item-group>

            </v-list-group>
            <v-list-item v-else
                  v-model="item.active"
                  :key="i"
                  link
                  :class="GetCoursePartNavClass(item)"
              >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title @click="OpenCoursePart(item.Id)">{{item.Name}} <span class="grey--text">({{item.Id}})</span> 
                      </v-list-item-title>
                    </v-list-item-content>
                    <template v-if="$store.state.everything.user.userData.RelatedUserType==2">
                      <template v-if="item.Goals && item.Goals.length>0">
                        <div v-for="(goal) in item.Goals" :key="goal.Id">
                          <weight-chip :weight="goal.Weight" :category="goal.Category" :weightId="goal.CourseGoalId" :sort="goal.Sort" />
                        </div>
                      </template>
                      <template v-else>
                        <weight-chip :weight="null" :category="''" :weightId="null" :sortId="null" />
                      </template>
                    </template>
                  </template>

              </v-list-item>
            </template>
          </v-list>
          <v-tooltip bottom v-if="$store.state.everything.courseEditor.previewState==false">
            <template v-slot:activator="{ on }">
                <v-btn icon @click="AddCoursePart()" v-on="on" >
                  <v-icon>add</v-icon>
                </v-btn>
            </template>
            <span>Lägg till delmål</span>
          </v-tooltip>
          
        </div>


        <!-- Add Mentors Dialog -->

            <v-dialog v-model="sortCourseDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
             <!--   <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
                </template> -->
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark @click="sortCourseDialog = false" v-on="on">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Stäng
                            </span>
                        </v-tooltip>
                    <v-toolbar-title>Sortera delmål - {{this.$store.state.everything.courseEditor.courseData["Name"]}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn dark icon @click="saveSortedCourseParts()" v-on="on"><v-icon>done</v-icon></v-btn>
                            </template>
                            <span>
                                Klar
                            </span>
                        </v-tooltip>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-list>
                        <!-- Hämta in alla mentorer, exkludera vanliga användare. -->

                 <!--   <v-subheader>Välj mentorer i listan</v-subheader> -->
                            <!-- <UserPicker v-bind:selectedUsers="selectedUsers" v-on:setSelectedUsers="onSetSelectedUsers($event)" /> -->
                            <draggable :list="sortedCoursePartItems" group="people" @start="drag=true" @end="onDragCoursePartFinished" > <!-- :move="checkBlockMove" -->
                              <template v-for="item in sortedCoursePartItems">
                                <v-list-item :key="item.Id">
                                  <v-chip
                                    class="ma-2"
                                    color="indigo"
                                    text-color="white"
                                    label
                                  >{{item.Name}} ({{item.Id}})
                                  </v-chip>
                                </v-list-item>
                              </template>
                            </draggable>                        
                    </v-list>
                    <v-divider></v-divider>
                </v-card>
            </v-dialog>

          </v-card>
</template>

<script>

import axios from 'axios'
import draggable from 'vuedraggable'
import WeightChip from '../components/helpers/WeightChip'
import WeightCategoryChip from '../components/helpers/WeightCategoryChip'
import ContentText from '../components/texts/ContentText.vue';

export default {
  name:'PartCourseMenu',
  components: {
    draggable,
    WeightChip,
    WeightCategoryChip,
    ContentText
  },
  data: () => ({
      //    id: null,
      //    post_id: null,
      //    user_id: null,
          showCoursePartList:true,
          sortedCoursePartItems: [],
          sortCourseDialog: false,
      }),
  props: {
    courseMenuExpanded: Boolean,
    scrollMenu: Number
  },
  computed: {
    isCourseHome: function() {
      if(this.$store.state.everything.courseEditor.currCoursePostId==-1) {
        return "v-item--active"
      } else {
        return ""
      }
    }
  },
  mounted: function() {

  },
  created: function() {
  //  console.log("Post ID is: " + this.$store.state.everything.courseEditor.currCoursePostId)
  //  console.log("Loaded partCourseMenu!")
  },
  beforeDestroy() {
  //  console.log("Destroyed PartCourseMenu")
  },
  methods: {
      loadCourse:function () {
          // Get Main Course
         let data = this.$store.state.everything.courseEditor.courseData
        // console.log("PM:")
      //   console.log(data)

          if(data["CourseParts"] != null) {

          //  console.log(this.$store.state.everything.courseEditor.courseData['CourseParts'])
            var nId = this.$store.state.everything.courseEditor.currCoursePostId //this.$store.state.everything.courseEditor.navForward
          //  console.log("looking for id: " + nId)
            
            for(let cnt=0;cnt<this.$store.state.everything.courseEditor.courseData['CourseParts'].length;cnt++) {
              let o = this.$store.state.everything.courseEditor.courseData['CourseParts'][cnt]["Blocks"].map(function(x) {return x.PostId; }).indexOf(nId)
              
              // this.$store.state.everything.courseEditor.courseData['CourseParts'][o].active = true
              if(o!=-1) {
              //  console.log(this.$store.state.everything.courseEditor.courseData['CourseParts'][cnt]["Blocks"])
              //  console.log("post is:" + o)
                this.$store.state.everything.courseEditor.courseData['CourseParts'][cnt].active = true   // PartCourse
                this.$store.state.everything.courseEditor.courseData['CourseParts'][cnt]["Blocks"][o].active = true  // Actual post
                break
              }
            }


          //  this.coursePartItems = data["CourseParts"]
           // console.log(this.$store.state.everything.courseEditor.courseData)
            /*
            for(let i in this.coursePartItems) {
              this.sortedCoursePartItems.push(this.coursePartItems[i])
            } */
          } else {
            console.log("Course without course-parts.")
          }
      },
      updateCourse:function(response, scope) {
        if(response.data["CourseParts"] != null) {
          scope.coursePartItems = response.data["CourseParts"]
        } else {
          console.log("Course without course-parts.")
        }
      },  
      BackToCourse() {
        let targetPath = '/kurs/' + this.$store.state.everything.courseEditor.currentCourseId

        if(this.$route.path != targetPath) {
          this.$router.push(targetPath)
          localStorage.post_id = -1
        }
      },   
      OpenCoursePart(i_postId) {
        //let targetPath = '/delmoment/' + i_postId
        let targetPath = '/kurs/' + this.$store.state.everything.courseEditor.currentCourseId + "/post/" + i_postId
          

      //  console.log("Curr Path: " + this.$route.path)
      //  console.log("Target Path: " + targetPath)

        if(this.$route.path != targetPath) {        
          
          // Only add browser history when first opening course
          
          if(this.$store.state.everything.courseEditor.currCoursePostId==-1) {
          //  console.log("post id is null!")
          //  localStorage.post_id = i_postId
            this.$store.commit("setCurrPostId", i_postId)
            this.$router.push(targetPath)
          } else {
          //  console.log("post id already set to:" + localStorage.post_id)
          //  localStorage.post_id = i_postId
            this.$store.commit("setCurrPostId", i_postId)
            this.$router.replace(targetPath)
          }

        } else {
          console.log("You are already at location: " + targetPath)
        }
        
      },
      PreparePostBlocks(i_blocks) {
              let blockArr = []
              for(let cnt=0;cnt<i_blocks.length;cnt++) {
                if(i_blocks[cnt]["BlockType"]=="Post") {
                  i_blocks[cnt] = this.FixPostIcon(i_blocks[cnt])
                  blockArr.push(i_blocks[cnt]);
                }
              }
        //    console.log(blockArr)
            return blockArr
      },
      FixPostIcon(i_block) {
        //console.log(i_block["PostType"])
        if(i_block["PostType"]==1) {
          i_block["PostIcon"] = "" // "description"
        }
        else if(i_block["PostType"]==2) {
          i_block["PostIcon"] = "assignment" 
        }
        else if(i_block["PostType"]==3) {
          i_block["PostIcon"] = "school"
        }
        else if(i_block["PostType"]==4) {
          i_block["PostIcon"] = "grade"
        }
        return i_block
      },
      ToggleShowCourseParts() {
          this.showCoursePartList = !this.showCoursePartList
      },
      GetPostNavClass(item) {
      //  console.log(item)
        if(item.PostId == this.$store.state.everything.courseEditor.currCoursePostId) {
         // console.log("Currently editing item: " + item.PostId)
          return "v-item--active v-list-item--active"
        }
      },
      GetCoursePartNavClass(item) {
        if(item.Id == this.$store.state.everything.courseEditor.currCoursePostId) {
        //  console.log(item)
        //  console.log("Active: " + item.active)
        //  console.log("Currently editing item: " + item.Id)
          return "course-part-list-nav-active" // v-list-group--active
        }
      },
      AddCoursePart() {
             console.log("Add new part course to courseId:" + this.$store.state.everything.courseEditor.currentCourseId)
             //var newSortNr = self.coursePartItems.length+1
              let newSortNr = this.$store.state.everything.courseEditor.courseData["CourseParts"].length+1
              // console.log("New SortNr: " + newSortNr)
              
              var fd = new FormData()
              fd.append('req', 'add_course_part')

              let postData = {
                Id: this.$store.state.everything.courseEditor.currentCourseId,
                UserId: this.$store.state.everything.user.user_id,
                SortNr: newSortNr,
                PostType: 1,
                Shareable: 0
              };

              fd.append("data", JSON.stringify(postData));

              axios.post(window.serverUrl + '/api/backend/course_admin.php', fd)
              .then(response => {
                  JSON.stringify(response, null, 4)
                //  console.log(response) // handle success

                    if(response.status === 200){
                      
                      var coursePart = {Id: response.data.data.Id, Name: response.data.data.Name, Blocks: Array()};
                      //console.log(coursePart);

                      this.$store.commit("addCoursePart", coursePart)
                      this.$store.dispatch('setPageNavDirections').then(() => {
                      }).catch(e => {
                          console.log(e)
                      })

                    }
              })
              .catch(e => {
                console.log(e);
              })

      },
      showSortPartCourse() {
        this.sortedCoursePartItems = []
        for(let i in this.$store.state.everything.courseEditor.courseData["CourseParts"]) {
          this.sortedCoursePartItems.push(this.$store.state.everything.courseEditor.courseData["CourseParts"][i])
        }
        this.sortCourseDialog = true
      },
      onDragCoursePartFinished() {
      //  console.log("Done")
      },
      saveSortedCourseParts() {
        for(let i=0;i<this.sortedCoursePartItems.length;i++) {
          this.sortedCoursePartItems[i]["SortId"] = (i+1)
        }
        
        var fd = new FormData()
        fd.append('req', 'save_sorted_course_parts')
       /* fd.append('id', this.$store.state.everything.courseEditor.currentCourseId)
        fd.append('user_id', this.$store.state.everything.user.user_id )
        fd.append('data', JSON.stringify(this.sortedCoursePartItems) ) // Skicka bara sista blocket
        */
        let postData = {
          CourseId: this.$store.state.everything.courseEditor.currentCourseId,
          SortedItems: this.sortedCoursePartItems
        };

        fd.append("data", JSON.stringify(postData));

        axios.post(window.serverUrl + '/api/backend/course_admin.php', fd)
        .then(response => {
            JSON.stringify(response, null, 4)
              if(response.status === 200){
              //  console.log(response) // handle success
                let d = this.$store.state.everything.courseEditor.courseData

                d["CourseParts"] = []
              //  this.coursePartItems = []

                for(let i in this.sortedCoursePartItems) {
                  d["CourseParts"].push(this.sortedCoursePartItems[i])
                //  this.coursePartItems.push(this.sortedCoursePartItems[i])
                }

                this.$store.commit("setCourseData", d)
                this.sortCourseDialog = false
              //  this.$snackbar.show("Sparar delmål.", {duration: 1300})
                this.$toast("Sparar delmål.")
                
                // Reload nav
                this.$store.dispatch('setPageNavDirections')
              }
        })
        .catch(e => {
          console.log(e);
        })

      }
  }
}

</script>