<template >
<!-- v-if="hashChanged && $root.env !== 'development'" --> 
<!-- && serverUrl != 'http://localhost:3000'" -->
    <div class="refresh-container" v-if="hashChanged && serverUrl != 'http://localhost:3000'">
        <v-card >
            <!-- src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" -->
            <v-img
            :src="cloudysky"
            height="240px"
            ></v-img>
            <img 
                :src='rocket' 
                alt="Rocket"
                class="update-rocket"
                style="display:inline-block;"
              >
            <v-card-title class="justify-center">
                <ContentText :TheKey="$store.state.everything.apptitle + '.NewVersion.NewVersionTitle'">
                    <template v-slot:DefaultContent>Ny version!</template>
                </ContentText>
            </v-card-title>

            <v-card-text>
                <div class="notification-body">
                    <div class="notification-button">
                        <ContentArea :TheKey="$store.state.everything.apptitle + '.NewVersion.Description'">
                            <template v-slot:DefaultContent>
                                <p class="text-center font12">En ny version av ValiWeb finns! Klicka på "uppdatera" eller ladda om din webbläsare.</p>
                                <p class="text-center"><span class="font10"><i>Om du inte uppdaterar kan det resultera i fel.</i></span></p>
                            </template>
                        </ContentArea>
                    
                    <!-- <p class="text-center font12">An update is available. Please save all current work and click update below. You can also accept these updates by refreshing your browswer at any time.</p>
                    <p class="text-center"><span class="font10">Not updating may result in errors.</span></p>
                    -->
                    </div>
                    <div class="refresh-button text-center">
                    <v-btn depressed color="primary" class="btn btn-default" @click="reloadApp">
                        <ContentText :TheKey="$store.state.everything.apptitle + '.NewVersion.UpdateBtn'">
                            <template v-slot:DefaultContent>Uppdatera</template>
                        </ContentText>
                    </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { refreshPageMixin } from '@/components/mixins/refresh-page.mixin';
import ContentArea from "@/components/texts/ContentArea.vue";
import ContentText from "@/components/texts/ContentText.vue";

export default {
    name: 'new-version',
    mixins: [refreshPageMixin],
    components:{
        ContentArea,
        ContentText
    },
    data: () => ({
        serverUrl: "",
        ready: false,
        cloudysky: require('@/assets/images/cloudysky.jpg'), 
        rocket: require('@/assets/images/rocket.svg')
    }),
    mounted() {
        this.serverUrl = window.serverUrl;
    //    console.log("url is: " + this.serverUrl);
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
    .refresh-container {
        width: 22%;
        min-width:250px;
        max-width:300px;
        position: fixed;
        bottom: 15px;
        right: 15px;
        background-color: none;
        border:none;
        z-index:9999;
    /*   -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
        -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
        box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05); */
    }
    .close-refresh-modal {
        position: absolute;
        right: 5px;
        top: 5px;
        border: none;
    }

    .update-rocket {
        position:absolute;
        right:-28px;
        top:-60px;
        width:240px;
        height:200px;
    }
</style>