import { render, staticRenderFns } from "./WeightChip.vue?vue&type=template&id=53814a3d&scoped=true&"
import script from "./WeightChip.vue?vue&type=script&lang=js&"
export * from "./WeightChip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53814a3d",
  null
  
)

export default component.exports