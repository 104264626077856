import Vue from 'vue'
//import Vuetify from 'vuetify'
import Vuetify, { VIcon, VSnackbar, VBtn, VProgressLinear } from 'vuetify/lib'     // a la carte install of vuetify
// const SnackbarStackPlugin = require('snackbarstack/dist/index.node.js')
import VuetifyToast from 'vuetify-toast-snackbar-ng'


Vue.use(Vuetify, {
  components: { VIcon, VSnackbar, VBtn, VProgressLinear }
})

// Vue.use(Vuetify)

/*
// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify, { VLayout } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: { VLayout },
})

const opts = {}

export default new Vuetify(opts)
*/


// Vue.use(SnackbarStackPlugin)
// Vue.use(VuetifyToast)

Vue.use(VuetifyToast, {
  x: 'center', // default
  y: 'bottom', // default
  color: '#238057', // default
  icon: 'info',
  iconColor: '', // default
  classes: [
      'body-2'
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: true, // default
  showClose: false, // default
  closeText: '', // default
  closeIcon: 'close', // default
  closeColor: '', // default
  slot: [], //default
  shorts: {
      custom: {
        color: 'purple',
        showClose:true
      },
      warning: {
        color: 'purple',
        showClose:true,
        icon: 'error_outline',
        queueable: false,
        timeout: 8000
      },
      save: {
        color: '#238057',
        icon: 'info',
        timeout: 2000
      }
  },
  property: '$toast' // default
})

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#2152ad',// '#00bcd4',     // 2152ad
        secondary: '#ffffff', // #424242
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#333333', // #2196F3
        success: '#0abb71', //old success green #4CAF50
        warning: '#FFC107',
        background: '#f5f5f5',
        btn_col: '#2152ad',//'#1b6075',
      },
      dark: {
        primary: '#2196F3'
      },
    },
  },
  options: {
    customProperties: true,
  },
  icons: {
    iconfont: 'md', // default is 'mdi'
  }
}

export default new Vuetify(opts)

