<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-chip 
                v-on="on"
                dark
                class="ma-2 float-left"
                :color="chipColor"
                small
            >
                <v-icon left small>{{icon}}</v-icon>
                {{weightStr}}
            </v-chip>
        </template>
        <span>
            {{weightTooltip}}
        </span>
    </v-tooltip>
</template>
<script>
    export default {
    name: 'WeightChip',
    components: {

    },
    props: {
        weight: {
            type: String,
            required: false,
            default: ""
        },
        sort: {
            type: Number,
            required: false,
            default: 1
        },
        weightId: {
            type: Number,
            required: false,
            default: -1
        },
        tooltip: {
            type: String,
            required: false,
            default: ""
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        category: {
            type: String,
            required: false,
            default: ''
        }
    },  
    data: () => ({
        
    }),
    created() {
        
    },
    mounted() {

    },
    computed: {
        weightStr() {
            if(this.weight!=null) {
                return this.weight;
            } else {
                return "Ej viktad!";
            }
        },
        weightTooltip() {
            var str = "";
            if(this.sort!=null) {
                str += "Mål: " + this.sort + " – ";
            }

            if(this.weight!=null) {
                str += "Viktning: " + this.weight;
                if(this.category!='') {
                    str += " – " + this.category + " (id: " + this.weightId + ")";
                }
                
                return str;
            } else {
                return "Ej viktad!";
            }
        },
        chipColor() {
            if(this.category!='') {
                if(this.category=="Teoretisk") {
                    return "blue darken-1";
                }
                else if(this.category=="Praktisk") {
                    return "red lighten-1";
                }
                else if(this.category=="Teoretisk och praktisk") {
                    return "green darken-1";
                }
            }

            if(this.weight==null) {
                return "orange";
            }
            if(this.weight<0.6) {
                return "blue lighten-3";
            }
            else if(this.weight<1.0 && this.weight>0.5) {
                return "blue lighten-1";
            }
            else if(this.weight==1.0) {
                return "blue darken-1";
            }
            else if(this.weight>1.0 && this.weight<1.6) {
                return "blue darken-3";
            }
            else if(this.weight>1.5) {
                return "blue darken-4";
            }
        },
        compDate() {
            if(this.date) {
                if(this.compressDate) {
                    return this.date.substring(0,10);
                } else {
                    return this.date;
                }
            } else {
                return '...';
            }
        },
        tooltipResult() {
            if(this.weight) {
                return this.tooltip;
            } else {
                return 'Tomt';
            }
        }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
