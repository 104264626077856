<template>
    <div>
      <v-text-field
        v-model="icon"
        :prepend-inner-icon="selectedIcon"
        label="Välj ikon"
        color="deep-purple"            
        readonly
        @click="showDialog = true"
      >
        <template v-slot:append>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="icon != ''"
                  icon small
                    v-bind="attrs"
                    v-on="on"
                    @click="clearIcon()"
                  >
                    <v-icon>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </template>
                  Rensa
              </v-tooltip>
            </template>
         </v-text-field>
        <v-dialog
        v-model="showDialog"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="700px"
        max-height="850px"
      >
        <v-card>
          <v-app-bar
            color="white"
            scroll-target="#scrolling-techniques-7"
            elevation="0"
          >
            <v-text-field
              v-model="searchIcon"
              placeholder="Search icon"
              prepend-icon="mdi-magnify"
              type="text"
              onautocomplete="off"
              dense
              @input="filterIcons"
            />
            <v-btn-toggle @change="typeChanged" v-model="iconType">
                <v-btn value="All"><span class="hidden-sm-and-down">All</span><v-icon>alert-circle</v-icon></v-btn>
                <v-btn value=""><span class="hidden-sm-and-down">Filled</span><v-icon>alert-circle</v-icon></v-btn>
                <v-btn value="outline"><span class="hidden-sm-and-down">Outlined</span><v-icon>alert-circle-outline</v-icon></v-btn>
              </v-btn-toggle>
          </v-app-bar>
          <v-sheet
            id="scrolling-techniques-7"
            class="overflow-y-auto iconPicker"
            max-height="600" 
          >
            <v-col cols="12">
              <v-btn
                v-for="(item, i) in filteredIcons"
                :key="i"
                @click="saveIcon(item)"
                color="white"
                class="mr-2 mb-2 "
                fab
                small
                depressed
                :title="'mdi-' + item"
              >
                <v-icon color="grey darken-3" class="material-icons"> mdi-{{ item }} </v-icon>
              </v-btn>
            </v-col>
            
          </v-sheet>
        </v-card>
      </v-dialog>
         
    </div>
  </template>
  
  <script>
  
  export default {
    name:'IconPicker',
  components: {
  },
    props: {
        
    },
    data: () => ({
        showDialog: false,
      icon: '',
      searchIcon: '',
      selectedIcon: '',
      iconType: 'All',
      filteredIcons: [],
      //commented out icons don't work for some reason...
      allIcons: [
      "ab-testing",
      "abacus",
      "access-point",
      "access-point-check",
      "access-point-minus",
      "access-point-network",
      "access-point-network-off",
      "access-point-off",
      "access-point-plus",
      "access-point-remove",
  "account",
  "account-alert",
  "account-alert-outline",
  "account-box",
  "account-box-multiple",
  "account-box-multiple-outline",
  "account-box-outline",
  "account-cancel",
  "account-cancel-outline",
  //"account-card", 
  //"account-card-outline",
  "account-check",
  "account-check-outline",
  "account-check",
  "account-circle",
  "account-circle-outline",
  "account-clock",
  "account-clock-outline",
  "account-cog",
  "account-cog-outline",
  "account-cowboy-hat",
  //"account-cowboy-hat-outline",
  //"account-credit-card",
  //"account-credit-card-outline",
  "account-details",
  "account-edit",
  "account-edit-outline",
  //"account-eye",
  //"account-eye-outline",
  //"account-filter",
  //"account-filter-outline",
  "account-group",
  "account-group-outline",
  "account-hard-hat",
  //"account-hard-hat-outline",
  "account-heart",
  "account-heart-outline",
  "account-key",
  "account-key-outline",
  "account-lock",
  //"account-lock-open",
  //"account-lock-open-outline",
  "account-lock-outline",
  "account-minus",
  "account-minus-outline",
  "account-multiple",
  "account-multiple-check",
  "account-multiple-check-outline",
  "account-multiple-minus",
  "account-multiple-minus-outline",
  "account-multiple-outline",
  "account-multiple-plus",
  "account-multiple-plus-outline",
  "account-multiple-remove",
  "account-multiple-remove-outline",
  "account-music",
  "account-music-outline",
  "account-network",
  "account-outline",
  "account-off",
  "account-off-outline",
  "account-plus",
  "account-plus-outline",
  "account-question",
  "account-question-outline",
  "account-reactivate",
  "account-reactivate-outline",
  "account-remove",
  "account-remove-outline",
  "account-search",
  "account-search-outline",
  "account-star",
  "account-switch",
  //"account-wrench",
  //"account-wrench-outline",

  "adjust",
  "air-filter",
  "air-horn",
  "airbag",
  //"airbaloon",
  //"airbaloon-outline",
  "airplane",
  //"airplane-alert",
  "alarm-plus",
  "alarm-snooze",
  "alarm",
  




  "airballoon",
  "airplane",
  "airplane-off",
  "alarm",
  "alarm-check",
  "alarm-multiple",
  "alarm-off",
  "alarm-plus",
  "album",
  "alert",
  "alert-box",
  "alert-box-outline",
  "alert-circle",
  "alert-circle-check",
  "alert-circle-check-outline",
  "alert-circle-outline",
  "alert-octagon",
  "alpha",
  "alphabetical",
  //"amazon",
  "google-cloud",
  "ambulance",
  "android",
  //"android-debug-bridge",
  "android-studio",
  "apple",
  "apple-finder",
  "apple-ios",
  "apple-safari",
  "apps",
  "archive",
  "arrange-bring-forward",
  "arrange-bring-to-front",
  "arrange-send-backward",
  "arrange-send-to-back",
  "arrow-all",
  "arrow-bottom-left",
  "arrow-bottom-right",
  "arrow-collapse",
  "arrow-down",
  "arrow-down-bold",
  "arrow-down-bold-circle",
  "arrow-down-bold-circle-outline",
  "arrow-down-bold-hexagon-outline",
  "arrow-expand",
  "arrow-left",
  "arrow-left-bold",
  "arrow-left-bold-circle",
  "arrow-left-bold-circle-outline",
  "arrow-left-bold-hexagon-outline",
  "arrow-right",
  "arrow-right-bold",
  "arrow-right-bold-circle",
  "arrow-right-bold-circle-outline",
  "arrow-right-bold-hexagon-outline",
  "arrow-top-left",
  "arrow-top-right",
  "arrow-up",
  "arrow-up-bold",
  "arrow-up-bold-circle",
  "arrow-up-bold-circle-outline",
  "arrow-up-bold-hexagon-outline",
  "at",
  "attachment",
  "auto-fix",
  "auto-upload",
  "baby",
  "backburger",
  "backup-restore",
  "bank",
  "barcode",
  "barley",
  "barrel",
  "basket",
  "basket-fill",
  "basket-unfill",
  "battery",
  "battery-10",
  "battery-20",
  "battery-30",
  "battery-40",
  "battery-50",
  "battery-60",
  "battery-70",
  "battery-80",
  "battery-90",
  "battery-alert",
  "battery-charging-100",
  "battery-charging-20",
  "battery-charging-30",
  "battery-charging-40",
  "battery-charging-60",
  "battery-charging-80",
  "battery-charging-90",
  "battery-minus",
  "battery-negative",
  "battery-outline",
  "battery-plus",
  "battery-positive",
  "battery-unknown",
  "beach",
  "beaker",
  "beaker-outline",
  "beer",
  "bell",
  "bell-off",
  "bell-outline",
  "bell-ring",
  "bell-ring-outline",
  "bell-sleep",
  "beta",
  "bike",
  "binoculars",
  "bio",
  "biohazard",
  "bitbucket",
  "black-mesa",
  "blinds",
  "block-helper",
  //"blogger",
  "bluetooth",
  "bluetooth-audio",
  "bluetooth-connect",
  "bluetooth-settings",
  "bluetooth-transfer",
  "blur",
  "blur-linear",
  "blur-off",
  "blur-radial",
  "bone",
  "book",
  "book-multiple",
  "book-open",
  "book-variant",
  "bookmark",
  "bookmark-check",
  "bookmark-music",
  "bookmark-outline",
  "bookmark-plus",
  "bookmark-remove",
  "border-all",
  "border-bottom",
  "border-color",
  "border-horizontal",
  "border-inside",
  "border-left",
  "border-none",
  "border-outside",
  "border-right",
  "border-top",
  "border-vertical",
  "bowling",
  "box",
  "briefcase",
  "briefcase-check",
  "briefcase-download",
  "briefcase-upload",
  "brightness-1",
  "brightness-2",
  "brightness-3",
  "brightness-4",
  "brightness-5",
  "brightness-6",
  "brightness-7",
  "brightness-auto",
  "broom",
  "brush",
  "bug",
  "bulletin-board",
  "bullhorn",
  "bus",
  "cake",
  "cake-variant",
  "calculator",
  "calendar",
  "calendar-blank",
  "calendar-check",
  "calendar-clock",
  "calendar-multiple",
  "calendar-multiple-check",
  "calendar-plus",
  "calendar-remove",
  "calendar-text",
  "calendar-today",
  "camcorder",
  "camcorder-off",
  "camera",
  "camera-front",
  "camera-front-variant",
  "camera-iris",
  "camera-party-mode",
  "camera-rear",
  "camera-rear-variant",
  "camera-switch",
  "camera-timer",
  "candycane",
  "car",
  "car-wash",
  "carrot",
  "cart",
  "cart-outline",
  "cash",
  "cash-100",
  "cash-multiple",
  //"cash-usd",
  "cast",
  "cast-connected",
  "castle",
  "cat",
  "cellphone",
  //"cellphone-android",
  "cellphone-dock",
  //"cellphone-iphone",
  "cellphone-link",
  "cellphone-link-off",
  "cellphone-settings",
  "chair-school",
  "chart-arc",
  "chart-areaspline",
  "chart-bar",
  "chart-histogram",
  "chart-line",
  "chart-pie",
  "check",
  "check-all",
  "checkbox-blank",
  "checkbox-blank-circle",
  "checkbox-blank-circle-outline",
  "checkbox-blank-outline",
  "checkbox-marked",
  "checkbox-marked-circle",
  "checkbox-marked-circle-outline",
  "checkbox-marked-outline",
  "checkbox-multiple-blank",
  "checkbox-multiple-blank-outline",
  "checkbox-multiple-marked",
  "checkbox-multiple-marked-outline",
  "checkerboard",
  "chevron-double-down",
  "chevron-double-left",
  "chevron-double-right",
  "chevron-double-up",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "church",
  "city",
  "clipboard",
  "clipboard-account",
  "clipboard-alert",
  "clipboard-arrow-down",
  "clipboard-arrow-left",
  "clipboard-check",
  "clipboard-outline",
  "clipboard-text",
  "clippy",
  "clock",
  "clock-fast",
  "close",
  "close-box",
  "close-box-outline",
  "close-circle",
  "close-circle-outline",
  "close-network",
  "closed-caption",
  "cloud",
  "apple-icloud",
  "cloud-check",
  "cloud-circle",
  "cloud-download",
  "cloud-outline",
  "cloud-off-outline",
  "cloud-upload",
  "cloud-refresh",
  "cloud-lock",
  "cloud-lock-outline",
  "cloud-question",
  "cloud-tags",
  "cloud-print",
  "cloud-print-outline",
  "cloud-search",
  "cloud-search-outline",
  "code-array",
  "code-braces",
  "code-equal",
  "code-greater-than",
  "code-less-than",
  "code-less-than-or-equal",
  "code-not-equal",
  "code-not-equal-variant",
  "code-string",
  "code-tags",
  "codepen",
  "coffee",
  "coffee-to-go",
  "color-helper",
  "comment",
  "comment-account",
  "comment-account-outline",
  "comment-alert",
  "comment-alert-outline",
  "comment-check",
  "comment-check-outline",
  "comment-multiple-outline",
  "comment-outline",
  "comment-plus-outline",
  "comment-processing",
  "comment-processing-outline",
  "comment-remove-outline",
  "comment-text",
  "comment-text-outline",
  "compare",
  "compass",
  "compass-outline",
  "console",
  "content-copy",
  "content-cut",
  "content-duplicate",
  "content-paste",
  "content-save",
  "content-save-all",
  "contrast",
  "contrast-box",
  "contrast-circle",
  "cow",
  "credit-card",
  "credit-card-multiple",
  "crop",
  "crop-free",
  "crop-landscape",
  "crop-portrait",
  "crop-square",
  "crosshairs",
  "crosshairs-gps",
  "crown",
  "cube",
  "cube-outline",
  "cube-unfolded",
  "cup",
  "cup-water",
  "currency-btc",
  "currency-eur",
  "currency-gbp",
  "currency-inr",
  "currency-rub",
  "currency-try",
  "currency-usd",
  "cursor-default",
  "cursor-default-outline",
  "cursor-move",
  "cursor-pointer",
  "database",
  "database-minus",
  "database-outline",
  "database-plus",
  "debug-step-into",
  "debug-step-out",
  "debug-step-over",
  "decimal-decrease",
  "decimal-increase",
  "delete",
  "delete-variant",
  "deskphone",
  "desktop-mac",
  "desktop-tower",
  "details",
  "deviantart",
  "diamond",
  "dice-1",
  "dice-2",
  "dice-3",
  "dice-4",
  "dice-5",
  "dice-6",
  "directions",
  "disqus",
  "division",
  "division-box",
  "dns",
  "domain",
  "dots-horizontal",
  "dots-vertical",
  "download",
  "drag",
  "drag-horizontal",
  "drag-vertical",
  "drawing",
  "drawing-box",
  "drone",
  "dropbox",
  "drupal",
  "duck",
  "dumbbell",
  "earth",
  "earth-off",
  "eject",
  "elevation-decline",
  "elevation-rise",
  "elevator",
  "email",
  "email-open",
  "email-outline",
  "emoticon",
  "emoticon-cool",
  "emoticon-devil",
  "emoticon-happy",
  "emoticon-neutral",
  "emoticon-poop",
  "emoticon-sad",
  "emoticon-tongue",
  "engine",
  "engine-outline",
  "equal",
  "equal-box",
  "eraser",
  "escalator",
  "evernote",
  "exclamation",
  "exit-to-app",
  "export",
  "eye",
  "eye-off",
  "eyedropper",
  "eyedropper-variant",
  "facebook",
  "facebook-messenger",
  "factory",
  "fan",
  "fast-forward",
  "ferry",
  "file",
  "file-cloud",
  "file-delimited",
  "file-document",
  "file-excel-box",
  "file-find",
  "file-image",
  "file-multiple",
  "file-music",
  "file-outline",
  //"file-pdf",
  "file-pdf-box",
  "file-powerpoint",
  "file-powerpoint-box",
  "file-presentation-box",
  "file-video",
  "file-word",
  "file-word-box",
  "film",
  "filmstrip",
  "filmstrip-off",
  "filter",
  "filter-outline",
  "filter-remove-outline",
  "filter-variant",
  "fire",
  "firefox",
  "fish",
  "flag",
  "flag-checkered",
  "flag-outline",
  "flag-triangle",
  "flag-variant",
  "flash",
  "flash-auto",
  "flash-off",
  "flashlight",
  "flashlight-off",
  "flip-to-back",
  "flip-to-front",
  "floppy",
  "flower",
  "folder",
  "folder-account",
  "folder-download",
  "folder-google-drive",
  "folder-image",
  "folder-lock",
  "folder-lock-open",
  "folder-move",
  "folder-multiple",
  "folder-multiple-image",
  "folder-multiple-outline",
  "folder-outline",
  "folder-plus",
  "folder-remove",
  "folder-upload",
  "food",
  "food-apple",
  "food-variant",
  "football",
  "football-helmet",
  "format-align-center",
  "format-align-justify",
  "format-align-left",
  "format-align-right",
  "format-bold",
  "format-clear",
  "format-color-fill",
  "format-float-center",
  "format-float-left",
  "format-float-none",
  "format-float-right",
  "format-header-1",
  "format-header-2",
  "format-header-3",
  "format-header-4",
  "format-header-5",
  "format-header-6",
  "format-header-decrease",
  "format-header-equal",
  "format-header-increase",
  "format-header-pound",
  "format-indent-decrease",
  "format-indent-increase",
  "format-italic",
  "format-line-spacing",
  "format-list-bulleted",

  "format-paint",
  "format-paragraph",

  "format-size",
  "format-strikethrough",
  "format-subscript",
  "format-superscript",
  "format-text",
  "format-textdirection-l-to-r",
  "format-textdirection-r-to-l",
  "format-underline",
  "format-wrap-inline",
  "format-wrap-square",
  "format-wrap-tight",
  "format-wrap-top-bottom",
  "forum",
  "forward",
  "fridge",
  "fullscreen",
  "fullscreen-exit",
  "function",
  "gamepad",
  "gamepad-variant",
  "gas-station",
  "gavel",
  "gender-female",
  "gender-male",
  "gender-male-female",
  "gender-transgender",
  "ghost",

  "gift",
  "git",
  "github",
  "glass-flute",
  "glass-mug",
  "glass-stange",
  "glass-tulip",
  "glasses",
  "gmail",
  "google",
  "google-chrome",
  "google-circles",
  "google-circles-communities",
  "google-circles-extended",
  "google-circles-group",
  "google-controller",
  "google-controller-off",
  "google-drive",
  "google-earth",
  "google-glass",
  "google-maps",

  "google-play",
  "google-plus",
  "google-hangouts",
  "grid",
  "grid-off",
  "group",

  "guitar-pick",
  "guitar-pick-outline",
  "halloween",
  "hamburger",
  "hammer",
  "hand-pointing-down",
  "hand-pointing-left",
  "hand-pointing-right",
  "hand-pointing-up",
  "hanger",
  "hand-saw",
  "hand-wash",
  "hand-wash-outline",

  "harddisk",
  "hat-fedora",


  "headphones",
  "headphones-box",
  "headphones-settings",

  "heart",
  "heart-flash",
  "heart-half",
  "heart-half-full",
  "heart-half-outline",
  "heart-minus",
  "heart-minus-outline",
  "heart-outline",
  "heart-plus",
  "heart-plus-outline",
  "heart-pulse",
  "heart-remove",
  "heart-remove-outline",
  "heart-settings",
  "heart-settings-outline",
  //"heat-wave",
  "helicopter",
  "help",
  "help-circle",
  "help-circle-outline",
  "help-rhombus",
  "help-rhombus-outline",
  "hexadecimal",
  "hexagon",
  "hexagon-outline",
  "hexagon-slice-1",
  "hexagon-slice-2",
  "hexagon-slice-3",
  "hexagon-slice-4",
  "hexagon-slice-5",
  "hexagon-slice-6",
  "hexagram",
  "hexagram-outline",
  "highway",
  "hiking",
  "history",
  "hockey-puck",
  "home",
  "home-account",
  "home-alert",
  "home-alert-outline",
  "home-analytics",
  //"home-assitant",
  "home-automation",
  //"home-battery",
  //"home-battery-outline",
  "home-circle",
  "home-circle-outline",
  //"home-clock",
  "home-edit",
  "home-group",
  "home-minus",
  "home-minus-outline",
  //"home-off",
  "home-plus",
  "home-plus-outline",
  "home-remove",
  "home-remove-outline",
  "horse",
  "horse-human",
  "horse-variant",
  //"horse-variant-fast",
  "horseshoe",
  "hospital",
  "hospital-box",
  "hospital-box-outline",
  "human",
  "human-baby-changing-table",
  "human-cane",
  "human-child",

  "image",
  //"image-drama-filter",
  "image-filter-hdr",
  "image-filter-none",
  "image-frame",
  "image-plus",
  "information",
  "information-outline",
  "information-variant",
  "instagram",
  "jellyfish",
  "jellyfish-outline",
  "jsfiddle",
  "kangaroo",
  "karate",
  //"kayaking",
  "keg",
  "kettle",
  "kettle-outline",
  "key",
  "key-outline",
  "keyboard-return",
  "knife",
  "knife-military",
  //"knob",
  "koala",
  "label",
  "label-outline",
  "ladybug",
  "lambda",
  "lamp",
  //"lamp-outline",
  "language-c",
  "language-cpp",
  "language-csharp",
  "language-html5",
  "language-java",
  "language-javascript",
  "language-lua",
  "language-php",
  "language-python",
  "language-ruby",
  "language-rust",
  "language-typescript",
  "language-xaml",
  "laptop",
  //"laptop-account",
  "laptop-off",
  "launch",
  "layers",
  "layers-outline",
  "lead-pencil",
  "leaf",
  "leaf-maple",
  "led-variant-on",
  "led-variant-outline",
  "library",
  //"library-outline",
  //"lifebouy",
  "light-switch",
  //"light-switch-off",
  "lightbulb",
  //"lightbulb-alert",
  //"lightbulb-alert-outline",
  "lightbulb-group",
  "lightbulb-group-off",
  "lightbulb-multiple",
  "lightbulb-on",
  //"lightbulb-on-10",
  "lightbulb-outline",
  //"lightbulb-question",
  //"lightbulb-question-outline",
  //"lightbulb-variant",
  "lightning-bolt",
  //"lightning-bolt-circle",
  "lightning-bolt-outline",
  "link",
  "link-box",
  "link-plus",
  "link-variant",
  "lock",
  "lock-alert",
  "lock-alert-outline",
  "lock-check",
  "lock-check-outline",
  "lock-clock",
  "lock-open",
  "lock-open-outline",
  "lock-open-variant",
  "lock-open-variant-outline",
  "login",
  "login-variant",
  "logout",
  "logout-variant",
  "longitude",
  "looks",
  "lotion",
  "lotion-outline",
  "lotion-plus",
  "magnet",
  "magnify",
  "magnify-minus",
  "magnify-plus",
  "mail",
  "mailbox",
  "mailbox-open",
  "menu-down",
  "menu-left",
  "menu-right",
  "menu-up",
  "message",
  "message-alert",
  "message-alert-outline",
  //"message-badge",
  "message-outline",
  "message-question",
  "message-text",
  "message-text-outline",

  "microsoft",
  "microsoft-visual-studio-code",
  "microsoft-windows",
  "microsoft-windows-classic",
  "microsoft-word",
  "microsoft-xbox-controller",

  "minus",  
  "minus-circle",

  "music-note",

  "pen",
  "pencil",
  "pencil-outline",
  "phone",
  "phone-hangup",

  "pickaxe",
  "pig",
  "pill",
  "pillar",
  "pine-tree",
  "pinwheel",
  "pirate",
  "pistol",
  "pitchfork",
  "pizza",
  "play",
  "play-circle",
  "play-outline",
  "play-pause",

  "plus-circle",
  "plus-thick",
  "pot",
  "pot-mix",
  "pot-steam",
  "pound",
  "pound-box",
  "power",
  "power-cycle",
  "power-off",
  "power-plug",
  "power-settings",
  "power-sleep",
  "power-standby",
  "printer",
  //"printer-outline",
  "protocol",
  "publish",
  "pulse",
  "pumpkin",
  "puzzle",
  //"pyramid",
  "rabbit",
  "radioactive",
  
  "reload",

  //"repeat-variant",
  "replay",
  "reply",
  "road",
  "road-variant",
  "robot",
  "robot-industrial",
  "roller-skate",
  //"rotate-360",
  "rotate-3d",
  "rotate-3d-variant",
  "rotate-left",
  "rotate-right",
  "ruler",
  "ruler-square",
  "run",
  "run-fast",
  "sack",
  "sack-percent",



  "rake",

  "safety-goggles",
  "sail-boat",
  "sale",
  //"sail-boat-sink",
  "scale",
  //"scale-balanced",
  //"scale-unbalanced",
  "scan-helper",
  "school",
  "school-outline",
  "scissors-cutting",
  "script",
  "script-outline",
  "script-text",
  "script-text-outline",
  "sd",
  "seat",
  "seat-outline",
  "security",
  "seed",
  "seed-outline",
  "segment",

  "select",
"select-color",
"send",
"send-check",
"send-circle",
"send-circle-outline",
"send-outline",
"server",
//"shark",
"shark-fin",
"shark-fin-outline",
"shield",
"shield-alert",
"shoe-print",
"skull",
"skull-outline",
//"sledding",
"sleep",
"sleep-off",
"snail",
"snake",
"snapchat",
"snowboard",
"snowflake",
"snowman",
//"snowmobile",
//"snowshoeing",
"soccer",
"social-distance-2-meters",
"sort",
"source-branch",
"space-invaders",
//"spear",
"spider",
"spider-thread",
"spider-web",
"spoon-sugar",
"spotify",
"spray-bottle",
"sprout",
"square",
"star",
"star-face",
"sticker",
"summit",
"sunglasses",
"swap-horizontal",
"swap-horizontal-bold",
"swap-vertical",
"swap-vertical-bold",
"sync",
"sync-alert",
"sync-circle",
"tab",
"tablet",
"tablet-cellphone",
"tablet-dashboard",
"tag",
"tailwind",
"target",
"target-account",
"target-variant",
"taxi",
"tea",
//"teddy-bear",
"telescope",
"television",
"television-classic",
//"temperature-celcius",
"temperature-fahrenheit",
"terrain",
"test-tube",
"test-tube-empty",
"text",
"text-account",
"text-box",
//"text-long",
"text-shadow",
"text-short",
"texture",
"texture-box",
"thermometer",
"thumb-down",
"thumb-up",


  "timer-sand",
  //"timer-sand-complete",
  "timer-sand-empty",
  "timer-sand-full",
  //"timer-sand-paused",
  //"timer-settings",
  //"timer-settings-outline",
  //"tire",
  "toaster",
  "toaster-off",
  "toaster-oven",
  "toggle-switch",
  "toggle-switch-off",
  "toggle-switch-off-outline",
  "toggle-switch-outline",
  //"toggle-switch-variant",
  //"toggle-switch-variant-off",
  "toilet",
  "toolbox",
  "toolbox-outline",
  "tools",
  "tooltip",
  "tooltip-account",
  //"tooltip-cellphone",
  "tooltip-check",
  "tooltip-check-outline",
  "tooltip-edit",
  "tooltip-edit-outline",
  "tooltip-image",
  "tooltip-image-outline",
  "tooltip-minus",
  "tooltip-minus-outline",
  "tooltip-outline",
  "tooltip-plus",
  "tooltip-plus-outline",
  //"tooltip-question",
  //"tooltip-question-outline",
  "tooltip-remove",
  "tooltip-remove-outline",
  "tooltip-text",
  "tooltip-text-outline",
  "tooth",
  "tooth-outline",
  "toothbrush",
  "toothbrush-electric",
  "toothbrush-paste",
  "torch",
  "tortoise",
  "toslink",
  //"tournamnet",
  "tow-truck",
  "tower-beach",
  "tower-fire",
  //"town-hall",
  "toy-brick",
  "toy-brick-outline",
  "tractor",
  "tractor-variant",
  "traffic-light",
  //"traffic-light-outline",
  "train",
  "train-car",
  //"train-car-box",
  //"train-car-caboose",
  //"train-car-centerbeam",
  //"train-car-flatbed-car",
  //"train-car-flatbed-tank",
  "train-variant",
  "tram",
  "transfer-down",
  "transfer-left",
  "transfer-right",
  "transfer-up",
  "transit-connection-variant",
  "trash-can",
  "trash-can-outline",
  "tray",
  "tray-alert",
  //"tray-arrow-down",
  //"tray-arrow-up",
  "tray-full",
  "trending-down",
  "trending-neutral",
  "trending-up",
  "triangle",
  "triangle-outline",
  //"triangle-small-down",
  //"triangle-small-up",
  "triangle-wave",
  "triforce",
  "trophy",
  "trophy-award",
  "trophy-outline",
  "truck",
  //"truck-alert",
  //"truck-alert-outline",
  //"truck-cargo-container",
  "truck-check",
  "truck-check-outline",
  "truck-delivery",
  "truck-delivery-outline",
  "truck-fast",
  "truck-fast-outline",
  //"truck-flatbed",
  //"truck-minus",
  //"truck-minus-outline",
  "truck-outline",
  //"truck-plus",
  //"truck-plus-outline",
  //"truck-remove",
  //"truck-remove-outline",
  "trumpet",
  "tshirt-crew",
  "tshirt-crew-outline",
  //"tsunami",
  "tune",
  "tune-variant",
  "tune-vertical",
  "tune-vertical-variant",
  //"tunnel",
  //"tunnel-outline",
  //"turbine",
  "twitch",
  "twitter",
  "ubisoft",
  "ubuntu",
  "ufo",
  "ufo-outline",
  "umbrella",
  //"umbrella-beach",
  //"umbrella-beach-outline",
  "umbrella-closed",
  "umbrella-closed-outline",
  "umbrella-outline",
  "undo",
  "undo-variant",
  "unfold-less-horizontal",
  "unfold-less-vertical",
  "unfold-more-horizontal",
  "unfold-more-vertical",
  "ungroup",
  "unicorn",
  "unicorn-variant",
  "unicycle",
  "unity",
  "unreal",
  "update",
  "upload",
  "upload-lock",
  "upload-lock-outline",
  "upload-multiple",
  "upload-outline",
  "usb",
  "usb-flash-drive",
  "usb-flash-drive-outline",
  //"van-passanger",
  "van-utility",
  "vanish",
  "vanish-quarter",
  "variable",
  "variable-box",
  "vector-arrange-above",
  "vector-arrange-below",
  "vector-bezier",
  "vector-circle",
  "vector-circle-variant",
  "vector-combine",
  "vector-curve",
  "vector-difference",
  "vector-difference-ab",
  "vector-difference-ba",
  "vector-ellipse",
  "vector-intersection",
  "vector-line",
  "vector-square",
  "video",
  "video-account",
  "video-box",
  "video-check",
  "video-input-component",
  "video-input-hdmi",
  "video-input-scart",
  "video-input-svideo",
  "video-switch",
  "video-vintage",
  "view-grid",
  "view-grid-outline",
  "view-headline",
  "view-list",
  "view-list-outline",
  "view-module",
  "view-module-outline",
  //"view-parallell",
  "vimeo",
  "violin",
  "vlc",
  "volume-high",
  "volume-low",
  "volume-medium",
  "volume-minus",
  "volume-mute",
  "volume-off",
  "volume-plus",
  "volume-source",
  "vote",
  "vote-outline",
  "vuejs",
  "vuetify",
  "walk",
  "wall",
  //"wall-fire",
  "wall-sconce",
  "wardrobe",
  "wardrobe-outline",
  "watch",
  "water",
  "water-well",
  "water-well-outline",
  "waves",
  "weather-cloudy",
  "weather-hail",
  "weather-hazy",
  "weather-hurricane",
  "weather-lightning",
  "weather-lightning-rainy",
  "weather-night",
  "weather-pouring",
  "weather-rainy",
  //"wind-power",
  //"wind-power-outline",

  "wiper",
  "wiper-wash",
  //"wiper-wash-alert",
  "wizard-hat",
  "wrench",
  "wrench-outline",
  "xml",
  "yin-yang",

  "youtube",


],
    }),
    mounted: function() {
      this.filteredIcons = this.allIcons;
    },
    computed: {
        isDisabled() {
            return this.value === this.url || (!this.value && !this.url);
        }
    },
    methods: {
      typeChanged() {
        this.filterIcons();
      },
      clearIcon() {
        this.selectedIcon='';
        this.icon='';
        this.apply();
      },
        saveIcon(icon) {
            this.icon = icon;
            this.selectedIcon = "mdi-" + icon;
            this.showDialog = false;
            this.apply();
        },
        apply() {
            this.$emit("apply", this.selectedIcon);
            this.$emit("close");
        },
        close() {
            this.$emit("close");
        },
        filterIcons() {
  
          if (this.searchIcon.length === 0) {
            switch(this.iconType) {
              case "All":
                this.filteredIcons = this.allIcons;
              break;
              case "":
                this.filteredIcons = this.allIcons.filter(i => !i.includes("outline"));
              break;
              case "outline":
                this.filteredIcons = this.allIcons.filter(i => i.includes("outline"));
              break;
            }
          } else {
            switch(this.iconType) {
              case "All":
                this.filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
              break;
              case "":
                this.filteredIcons = this.allIcons.filter(i => !i.includes("outline") && i.includes(this.searchIcon));
              break;
              case "outline":
                this.filteredIcons = this.allIcons.filter(i => i.includes("outline") && i.includes(this.searchIcon));
              break;
            }
          }
        }
    }
  }
  </script>
<style>
  .iconPicker {
    text-rendering: optimizeSpeed;
  }
</style>
