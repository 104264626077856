<template>
    <div :class="'my-tip-tap' + (showColorMenu?' color-picker-active':'')" ref="myTipTap" flat>
        
    <v-toolbar :dense="dense" flat tile v-if="editor" :color="$vuetify.theme.dark ? undefined : 'grey lighten-4'"
            height="auto"
            class="py-1">
    
        <v-toolbar-items>
            <v-tooltip v-if="toolbar.includes('bold')" :open-delay="250" top>
                <span>{{editor.isActive('bold')?'Ta bort fet stil':'Sätt fet stil'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()"  :input-value="editor.isActive('bold')"  :class="{ 'is-active': editor.isActive('bold') }">
                        <v-icon>mdi-format-bold</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            <v-tooltip v-if="toolbar.includes('italic')" :open-delay="250" top>
                <span>{{editor.isActive('italic')?'Ta bort kursiv stil':'Sätt kursiv stil'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()"  :input-value="editor.isActive('italic')"  :class="{ 'is-active': editor.isActive('italic') }">
                        <v-icon>mdi-format-italic</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            <v-tooltip v-if="toolbar.includes('underline')" :open-delay="250" top>
                <span>{{editor.isActive('underline')?'Ta bort understruken stil':'Sätt understruken stil'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().toggleUnderline().run()" :disabled="!editor.can().chain().focus().toggleUnderline().run()"  :input-value="editor.isActive('underline')"  :class="{ 'is-active': editor.isActive('underline') }">
                        <v-icon>mdi-format-underline</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            <v-tooltip v-if="toolbar.includes('highlight')" :open-delay="250" top>
                <span>{{editor.isActive('highlight')?'Ta bort markerad text':'Markera text'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().toggleHighlight().run()" :disabled="!editor.can().chain().focus().toggleHighlight().run()"  :input-value="editor.isActive('highlight')"  :class="{ 'is-active': editor.isActive('highlight') }">
                        <v-icon>mdi-format-color-highlight</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>


            <v-menu v-if="toolbar.includes('color')" :value="showColorMenu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false" @input="showColorMenu=false">
                <template v-slot:activator="menu">
                    <v-tooltip :open-delay="250" top>
                        <span>{{'Textfärg'}}</span>
                        <template v-slot:activator="tooltip">
                            <v-btn v-on="{...menu.on, ...tooltip.on}" class="mr-1" icon @click="setPickerColor" small :input-value="editor.isActive('textStyle')"  :class="{ 'is-active': editor.isActive('textStyle') }">
                                <v-icon 
                                    :style="{
                                        'border-bottom': selectedColorBorder,
                                        height: '18px',
                                        top: '2px',
                                        width: '20px',
                                    }">mdi-format-color-text</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>
                </template>
                <v-card>
                    <v-card-text class="pa-0">
                        <v-color-picker v-model="textColor" flat @input="setEditorColor" />
                    </v-card-text>
                </v-card>
            </v-menu>

            <div v-if="toolbar.includes('|')" class="divider"></div>
            <v-tooltip v-if="toolbar.includes('headings')" :open-delay="250" top>
                <span>{{'Formattera text'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-select 
                        v-model="selectedHeading"
                        :items="[{ text: 'Heading 1', value: 1 },
                                { text: 'Heading 2', value: 2 },
                                { text: 'Heading 3', value: 3 },
                                { text: 'Text', value: 0 }]"
                        @change="setHeading"
                        dense
                                    hide-details="auto"
                                    style="width: 104px"
                        ></v-select>
                    </div>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('bulletList')" :open-delay="250" top>
                <span>{{editor.isActive('bulletList')?'Ta bort punktlista':'Punktlista'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().toggleBulletList().run()" :disabled="!editor.can().chain().focus().toggleBulletList().run()"  :input-value="editor.isActive('bulletList')"  :class="{ 'is-active': editor.isActive('bulletList') }">
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('orderedList')" :open-delay="250" top>
                <span>{{editor.isActive('orderedList')?'Ta bort numrerad lista':'Numrerad lista'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().toggleOrderedList().run()" :disabled="!editor.can().chain().focus().toggleOrderedList().run()"  :input-value="editor.isActive('orderedList')"  :class="{ 'is-active': editor.isActive('orderedList') }">
                        <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            

            <div v-if="toolbar.includes('|')" class="divider"></div>

            <v-tooltip v-if="toolbar.includes('rule')" :open-delay="250" top>
                <span>{{'Horisontell linje'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().setHorizontalRule().run()" :disabled="!editor.can().chain().focus().setHorizontalRule().run()">
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            
            <v-tooltip v-if="toolbar.includes('button')" :open-delay="250" top>
                <span>{{'Knapp-länk'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="triggerButtonBtn">
                        <v-icon>mdi-button-cursor</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('link')" :open-delay="250" top>
                <span>{{editor.isActive('link')?'Ändra/ta bort länk':'Länk'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="triggerLinkBtn" :disabled="!editor.can().chain().focus().extendMarkRange('link').setLink({ href: urlToLink }).run() && !editor.isActive('link')"  :input-value="editor.isActive('link')"  :class="{ 'is-active': editor.isActive('link') }">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('image')" :open-delay="250" top>
                <span>{{'Bild'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="showImageDialog = true">
                        <v-icon>mdi-image</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('youtube')" :open-delay="250" top>
                <span>{{'Youtube'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="showYoutubeDialog = true">
                        <v-icon>mdi-youtube</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('icons')" :open-delay="250" top>
                <span>{{'Ikoner'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="showIconPicker = true">
                        <v-icon>mdi-candycane</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            <v-tooltip v-if="toolbar.includes('clear')" :open-delay="250" top>
                <span>{{'Rensa formattering'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().clearNodes().unsetAllMarks().run()" :disabled="!editor.can().chain().focus().clearNodes().unsetAllMarks().run()"  :input-value="editor.isActive('orderedList')"  :class="{ 'is-active': editor.isActive('orderedList') }">
                        <v-icon>mdi-format-clear</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            
            <v-tooltip v-if="toolbar.includes('#undo')" :open-delay="250" top>
                <span>{{'Undo'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-tooltip v-if="toolbar.includes('#redo')" :open-delay="250" top>
                <span>{{'Redo'}}</span>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mr-1" icon small @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
                        <v-icon>mdi-redo</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            
        </v-toolbar-items>
    
    </v-toolbar>
    <editor-content :editor="editor" @keydown.native="onKeydown" />

    <v-dialog v-model="showLinkDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">Link</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="urlToLink" label="URL" required></v-text-field>
                <v-btn-toggle
                    v-model="urlTarget"
                    borderless dense
                >
                    <v-btn value="_blank">
                        <span>Extern länk</span>
                        <v-icon right>
                            mdi-open-in-new
                        </v-icon>
                    </v-btn>

                    <v-btn value="_self">
                        <span>Intern länk</span>
                        <v-icon right>
                            mdi-fit-to-page
                        </v-icon>
                    </v-btn>
                </v-btn-toggle>

                <v-btn-toggle
                    v-model="linkUnderline"
                    borderless dense
                >
                    <v-btn :value="true">
                        <span>Understruken länk</span>
                        <v-icon right>
                            mdi-format-underline
                        </v-icon>
                    </v-btn>

                    <v-btn :value="false">
                        <span>Icke understruken länk</span>
                        <v-icon right>
                            mdi-format-text-variant
                        </v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="insertLinkAndCloseDialog"
                    :disabled="urlToLink == ''"
                >
                    apply
                </v-btn>
                <v-btn v-if="hadLink"
                    color="orange darken-1"
                    text
                    @click="removeLinkAndCloseDialog">
                    remove link
                </v-btn>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="showLinkDialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showImageDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">Add image</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="urlToImage" label="URL to Image" required></v-text-field>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="insertImageAndCloseDialog"
                    :disabled="urlToImage == ''"
                >
                    apply
                </v-btn>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="showImageDialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showYoutubeDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">Add youtube clip</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="youtubeUrl" label="URL to Youtube" required></v-text-field>
                <v-text-field v-model="youtubeWidth" label="Player Width" placeholder="320" :rules="[v => !!isNaN(v) || 'Please write a number']" min="320" suffix="px"></v-text-field>
                <v-text-field v-model="youtubeHeight" label="Player Height" placeholder="180" :rules="[v => !!isNaN(v) || 'Please write a number']" min="180" suffix="px"></v-text-field>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="insertYoutubeAndCloseDialog"
                    :disabled="youtubeUrl == ''"
                >
                    apply
                </v-btn>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="showYoutubeDialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
      v-model="showIconPicker"
      @input="$emit('close', $event)"
      max-width="500"
    >
      <v-card>
        <v-card-title>
            <span class="headline">
                {{ "Skapa ikon" }}
            </span>
    
            <v-spacer />
    
            <v-btn icon @click="showIconPicker = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
  
        <v-card-text>
            <IconPicker :value="icon" @apply="onSelectIcon($event)" />
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="icon != ''" text @click="addIcon">
            {{ "Lägg till" }}
          </v-btn>
        </v-card-actions>
     </v-card>
    </v-dialog>
    <custom-button-dialog v-model="showButtonDialog" @apply="insertButtonAndCloseDialog($event)" @close="showButtonDialog = false" />
</div>
</template>
<script>

    import { Editor, EditorContent } from '@tiptap/vue-2'
    /*import StarterKit from '@tiptap/starter-kit'*/
    import { Bold } from '@tiptap/extension-bold'
    import { BulletList } from '@tiptap/extension-bullet-list'
    import { Document } from '@tiptap/extension-document'
    import { Dropcursor, DropcursorOptions } from '@tiptap/extension-dropcursor'
    import { Heading } from '@tiptap/extension-heading'
    import { History } from '@tiptap/extension-history'
    import { ListItem, } from '@tiptap/extension-list-item'
    import { OrderedList, } from '@tiptap/extension-ordered-list'
    
    import { Text } from '@tiptap/extension-text'
    /* others not imported */
    /*
import { Blockquote, BlockquoteOptions } from '@tiptap/extension-blockquote'
import { Code, CodeOptions } from '@tiptap/extension-code'
import { CodeBlock, CodeBlockOptions } from '@tiptap/extension-code-block'
import { Strike, StrikeOptions } from '@tiptap/extension-strike'
import { Gapcursor } from '@tiptap/extension-gapcursor'
import { HardBreak, HardBreakOptions } from '@tiptap/extension-hard-break'
import { HorizontalRule, HorizontalRuleOptions } from '@tiptap/extension-horizontal-rule'
import { Strike, StrikeOptions } from '@tiptap/extension-strike'
    */

    import Underline from '@tiptap/extension-underline'
    import Highlight from '@tiptap/extension-highlight'
    import Color from '@tiptap/extension-color'
    import TextStyle from '@tiptap/extension-text-style'
    import Image from '@tiptap/extension-image'
    import Youtube from '@tiptap/extension-youtube'
    import HorizontalRule from '@tiptap/extension-horizontal-rule'
    import HardBreak from '@tiptap/extension-hard-break'
    //import { ExtendedLink } from './TipTapExtensions/ExtendedLink'
    //import Link from '@tiptap/extension-link'
    //import { Paragraph } from '@tiptap/extension-paragraph'
    import { CustomParagraph } from './TipTapExtensions/CustomParagraph'
    import { CustomLink } from './TipTapExtensions/CustomLink'
    import { CustomItalic } from './TipTapExtensions/CustomItalic'
    import { CustomIcon } from './TipTapExtensions/CustomIcon'
    import { CustomButton } from './TipTapExtensions/CustomButton'
    import IconPicker from './TipTapExtensions/IconPicker'
    import CustomButtonDialog from './TipTapExtensions/CustomButtonDialog.vue'

//import CharacterCount from "@tiptap/extension-character-count";
    export default {
    name: 'MyTipTap',
    components: {
        EditorContent,IconPicker,CustomButtonDialog
    },
    props: {
        value: {
            type: String,
            required:false,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideToolbar: {
            type: Boolean,
            default: false
        },
        disableToolbar: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        mention: {
            type: Boolean,
            default: false
        },
        mentionItems: {
            type: Function,
            default: () => { return []; }
        },
        index: {
            type: Number,
            required: false,
            default: -1
        },
        toolbar: {
            type: Array,
            default: () => { return ['bold', 'italic', 'underline', 'highlight', 'color', '|', 'headings', 'bulletList', 'orderedList', 'checkbox', '|', 'link', 'image', 'video', 'emoji', 'clear', '#undo', '#redo']; }
        },
        errorMessages: {
            type: Array,
            default: null
        },
        placeholder: {
            type: String,
            default: ""
        },
        extensions: {
            type: Array,
            default: () => { return [/*CharacterCount*/]; }
        }
    },
    emits: ['update:modelValue', 'input', 'blur', 'focus', 'keydown', 'paste'],
    data: () => ({
        editor: null,
        textColor: '#ffffff',
        selectedHeading: null,
        showColorMenu: false,
        showButtonDialog: false,
        showLinkDialog: false,
        showImageDialog: false,
        showYoutubeDialog: false,
        showIconPicker: false,
        icon: '',
        iconText: null,
        hadLink: false,
        linkUnderline: true,
        urlToLink: '',
        urlToImage: '',
        youtubeUrl: '',
        urlTarget: '_blank',
        youtubeWidth: 480,
        youtubeHeight: 320
    }),
    watch: {
        // if the set value is set after awhile (for example via a ajax requiest) then we need to re-set the mutableValue
        value(newV) {
            // HTML
            const isSame = this.editor.getHTML() === newV
            //const isSame = this.editor.view.dom.innerHTML === newV
            
            if (isSame) {
                return
            }

            this.editor.commands.setContent(newV, false)
        }
    },
    created() {
    },
    mounted() {
        
        this.editor = new Editor({
            content: this.value,
            extensions: [
                
               Bold,
               BulletList,
               Document,
               Dropcursor.configure({ color: '#ff00ff'}), 
               Heading,
               History,
               ListItem,
               OrderedList,
               CustomParagraph,
               HorizontalRule,
               Text,
               HardBreak,
                CustomItalic,
                Underline,
                Highlight,
                TextStyle,
                Color,
                Image,
                CustomButton,
                CustomIcon,
                CustomLink.configure({
                    openOnClick: false
                }),
                Youtube.configure({
                    width: 480, height: 320, controls: true, inline: false, nocookie: false, allowFullscreen: true, autoplay: false, endTime: 0, loop: false, modestBranding: false
                })
            ],
            onCreate: () => {
                this.selectedHeading = this.getHeading();
            },
            onSelectionUpdate: () =>  {
                this.selectedHeading = this.getHeading();
            },
            onUpdate: () => {
                this.selectedHeading = this.getHeading();
                //console.log(this.$refs.myTipTap.getElementsByClassName("ProseMirror"));
                //console.log("update:", this.editor.view.dom.innerHTML);
                this.$emit('input', this.editor.getHTML());
                this.$emit('update:modelValue', this.editor.getHTML());
            },
            onBlur: () => {
                this.$emit('blur', this.editor.getHTML());
            },
            onFocus: () => {
                this.$emit('focus', this.editor.getHTML());
            }
        })
        
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    computed: {
        selectedColorBorder() {
            let opacity = !this.disableToolbar ? "0.67" : "0.2";
            //console.log("selected color border:", this.editor.getAttributes('textStyle').color);
            if (this.editor.getAttributes('textStyle').color) {
                let color = `${this.editor.getAttributes('textStyle').color}`;
                if (color[0] === "r") {
                    opacity = !this.disableToolbar ? "0.75" : "0.25";
                    return `3px solid ${color.replace(")", `, ${opacity})`)}`;
                } else {
                    opacity = !this.disableToolbar ? "C0" : "30";
                    return `3px solid ${color.substring(0, 7).concat(opacity)}`;
                }
            }

            return `3px solid rgba(0, 0, 0, ${opacity})`;
        }
    },
    methods: {
        addIcon() {
            this.editor.commands.insertContent("<bdi contenteditable='false' aria-hidden='true' class='v-icon notranslate mdi " + this.iconText + "' />");
            this.editor.commands.focus();
            //this.editor.chain().focus().setFigure({ class: 'v-icon notranslate mdi ' + this.iconText, contenteditable: false }).run();
            this.showIconPicker = false;
        },
        onSelectIcon(iSelectedIcon) {
            this.iconText = iSelectedIcon;
        },
        triggerButtonBtn() {
            this.showButtonDialog = true;
            //this.editor.commands.insertContent("<a href='#' targert='_blank'><button contenteditable='false' class='ma-2 img-btn v-btn v-btn--has-bg theme--light v-size--x-large transparent'><bdi contenteditable='false' aria-hidden='true' class='v-icon notranslate mr-4 mdi mdi-link'> </bdi> test</button></a>");
            //this.editor.commands.focus();
        },
        insertButtonAndCloseDialog(e) {
            this.showButtonDialog = false;
            console.log("try to insert:", e.buttonResult);
            this.editor.commands.insertContent(e.buttonResult);
            this.editor.commands.focus();
        },
        triggerLinkBtn() {
            this.hadLink = false;
            if (this.editor.isActive('link')) {
                this.hadLink = true;
                this.urlToLink = this.editor.getAttributes("link").href;
                this.linkUnderline = !(this.editor.getAttributes("link").class == 'no-underline');
            }// else {
                this.showLinkDialog = true;
            //}
        },
        removeLinkAndCloseDialog() {
            this.urlToLink = "";
            this.insertLinkAndCloseDialog();
        },
        insertLinkAndCloseDialog() {
            //console.log("url target:", this.urlTarget);
            if (this.urlToLink == "") {
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run();
            } else {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: this.urlToLink, target: this.urlTarget, class: this.linkUnderline?'':'no-underline' })
                    .run();
            }
            this.showLinkDialog = false;
            
        },
        insertImageAndCloseDialog() {
            this.showImageDialog = false;
            this.editor.chain().focus().setImage({ src: this.urlToImage }).run();
        },
        insertYoutubeAndCloseDialog() {
            //console.log("do the insert");
            this.showYoutubeDialog = false;
            //console.log("hide dialog");
            this.editor.commands.setYoutubeVideo({ src: this.youtubeUrl, width: this.youtubeWidth, height: this.youtubeHeight});
        },
        getHeading() {
            if (this.editor.isActive("heading", { level: 1 })) {
                return 1;
            }

            if (this.editor.isActive("heading", { level: 2 })) {
                return 2;
            }

            if (this.editor.isActive("heading", { level: 3 })) {
                return 3;
            }

            return 0;
        },
        setHeading(iEv) {
            if (iEv != 0) {
                this.editor.chain().focus().toggleHeading({ level: iEv }).run();
            } else {
                this.editor.chain().focus().setParagraph().run();
            }
        },
        rgbaToHex(rr, gg, bb, aa) { // assumes values: 0 to 256
            var r = Math.floor(rr);
            var g = Math.floor(gg);
            var b = Math.floor(bb);
            var a = Math.floor(aa);
            //console.log("rgba", r, g, b, a);
            //console.log("rgba to string", (r<16?"0":"")+r.toString(16), (g<16?"0":"")+g.toString(16), (b<16?"0":"")+b.toString(16), (a<16?"0":"")+a.toString(16));
            //console.log("result", '#' + r.toString(16) + g.toString(16) + b.toString(16) + a.toString(16));
            return ('#' + (r<16?"0":"")+r.toString(16) + (g<16?"0":"")+g.toString(16) + (b<16?"0":"")+b.toString(16) + (a<16?"0":"")+a.toString(16));
        },
        rgbToHex(r, g, b) {
                    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
        },
        setPickerColor() {
            this.showColorMenu = true;
            //console.log("editor color:", this.editor.getAttributes('textStyle').color);
            var hexColor = this.editor.getAttributes('textStyle').color;
            if (!hexColor) {
                hexColor = this.rgbaToHex(0*256, 0*256, 0*256, 0.6*256);
            } else if (this.editor.getAttributes('textStyle').color.indexOf("rgb") > -1) {
                var colors = this.editor.getAttributes('textStyle').color.replace(/[^\d,]/g, "").split(",");
                hexColor = this.rgbToHex(colors[0], colors[1], colors[2]) + "FF"; // hexa
            }
            if (!hexColor) {
                hexColor = this.rgbaToHex(0*256, 0*256, 0*256, 0.6*256);
            }
            this.textColor = hexColor;
        },
        setEditorColor() {
            //console.log("set color!", this.textColor);
            this.editor.chain().focus().setColor(this.textColor).run();
        },
        onKeydown(e) {
            this.$emit("keydown", e);
        }
    }
  }
</script>

<style>
    #Emojis {
        font-family: auto !important;
    }
    p {
        min-height: 24px;
    }
    
</style>
<style lang="scss">
    .divider {
    width: 2px;
    height: 1.25rem;
    background-color: rgba(#000, 0.1);
    margin-left: 0.25rem;
    margin-right: 0.5rem;
  }
    .my-tip-tap {
        .v-select:hover >*>*{
            /*background-color:#f0f;
            opacity:0.18;*/
        }
        .v-select__selections input {
            cursor:pointer;
            opacity: 0;
        }
        .v-select {
            width: min-content;
            margin-bottom: 0px !important;
            margin-top:-4px !important;

            .v-input__slot:hover {
                border-radius: 4px;
                background-color:#00000011;
            }
            .v-input__slot::before,
            .v-input__slot::after {
                display: none;
            }
            .v-input__control > div {
                height: 28px;
                min-height: 24px !important;
                padding: 6px !important;
            }
            .v-input__icon {
                min-width: auto;
                width: auto;
                margin-left: -4px;
                margin-right: -4px;
            }
            .v-input__append-inner {
                padding: 0px;
            }
            .v-select__selection {
                margin-top: 0px;
                font-size: 14px;
                margin-right: 8px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .ProseMirror-focused {
        outline: none;
        }
        .v-toolbar__content {
            padding: 0;
        }
        .v-toolbar__items {
            flex-wrap: wrap;
        }
    }
    .theme--light.v-card.my-tip-tap {
        color: inherit !important;
    }
    .my-tip-tap {
        background: #dedede !important;
        border:0px solid #00000033;
        margin-left:-1px;
        margin-top:-1px;
        margin-right:-1px;
        margin-bottom:-1px;
        /*
        margin-left:-16px;
        margin-top:-16px;
        margin-right:-16px;
        margin-bottom:-16px;*/
    }
    .white--text .my-tip-tap .ProseMirror {  
        caret-color: white;
    }
    .my-tip-tap .ProseMirror {
        padding: 15px;      
        color: inherit !important;
        -webkit-font-variant-ligatures: inherit;
        font-variant-ligatures: inherit;
        font-feature-settings: inherit;
        min-height:80px;
    }
    .my-tip-tap .v-card__text {
        color: inherit !important;
    }
    .my-tip-tap header {
        /*margin-top:-32px;*/
    }
    .my-tip-tap .v-card__text {
        letter-spacing: inherit;
        line-height: inherit;
        font-weight: auto;
    }

    .ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68CEF8;
    }
  }
}
.color-picker-active ::selection {
    background:inherit;
}
</style>