<template>
    <v-row :justify="LR" 
        @mouseover="selectItem(message)"
        @mouseleave="unSelectItem()"
    >
        <template v-if="selectedItem===message" >
            <v-col cols="2" v-if="isSender=='true'" >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="showRemoveButton()"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <span>
                        Val
                    </span>
                </v-tooltip>
                <v-btn small v-if="showRemoveBtn" outlined color="primary" style="overflow:auto;" @click="openConfirmRemoveNote()">
                    Ta bort
                </v-btn>
            </v-col>
        </template>
        <v-col cols="10" class="">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-card :color="bgCol"
                            :dark="isLight ? null : ''"
                            shaped
                            flat
                            class="one-notification"
                            v-on="on"
                    >
                    <!--      @contextmenu.prevent="showDialogMenu($event)" -->
                        <v-toolbar 
                            light
                            dense
                            flat 
                            v-if="messageMenu"
                            class="d-flex align-start message-toolbar"
                        >
                        <!--  color="blue-grey lighten-2"
                                    dark -->

                        <!--     <v-list-item>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="openUser()"><v-icon>person</v-icon></v-btn>
                                    </template>
                                    <span>
                                    Visa användare - {{message.ToUserId}}
                                    </span>
                                </v-tooltip>
                            </v-list-item> -->
                            <v-list-item>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="openValiFormSubmission()"><v-icon>business_center</v-icon></v-btn>
                                    </template>
                                    <span>
                                    Visa akt - {{message.SubmissionId}}
                                    </span>
                                </v-tooltip>
                            </v-list-item>
                            <v-list-item v-if="isSender=='false'">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="openCourse()"><v-icon>school</v-icon></v-btn>
                                    </template>
                                    <span>
                                    Visa Post (prövning) - {{message.CoursePartId}}
                                    </span>
                                </v-tooltip>
                            </v-list-item>
                            <v-list-item v-if="message.TaskId!==null">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="openTask()"><v-icon>all_inbox</v-icon></v-btn>
                                    </template>
                                    <span>
                                    Visa To do - {{message.TaskId}}
                                    </span>
                                </v-tooltip>
                            </v-list-item>
                        </v-toolbar>
                        <v-card-text class="mb-0 pt-2 pb-2" > <!-- @click.prevent="showMenu($event)"class="fadeInMe" -->
                            <span v-html="message.Message" ></span>
                            <!-- <span 
                                class="tiptap-vuetify-editor__content mb-2"
                                v-html="message.Message"
                                />

                                -->
                                
                        <!--  <div class="mb-0 sender" small>
                            // {{senderName}} <div class="date">{{sentDate}}</div> </div> 
                            -->
                            <template v-if="typeof message.Attachments !== 'undefined' && message.Attachments.length>0">
                                <span style="opacity:0.6" class="caption" v-if="isSender=='true'">
                                    {{timeProgressed($store.state.notices.notifications.serverDateTime, message.CDate)}}
                                </span>
                                <h5 class="mt-5">Bilagor</h5>
                                
                                <file-list-buttons :files="message.Attachments" :linkTextColor="isSender?'primary':'white'" />
                            </template>
                            <!--
                                <template v-if="message.Attachments && message.Attachments.length>0">
                                    <v-list v-for="(file) in message.Attachments" :key="file.Id">
                                        <v-list-item>
                                        <a target="_blank" :href="getFileUrl(file)"><v-chip class="ma-2" outlined >{{ file.FileName }}</v-chip></a>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <template v-else>
                                    Inga bilagor...
                                </template>
                            -->
                            
                            
                        </v-card-text>
                        
                        <v-card-actions v-if="isSender=='false'" class="pt-0">
                            <v-list-item class="grow"> <!-- class="grow" -->
                                <v-list-item-avatar color="grey darken-3">
                                <v-img
                                    class="elevation-6"
                                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                                ></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="mb-0 sender" small>{{senderName}}</v-list-item-title>
                                    {{timeProgressed($store.state.notices.notifications.serverDateTime, message.CDate)}}
                                </v-list-item-content>

                                <!--
                                <v-row
                                align="center"
                                justify="end"
                                >
                                <v-icon class="mr-1">mdi-heart</v-icon>
                                <span class="subheading mr-2">256</span>
                                <span class="mr-1">·</span>
                                <v-icon class="mr-1">mdi-share-variant</v-icon>
                                <span class="subheading">45</span>
                                </v-row>
                                -->
                            </v-list-item>
                        </v-card-actions>
                    </v-card>
                </template>
                <span>
                     {{sentDate}} (Id:{{message.Id}})
                </span>
            </v-tooltip>

             <confirm-dialog v-if="removeNoteDialog" :title="'Bekräfta - Ta bort meddelande!'" :type="'alert'" :message="'Är du säker på att du vill ta bort meddelandet?'" :choiceOK="'Ja'" :choiceNO="'Nej'" v-on:confirm="removeNote()" v-on:closeDialog="removeNoteDialog=false" />
        </v-col>
        <!--
        <template v-if="selectedItem===message" >
            <v-col cols="2" v-if="isSender=='false'" >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="setReplyToMessage()"><v-icon>reply</v-icon></v-btn>
                    </template>
                    <span>
                        Svara
                    </span>
                </v-tooltip>
            </v-col>
        </template>
        -->
    </v-row>
</template>

<script>
import ConfirmDialog from '../dialogs/ConfirmDialog.vue'
import FileListButtons from '../helpers/FileListButtons.vue'

export default {
    name: 'MyMessage',
    components:{
        'confirm-dialog': ConfirmDialog,
        FileListButtons
    },
    props:{
        message: {
            type: Object,
            required: true
        },
        asyncMode: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data () {
        return {
            messageMenu: false,
            userExamId: null,
            postId:null,
            selectedItem:null,
            showRemoveBtn:false,
            removeNoteDialog:false
        } 
    },
    computed: {
        bgCol() {
            if(this.message.FromUserId === this.$store.state.everything.user.user_id) {
                return "#2556B3"
            }
            else {
                return "#ffffff"
            }
        },
        isLight() {
            if(this.message.FromUserId === this.$store.state.everything.user.user_id) {
                return false
            }
            else {
                return true
            }
        },
        senderName() {
            if(this.message.FromUserId === this.$store.state.everything.user.user_id)
                return this.$store.state.everything.user.realname
            else 
                return this.message.SenderFirstName + " " + this.message.SenderLastName
            
        },
        LR() {
            if(this.message.FromUserId === this.$store.state.everything.user.user_id)
                return 'end'
            else
                return 'start'
        },
        isSender() {
            if(this.message.FromUserId === this.$store.state.everything.user.user_id)
                return 'true'
            else
                return 'false'
        },
        sentDate() {
           // Split timestamp into [ Y, M, D, h, m, s ]
            var t = this.message.CDate.split(/[- :]/)
            var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
            var weekday = new Array(7)

            weekday[0] = "Sunday"
            weekday[1] = "Monday"
            weekday[2] = "Tuesday"
            weekday[3] = "Wednesday"
            weekday[4] = "Thursday"
            weekday[5] = "Friday"
            weekday[6] = "Saturday"
            
            var dag = weekday[d.getDay()]

            var month = new Array(12);
            month[0] = "January";
            month[1] = "February";
            month[2] = "March";
            month[3] = "April";
            month[4] = "May";
            month[5] = "June";
            month[6] = "July";
            month[7] = "August";
            month[8] = "September";
            month[9] = "October";
            month[10] = "November";
            month[11] = "December";

            var m = month[d.getUTCMonth()];

          //  dag = this.getDayName(this.message.CDate,'SE')
           
            return dag + " " + t[2] + " " + m + " " + t[0] + " kl:" + t[3] + ":" + t[4]

        }
    },
    created() {
         /*   console.log("My User Id is: " + this.$store.state.everything.user.user_id)
            console.log("User Id of message is: " + this.message.FromUserId)
            console.log(this.message)
            */
    },
    mounted() {
        //var elem = this.$el.parentElement
        //elem.scrollTop = elem.clientHeight;
        this.showMenu();
    },
    updated(){
      //  var elem = this.$el
      //  console.log(elem)
    //    elem.scrollTop = elem.clientHeight;
    },
    methods: {
        showMenu() {
            if(this.$store.state.everything.user.isValidator==false) {                 // only for admins
               // if(this.message.Message.includes("prövnings_id:",0)) {
                if(this.message.CoursePartId!=null) {
                //    const words = this.message.Message.split('<br />');
                //    console.log(words)
                //    let a = this.message.Message.indexOf("prövnings_id:")
                    this.messageMenu = !this.messageMenu
                    this.postId = this.message.Message.a
                }
            }
        },
        showDialogMenu(e) {
            if(this.$store.state.everything.user.isValidator==false) {                 // only for admins
                if(this.message.Message.includes("prövnings_id:",0)) {
                    this.$store.commit("setShowDialogMenu", true)
                    this.$store.commit("setDialogMenuPos", {x:e.clientX, y:e.clientY})
                }
            }
        },
        getDayName(dateStr, locale)
        {
            var date = new Date(dateStr)
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            let d = date.toLocaleDateString(locale, options)
            d += " " + dateStr.substr(11,5)
            return d
        },
        getMonthName(dateStr, locale)
        {
            var date = new Date(dateStr);
            return date.toLocaleDateString(locale, { weekday: 'long' })    
        },
        openUser() {
            
        },
        openValiFormSubmission() {
            var subObj = {
                Id: this.message.SubmissionId,
                RelatedUserID: this.message.FromUserId
            }

            this.$store.commit("setValiFormSubmission", subObj)
            this.$router.push('/akter/' + this.message.SubmissionId)
        },
        openCourse() {
            let o = [];
            o.obj = this.message;
            o.obj.UserId = this.message.FromUserId;
           // o.obj.FirstName = this.message.From
            o.router = this.$router;
            o.route = this.$route;

        //    console.log(o.obj)

            if(this.message.TaskId!=null) {             // Kolla vem som är mentor och vem som är validand.
                if(this.message.ToUserId == this.$store.state.everything.user.user_id) {
                    o.obj.UserId = this.message.FromUserId;
                    o.obj.FirstName = this.message.SenderFirstName;
                    o.obj.LastName = this.message.SenderLastName;
                } else {
                    o.obj.UserId = this.message.ToUserId;
                }
            }

            o.obj.SubmissionId = this.message.SubmissionId;

        //    console.log(o.obj)

            this.$store.dispatch("gotoUserExamAsMentor", o).then(() => {

            }, (error) => {
                console.log(error)
            })
        },
        openTask() {
            if(this.message.TaskId) {
                this.$router.push('/Todo/' + this.message.TaskId)
            } else {
                this.$toast.warning("Meddelandet har ingen to-do-uppgift! <i>(Kan vara ett gammalt prövningsmeddelande.)</i>");
            }
        },
        selectItem(i) {
            if(i.TaskId==null) {
                this.selectedItem = i
            }
        },
        unSelectItem() {
            this.selectedItem = null;
            this.showRemoveBtn = false;
        },
        showRemoveButton() {
            this.showRemoveBtn = true;
        },
        openConfirmRemoveNote() {
            this.removeNoteDialog = true;
        },
        removeNote() {
            this.removeNoteDialog = false;
            this.$store.dispatch("removeNote", this.message).then(() => {
                this.$toast("Meddelandet togs bort!");
            }, (error) => {
                console.log(error)
            })
        },
        setReplyToMessage() {
            this.$store.dispatch("setReplyToMessage", this.message).then(() => {
                this.$toast("Skriv ett svara på meddelandet!");
            }, (error) => {
                console.log(error)
            })
        },
        getFileUrl(i_file) {
         // return window.serverUrl + '/api/backend/get_file.php?fileGet=/assets/user_exams/' + i_file.UserId + '/' + i_file.FileName + '&token=' + localStorage.getItem("user-token");
          return window.serverUrl + '/api/backend/get_file.php?fileGet=' + i_file.Path + '&token=' + localStorage.getItem("user-token");
        },
    }

}
</script>

<style scoped>
    .sender {
        font-size: 0.9em;
    }

    .one-notification .date {
        float:right;
    }
    .one-notification > .v-card__text div .date:last-of-type {
        visibility: hidden;
    }
    .one-notification:hover > .v-card__text div .date:last-of-type {
        visibility: visible;
    }

    .one-notification span {
        margin: 0px;
        padding: 0px;
    }

    /*
    .fadeInMe {
        opacity: 0;
        transition: 3.3s all ease-out;
    }
    */
</style>
