<template>
<div v-if="crumbs">
  <!--  <v-list v-for="item in crumbs" v-bind:key="item.id">
      hejsan
    <template v-slot:item="{ item }">
      <v-list-item
        :to="item.to"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-list-item>
    </template>
  </v-list>
  -->
  
  <v-breadcrumbs :items="crumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>

</div>

</template>

<script>
export default {
  mounted() {

  },
  computed: {
    crumbs: function () {
      let paths = this.$route.path; //
      paths = paths.substring(1);
      paths = paths.split("/");
      console.log("path: ");
      console.log(paths);

      let breadcrumb = this.$route.meta.breadCrumbs;
      
      if(breadcrumb === undefined) return;

      console.log("shiasne");
      console.log(breadcrumb);

      paths.forEach((path, i) => {
        if (breadcrumb[i]) {
          breadcrumb[i].text = breadcrumb[i].text
            ? breadcrumb[i].text
            : path;
        } else {
          return this.$route.path;
        }
      });

      return breadcrumb;
    },
    
  //  breadCrumbs() {
    //  console.log(this.$route.meta.breadCrumbs);
    //  return this.$route.meta.breadCrumbs;
     /* if (typeof this.$route.meta.breadCrumbs === "function") {
        return this.$route.meta.breadCrumbs.call(this, this.$route);
      }
      return this.$route.meta.breadCrumbs;
      */
  //  },
  },
};
</script>

<style>