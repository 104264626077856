<template>
    <span>
        <v-btn @click="loadVersionHistory()" depressed text class="grey--text text--lighten-1">
            v{{version}} {{errorStatus}}
        </v-btn>
    </span>
</template>

<script>
import axios from 'axios';

export default {
    name: 'VersionNumber',
    components: {
    },
    props: {
    },
    data: () => ({
        hash: null,
        version: "",
        loading: false,
        errorStatus: "",
    }),
    mounted() {
        this.loadVersionNr();
    },
    methods: {
        async loadVersionNr() {
        //    console.log("Fetching version number from file..." + this.errorStatus);  
            let url = 'version.json';
            let token = localStorage.getItem('user-token');
            this.loading = true;

            try {
                const fileResponse = await axios.create({
                baseURL: this.$publicUrl,
                headers: {
                    'Authorization': 'JWT ' + token,
                    'Content-type': 'application/json'
                }
                }).get(url + '?t=' + new Date().getTime());

                this.hash = fileResponse.data.hash;
                this.version = fileResponse.data.version;
                this.loading = false;
             //   console.log("v: " + this.version);
            } catch (error) {
                this.loading = false;
                if (!error.response) {
                    this.errorStatus = "N/A"; //'Error: Network Error'
                } else {
                    this.errorStatus = error.response.statusText;
                }
             //   console.log(scope);
            }
            
        },
        loadVersionHistory() {
            if(this.$store.state.everything.user.user_id !=0) {
                if(this.$store.state.everything.user.userData.RelatedUserType!='1') {
                    this.$router.push('/versioner');
                }
            }
        }
    }
}

</script>
