import { Italic } from '@tiptap/extension-italic'
import { mergeAttributes } from '@tiptap/core'

export const CustomItalic = Italic.extend({
  group: 'inline',
  marks: '_',
  content: 'inline*',
  addAttributes() {
    return {
        class: {
            default: '',
        },
    };
},
/*
parseHTML() {
  return [
    {
      tag: 'em',
    },
    {
      tag: 'i',
      getAttrs: node => (node).style.fontStyle !== 'normal' && null,
    },
    {
      style: 'font-style=italic',
    },
  ]
},*/
  renderHTML({ HTMLAttributes }) {
    // Original:
    // return ['strong', HTMLAttributes, 0]
    return ['i', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})