/*********************

  If you need to trigger new-version update rocket???
  Do the following:
  
  Change NewVersion.vue line 4 from -> <div class="refresh-container" v-if="hashChanged && serverUrl != 'http://localhost:3000'">
  
  to

  <div class="refresh-container" v-if="hashChanged">


**********************/

import axios from 'axios';

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      token: localStorage.getItem('user-token'),
      hashChanged: false,
      newHash: ''
    }
  },
  mounted() {
    // let url = this.$publicUrl + 'version.js';
    let url = 'version.json';
    this.initVersionCheck(url, 1000 * 30);
  },
  methods: {
    initVersionCheck(url, frequency = 2000 ) { // 1000 * 60 * 2
      setInterval(() => {
        console.log("version check...");
        this.checkVersion(url);
      }, frequency);
    },
    async checkVersion(url) {
      try {
        const fileResponse = await axios.create({
          baseURL: this.$publicUrl,
          headers: {
            'Authorization': 'JWT ' + this.token,
            'Content-type': 'application/json'
          }
        }).get(url + '?t=' + new Date().getTime());

        this.newHash = fileResponse.data.hash;

        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
      } catch (error) {
        this.loading = false;
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },
    hasHashChanged(currentHash, newHash) {
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true;
      }

      return currentHash !== newHash;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      window.location.reload();
    }
  }
};