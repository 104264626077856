<template>
    <v-footer class="myapp-footer">
      <v-container fluid>
        <v-row no-gutters justify="center">
          <v-col cols="12" sm="10" md="8">

            <v-row no-gutters>
              <v-col cols="12" sm="4" class="mb-8">
                <template>
                      <div style="display:block" >
                        <img 
                          :src='logo_img' 
                          :alt="logo_alt"
                          class="valiweb_logo mt-2 ml-1"
                          :style="'display:inline-block;width:' + logo_width + 'px;'"
                        >
                      </div>
                </template>
              </v-col>
              <v-col cols="12" sm="8">
                <img 
                    :src='logo_img_2' 
                    alt="Yrkeslärare ikoner 1"
                    class="mt-2 ml-1"
                    :style="'display:inline-block;width:' + logo_width + 'px;'"
                >
                <img 
                    :src='logo_img_3' 
                    alt="Yrkeslärare ikoner 2"
                    class="mt-2 ml-1"
                    :style="'display:inline-block;width:' + logo_width + 'px;'"
                >
              </v-col>
              
              <v-col cols="12" md="4" class="pa-2">
                
                <ContentArea TheKey="Yrk.Footer.LeftText">
                  <template v-slot:DefaultContent>
                    Skellefteå Kommun<br />
                    ValiWeb / Kanal, Bäckgatan 2<br />
                    931 85 Skellefteå<br />
                    <br />
                  </template>
                </ContentArea>
              </v-col>
              <v-col cols="6" sm="5" class="pa-2">
                <ContentArea TheKey="Yrk.Footer.MiddleText">
                  <template v-slot:DefaultContent>ValiWeb gör behörighetsprövning i yrkesämnen. För information om ansökan till utbildning samt grundläggande behörighet, se respektive lärosätes hemsida.</template>
                </ContentArea>
              </v-col>
              <v-col cols="6" sm="3" class="pa-2">
                <ul class="ml-4">
                  <li class="pb-2">
                    <a :href="$root.$publicUrl + 'kontakt'" @click.prevent="$router.push('/kontakt');" title="Kontakt">Kontakt</a><br />
                  </li>
                  <li class="py-2">
                    <a :href="$root.$publicUrl + 'info'" @click.prevent="$router.push('/info');" title="Allmän info">Allmän info</a><br />
                  </li>
                  <li class="py-2">
                    <a :href="$root.$publicUrl + 'FAQ'" @click.prevent="$router.push('/FAQ');" title="FAQ">FAQ</a><br />
                  </li>
                  <li class="py-2">
                    <a :href="'https://yrkadmin.valiweb.nu'" title="Admin">Admin</a><br />
                  </li>
                </ul>
              </v-col>  

            </v-row>

          </v-col>
        </v-row>
      </v-container>

    </v-footer>
</template>

<script lang="ts">

    import ContentArea from "./texts/ContentArea.vue";
    import Router from 'vue-router';

    export default {
        name: 'MyFooter',
        components: {
            ContentArea
        },
        props: {
            logo_img: {
                type: String,
                required: true
            },
            logo_alt: {
                type: String,
                required: true
            },
            logo_width: {
                type: Number,
                required: true
            },
            logo_img_2: {
                type: String,
                required: false
            },
            logo_img_3: {
                type: String,
                required: false
            }
        },
        data: function () {
            return {
                
            }
        },
        mounted() {
            // console.log("root: ", this.$root);
          //  console.log(this.logo_img);
        },
        methods: {
        
        }
    }

</script>

<style scoped>
    .valiweb_logo {
        filter: invert(27%) sepia(51%) saturate(0%) hue-rotate(346deg) brightness(255%) contrast(100%);
    }

    .myapp-footer ul li a, body footer ul li a:link {
    color: #fff; 
    padding: 0px;
    text-decoration: none;
    }

    .myapp-footer ul li a:hover {
    text-decoration: underline;
    }

    .myapp-footer ul {
    list-style:none;
    font-weight: bolder;
    padding-inline-start: 0px;
    }


    .myapp-footer {
    background-color: #294a5c;
    padding-top: 2rem;
    color: #fff;
    }
    .myapp-footer .col{
    color: #fff;
    line-height: 28px;
    }

</style>