import Vue from "vue";
import * as axios from "axios";

const state = {
    group_id: null,
    course_id: null
};
const getters = {
};
const mutations = {
    setGroupId(state, i) {
        state.group_id = i;
    },
    setGroupCourseId(state, i) {
        state.course_id = i;
    },
};
const actions = {
    setActiveGroup({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            commit("setGroupId", data.Id);
            console.log("Group is is: " + state.group_id);
            resolve();
        });
    },
    setActiveGroupCourse({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            commit("setGroupCourseId", data.Id);
            resolve();
        });
    }
};

export default { namespaced: false, state, getters, mutations, actions };